import {StateCreator} from 'zustand';
import {DateRange} from 'react-day-picker';
import {MarketingPlatforms} from '@enums/marketingPlatforms';
import {
    PerformanceSummary,
    PerformanceNew,
    BusinessPrisma,
    MemberCount,
    PerformanceSortObjectType,
} from 'API';
import {filterConditionType} from '@interfaces/performance';
import {BusinessProfile} from '@interfaces/business';
import {BusinessProfileSlice} from './businessProfileSlice';

export const enum PerformancePageState {
    success,
    loading,
    noResults,
    noResultsWithFilters,
    adAccountsNotConnected,
}

enum Platforms {
    FACEBOOK = 'Facebook',
    TIKTOK = 'Tiktok',
    GOOGLE = 'Google',
}

interface CallbackState {
    callback: (() => void) | null;
}

interface State {
    currentPurchase: string | null;
    currentPlatform: MarketingPlatforms;
    currentSelectedDateValue: DateRange | undefined;
    campaignsSelected: any[];
    adSetsSelected: any[];
    adsSelected: any[];
    sirgeDataToggle: boolean;
    allPerformanceRows: PerformanceNew[];
    summaryPerformanceRows: PerformanceSummary | null;
    memberCount: MemberCount | null;
    selectedAdGroupsExplore: {
        selected_campaign_ids: string[];
        selected_ad_set_ids: string[];
    };
    currentPage: number;
    simplifiedUiChecked: boolean
    filterProps: filterConditionType;
    loading: PerformancePageState;
    activeFilterCount: number;
    allChecked: string[];
    resetAllFilters: CallbackState;
    sortChangeColumn: PerformanceSortObjectType | undefined;
    // Used to show tooltip on the table . Can't be kept inside the cell itself because table cuts off
    hoveredRowIndex: number|null;
}

interface Actions {
    setCurrentPurchase: (currentPurchase: string) => void;
    setHoveredRowIndex: (rowIndex: number|null) => void;
    setIsSimplifiedUiChecked: (simplifiedUiChecked: boolean) => void;
    setCurrentPlatform: (currentPlatform: MarketingPlatforms) => void;
    setCurrentSelectedDateValue: (
        currentSelectedDateValue: DateRange | undefined,
    ) => void;
    setCampaignsSelected: (campaignsSelected: any[]) => void;
    setAdSetsSelected: (adSetsSelected: any[]) => void;
    setAdsSelected: (adsSelected: any[]) => void;
    setSirgeDataToggle: (sirgeDataToggle: boolean) => void;
    setAllPerformanceRows: (allPerformanceRows: PerformanceNew[]) => void;
    setSummaryPerformanceRows: (
        summaryPerformanceRows: PerformanceSummary,
    ) => void;
    setMemberCount: (memberCount: MemberCount | null) => void;
    setselectedAdGroupsExplore: (selectedAdGroupsExplore: {
        selected_campaign_ids: string[];
        selected_ad_set_ids: string[];
    }) => void;
    setCurrentPage: (currentPage: number) => void;
    setFilterProps: (filterProps: filterConditionType) => void;
    setLoading: (loading: PerformancePageState) => void;
    setActiveFilterCount: (activeFilterCount: number) => void;
    setAllChecked: (checkedItems: string[]) => void;
    setHandlerResetFilters: (resetAllFilters: CallbackState) => void;
    executeResetFilters: () => void;
    setSortChangeColumn: (
        sortChangeColumn: PerformanceSortObjectType | undefined,
    ) => void;
}

export interface PerformanceSlice extends State, Actions {
}

const initalPurchase = (): string | null => {
    const savedPurchase: string | null = 'Ads';
    if (typeof window !== 'undefined') {
        const localSavedPurchase = localStorage.getItem('current_purchase');
        if (localSavedPurchase) {
            return localSavedPurchase;
        }
    }
    return savedPurchase;
};
const initialSimplifiedUiChecked = () => {
    if (typeof window === 'undefined') {
        return true;
    }
    if (window.localStorage.getItem('simplifiedUiChecked') === null) {
        return true;
    }
    return localStorage.getItem('simplifiedUiChecked') === 'true';
}

const initialPlatform = (
    businessProfile: BusinessProfile | null | undefined,
    selectedBusiness: BusinessPrisma | null | undefined,
): MarketingPlatforms => {
    let savedPlatform: string | null = null;

    if (typeof window !== 'undefined') {
        const localPlatform = localStorage.getItem('current_platform');
        if (localPlatform !== null) {
            savedPlatform = localPlatform;
        }
    }
    let value;

    if (savedPlatform) {
        const fbPlatform = selectedBusiness?.ad_account_settings.find(
            (accountSetting) => {
                accountSetting.ad_platform.name === Platforms.FACEBOOK;
            },
        );
        const tiktokPlatform = selectedBusiness?.ad_account_settings.find(
            (accountSetting) => {
                accountSetting.ad_platform.name === Platforms.TIKTOK;
            },
        );
        if (
            savedPlatform === MarketingPlatforms.FACEBOOK &&
            businessProfile?.profile?.facebook_ad_account_id &&
            fbPlatform?.access_token
        )
            value = MarketingPlatforms.FACEBOOK;
        if (
            savedPlatform === MarketingPlatforms.TIKTOK &&
            businessProfile?.profile?.tik_tok_ad_account_id &&
            tiktokPlatform?.social_integration
        )
            value = MarketingPlatforms.TIKTOK;
    }

    if (!value) value = MarketingPlatforms.ALLPLATFORMS;
    return value as MarketingPlatforms;
};

const initalSummary = (): PerformanceSummary => {
    const summary = {
        amount_spent: 0,
        clicks: 0,
        purchases: 0,
        cost_per_purchase: 0,
        total_conversion_value: 0,
        roas: '',
        ft_clicks: 0,
        ft_purchases: 0,
        ft_cost_per_purchase: 0,
        ft_total_conversion_value: 0,
        ft_roas: '',
    } as PerformanceSummary;
    return summary;
};

export const createPerformanceSlice: StateCreator<
    PerformanceSlice & Partial<BusinessProfileSlice>,
    [],
    []
> = (set, get) => ({
    hoveredRowIndex: null,
    setHoveredRowIndex: (hoveredRowIndex: number|null) =>
        set((state) => ({...state, hoveredRowIndex})),
    currentPurchase: initalPurchase(),
    setCurrentPurchase: (currentPurchase: string) =>
        set((state) => ({...state, currentPurchase})),
    simplifiedUiChecked: initialSimplifiedUiChecked(),
    setIsSimplifiedUiChecked: (isSimplifiedUiChecked: boolean) =>
        set((state) => ({...state, simplifiedUiChecked: isSimplifiedUiChecked})),
    currentPlatform: initialPlatform(
        get()?.businessProfile,
        get()?.selectedBusiness,
    ),
    setCurrentPlatform: (currentPlatform: MarketingPlatforms) =>
        set((state) => ({...state, currentPlatform})),
    currentSelectedDateValue: undefined,
    setCurrentSelectedDateValue: (
        currentSelectedDateValue: DateRange | undefined,
    ) => set((state) => ({...state, currentSelectedDateValue})),
    campaignsSelected: [],
    setCampaignsSelected: (campaignsSelected: any[]) =>
        set((state) => ({...state, campaignsSelected})),
    adSetsSelected: [],
    setAdSetsSelected: (adSetsSelected: any[]) =>
        set((state) => ({...state, adSetsSelected})),
    adsSelected: [],
    setAdsSelected: (adsSelected: any[]) =>
        set((state) => ({...state, adsSelected})),
    sirgeDataToggle: false,
    setSirgeDataToggle: (sirgeDataToggle: boolean) =>
        set((state) => ({...state, sirgeDataToggle})),
    allPerformanceRows: [],
    setAllPerformanceRows: (allPerformanceRows: PerformanceNew[]) =>
        set((state) => ({...state, allPerformanceRows})),
    summaryPerformanceRows: initalSummary(),
    setSummaryPerformanceRows: (summaryPerformanceRows: PerformanceSummary) =>
        set((state) => ({...state, summaryPerformanceRows})),
    memberCount: {
        __typename: 'MemberCount',
        adSetCount: null,
        adsCount: null,
    },
    setMemberCount: (memberCount: MemberCount | null) =>
        set((state) => ({...state, memberCount})),
    selectedAdGroupsExplore: {
        selected_campaign_ids: [] as string[],
        selected_ad_set_ids: [] as string[],
    },
    setselectedAdGroupsExplore: (selectedAdGroupsExplore: {
        selected_campaign_ids: string[];
        selected_ad_set_ids: string[];
    }) => set((state) => ({...state, selectedAdGroupsExplore})),
    currentPage: 0,
    setCurrentPage: (currentPage: number) =>
        set((state) => ({...state, currentPage})),
    filterProps: {
        activeChecked: true,
        filterStatus: false,
        Condition: [],
        roas: null,
    },
    setFilterProps: (filterProps: filterConditionType) =>
        set((state) => {
            return {...state, filterProps};
        }),
    loading: PerformancePageState.loading,
    setLoading: (loading: PerformancePageState) =>
        set((state) => ({...state, loading: loading})),
    activeFilterCount: 1,
    setActiveFilterCount: (activeFilterCount: number) =>
        set((state) => ({...state, activeFilterCount})),
    allChecked: [],
    setAllChecked: (checkedItems: string[]) =>
        set((state) => ({...state, allChecked: checkedItems})),
    resetAllFilters: {callback: null},
    setHandlerResetFilters: (resetAllFilters) =>
        set((state) => ({...state, resetAllFilters})),
    executeResetFilters: () => get().resetAllFilters.callback?.(),
    sortChangeColumn: {field: 'amount_spent', sort: false},
    setSortChangeColumn: (
        sortChangeColumn: PerformanceSortObjectType | undefined,
    ) => set((state) => ({...state, sortChangeColumn: sortChangeColumn})),
});
