/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const activateBusinessNew = /* GraphQL */ `mutation ActivateBusinessNew($activateBusinessInput: ActivateBusinessInput!) {
  activateBusinessNew(activateBusinessInput: $activateBusinessInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ActivateBusinessNewMutationVariables,
  APITypes.ActivateBusinessNewMutation
>;
export const setBusinessGoal = /* GraphQL */ `mutation SetBusinessGoal($setBusinessGoalInput: SetBusinessGoalInput!) {
  setBusinessGoal(setBusinessGoalInput: $setBusinessGoalInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetBusinessGoalMutationVariables,
  APITypes.SetBusinessGoalMutation
>;
export const updateMiniShop = /* GraphQL */ `mutation UpdateMiniShop($updateMiniShopInput: GetMiniShopDetailsInput!) {
  updateMiniShop(updateMiniShopInput: $updateMiniShopInput) {
    data {
      topProducts {
        product_id
        name
        total_revenue
        product_image
        handle
        __typename
      }
      miniShop {
        id
        shop_name
        shop_image
        business_id
        top_products
        deleted_at
        updated_at
        created_at
        __typename
      }
      trackableLink {
        id
        name
        description
        type
        short_code
        destination_url
        url_position
        business_id
        created
        stats {
          clicks
          purchases
          revenue
          prev_clicks
          __typename
        }
        is_onboarding_default
        is_mini_shop
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMiniShopMutationVariables,
  APITypes.UpdateMiniShopMutation
>;
export const changePasswordNew = /* GraphQL */ `mutation ChangePasswordNew($changePasswordInput: ChangePasswordInput!) {
  changePasswordNew(changePasswordInput: $changePasswordInput) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ChangePasswordNewMutationVariables,
  APITypes.ChangePasswordNewMutation
>;
export const createTask = /* GraphQL */ `mutation CreateTask($createTaskInput: CreateTaskInput!) {
  createTask(createTaskInput: $createTaskInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskMutationVariables,
  APITypes.CreateTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask($updateTaskInput: UpdateTaskInput!) {
  updateTask(updateTaskInput: $updateTaskInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const updateBusinessOnboardingData = /* GraphQL */ `mutation UpdateBusinessOnboardingData(
  $updateBusinessOnboardingDataInput: UpdateBusinessOnboardingDataInput!
) {
  updateBusinessOnboardingData(
    updateBusinessOnboardingDataInput: $updateBusinessOnboardingDataInput
  ) {
    success
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessOnboardingDataMutationVariables,
  APITypes.UpdateBusinessOnboardingDataMutation
>;
export const upsertStoreNew = /* GraphQL */ `mutation UpsertStoreNew($upsertStoreInput: UpsertStoreInput!) {
  upsertStoreNew(upsertStoreInput: $upsertStoreInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertStoreNewMutationVariables,
  APITypes.UpsertStoreNewMutation
>;
export const updateTaskStatus = /* GraphQL */ `mutation UpdateTaskStatus($updateTaskStatusInput: UpdateTaskStatusInput!) {
  updateTaskStatus(updateTaskStatusInput: $updateTaskStatusInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskStatusMutationVariables,
  APITypes.UpdateTaskStatusMutation
>;
export const deleteTask = /* GraphQL */ `mutation DeleteTask($deleteTaskInput: DeleteTaskInput!) {
  deleteTask(deleteTaskInput: $deleteTaskInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const createStaffAccountNew = /* GraphQL */ `mutation CreateStaffAccountNew(
  $createStaffAccountInput: CreateStaffAccountInput!
) {
  createStaffAccountNew(createStaffAccountInput: $createStaffAccountInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStaffAccountNewMutationVariables,
  APITypes.CreateStaffAccountNewMutation
>;
export const updatePinnedInspirations = /* GraphQL */ `mutation UpdatePinnedInspirations(
  $updatePinnedInspirationsInput: UpdatePinnedInspirationsInput!
) {
  updatePinnedInspirations(
    updatePinnedInspirationsInput: $updatePinnedInspirationsInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePinnedInspirationsMutationVariables,
  APITypes.UpdatePinnedInspirationsMutation
>;
export const deleteStaffAccountNew = /* GraphQL */ `mutation DeleteStaffAccountNew(
  $deleteStaffAccountInput: DeleteStaffAccountInput!
) {
  deleteStaffAccountNew(deleteStaffAccountInput: $deleteStaffAccountInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStaffAccountNewMutationVariables,
  APITypes.DeleteStaffAccountNewMutation
>;
export const passwordResetLinkNew = /* GraphQL */ `mutation PasswordResetLinkNew($passwordResetInput: PasswordResetInput!) {
  passwordResetLinkNew(passwordResetInput: $passwordResetInput) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PasswordResetLinkNewMutationVariables,
  APITypes.PasswordResetLinkNewMutation
>;
export const updateUserDefaultBusinessNew = /* GraphQL */ `mutation UpdateUserDefaultBusinessNew(
  $updateUserDefaultBusinessNewInput: BusinessIdInput!
) {
  updateUserDefaultBusinessNew(
    updateUserDefaultBusinessNewInput: $updateUserDefaultBusinessNewInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserDefaultBusinessNewMutationVariables,
  APITypes.UpdateUserDefaultBusinessNewMutation
>;
export const sendUnlockFullPotentialEmail = /* GraphQL */ `mutation SendUnlockFullPotentialEmail($businessIdInput: BusinessIdInput!) {
  sendUnlockFullPotentialEmail(businessIdInput: $businessIdInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendUnlockFullPotentialEmailMutationVariables,
  APITypes.SendUnlockFullPotentialEmailMutation
>;
export const removeSocialAdAccount = /* GraphQL */ `mutation RemoveSocialAdAccount(
  $removeSocialAdAccountInput: BusinessIdWithPlatform!
) {
  removeSocialAdAccount(
    removeSocialAdAccountInput: $removeSocialAdAccountInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveSocialAdAccountMutationVariables,
  APITypes.RemoveSocialAdAccountMutation
>;
export const removeSocialUserAccess = /* GraphQL */ `mutation RemoveSocialUserAccess($businessIdInput: BusinessIdWithPlatform!) {
  removeSocialUserAccess(businessIdInput: $businessIdInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveSocialUserAccessMutationVariables,
  APITypes.RemoveSocialUserAccessMutation
>;
export const setSocialUserAccess = /* GraphQL */ `mutation SetSocialUserAccess($socialAccessStoreInput: SetSocialAccessInput!) {
  setSocialUserAccess(socialAccessStoreInput: $socialAccessStoreInput) {
    data {
      id
      business_id
      ad_platform_id
      premium_page_views
      external_platform
      active_campaign_count
      paused_campaign_count
      social_account_id
      social_account_name
      social_account_currency
      social_account_timezone
      access_token
      utm_count
      social_integration
      conversion_api_enabled
      pixel_id
      social_refresh_token
      updateKey
      last_data_refreshed
      created_at
      updated_at
      deleted_at
      ad_platform {
        id
        name
        internal_source_name
        dark_theme_image_url
        light_theme_image_url
        status
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetSocialUserAccessMutationVariables,
  APITypes.SetSocialUserAccessMutation
>;
export const setSocialAdAccount = /* GraphQL */ `mutation SetSocialAdAccount(
  $setSocialAdAccountInput: SetSocialAdAccountInput!
) {
  setSocialAdAccount(setSocialAdAccountInput: $setSocialAdAccountInput) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetSocialAdAccountMutationVariables,
  APITypes.SetSocialAdAccountMutation
>;
export const triggerBusinessDataLongFetchNew = /* GraphQL */ `mutation TriggerBusinessDataLongFetchNew(
  $triggerBusinessDataLongFetchInput: BusinessIdInput
) {
  triggerBusinessDataLongFetchNew(
    triggerBusinessDataLongFetchInput: $triggerBusinessDataLongFetchInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TriggerBusinessDataLongFetchNewMutationVariables,
  APITypes.TriggerBusinessDataLongFetchNewMutation
>;
export const triggerBusinessDayZeroDataCheck = /* GraphQL */ `mutation TriggerBusinessDayZeroDataCheck(
  $triggerBusinessDayZeroDataCheckInput: TriggerBusinessDayZeroDataCheckInput
) {
  triggerBusinessDayZeroDataCheck(
    triggerBusinessDayZeroDataCheckInput: $triggerBusinessDayZeroDataCheckInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.TriggerBusinessDayZeroDataCheckMutationVariables,
  APITypes.TriggerBusinessDayZeroDataCheckMutation
>;
export const updateBusinessByBusinessIdNew = /* GraphQL */ `mutation UpdateBusinessByBusinessIdNew(
  $setBusinessesInput: SetBusinessesInput!
) {
  updateBusinessByBusinessIdNew(setBusinessesInput: $setBusinessesInput) {
    data {
      id
      store_id
      name
      status
      logo
      vanity_name
      reminder_status
      show_budget_confirmation
      monthly_budget
      business_goal
      campaign_roas_goal
      adset_roas_goal
      ad_roas_goal
      created_at
      updated_at
      deleted_at
      ad_account_settings {
        id
        business_id
        ad_platform_id
        premium_page_views
        external_platform
        active_campaign_count
        paused_campaign_count
        social_account_id
        social_account_name
        social_account_currency
        social_account_timezone
        access_token
        utm_count
        social_integration
        conversion_api_enabled
        pixel_id
        social_refresh_token
        updateKey
        last_data_refreshed
        created_at
        updated_at
        deleted_at
        ad_platform {
          id
          name
          internal_source_name
          dark_theme_image_url
          light_theme_image_url
          status
          __typename
        }
        __typename
      }
      subscriptions {
        id
        business_id
        subscription_plan_code
        status
        processor
        store_subscription_body
        store_subscription_id
        promo_code_id
        trial_start
        trial_end
        trial_left
        current_billing_period_start
        current_billing_period_end
        subscription_end_date
        business_limit
        staff_limit
        subscription_charges {
          id
          business_id
          subscription_id
          store_revenue
          amount_billed
          created_at
          updated_at
          deleted_at
          __typename
        }
        promo_codes {
          id
          code
          type
          status
          duration
          amount
          created_at
          updated_at
          deleted_at
          __typename
        }
        current_revenue
        created_at
        updated_at
        deleted_at
        __typename
      }
      store {
        id
        shop_name
        address1
        address2
        city
        state
        zip
        country
        store_url
        timezone
        currency
        created_at
        updated_at
        deleted_at
        __typename
      }
      completed_onboarding_call
      has_visitor_session
      business_roas_goals {
        id
        roas_goal
        ad_platform_id
        ad_platforms {
          id
          name
          internal_source_name
          dark_theme_image_url
          light_theme_image_url
          status
          __typename
        }
        __typename
      }
      tour_completed_at
      tour_skipped_at
      feature_flags
      onboarding_call_completed_at
      pinned_inspirations
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessByBusinessIdNewMutationVariables,
  APITypes.UpdateBusinessByBusinessIdNewMutation
>;
export const updateBusinessLogoNew = /* GraphQL */ `mutation UpdateBusinessLogoNew(
  $updateBusinessLogoInput: UpdateBusinessLogoInput!
) {
  updateBusinessLogoNew(updateBusinessLogoInput: $updateBusinessLogoInput) {
    data {
      id
      store_id
      name
      status
      logo
      vanity_name
      reminder_status
      show_budget_confirmation
      monthly_budget
      business_goal
      campaign_roas_goal
      adset_roas_goal
      ad_roas_goal
      created_at
      updated_at
      deleted_at
      ad_account_settings {
        id
        business_id
        ad_platform_id
        premium_page_views
        external_platform
        active_campaign_count
        paused_campaign_count
        social_account_id
        social_account_name
        social_account_currency
        social_account_timezone
        access_token
        utm_count
        social_integration
        conversion_api_enabled
        pixel_id
        social_refresh_token
        updateKey
        last_data_refreshed
        created_at
        updated_at
        deleted_at
        ad_platform {
          id
          name
          internal_source_name
          dark_theme_image_url
          light_theme_image_url
          status
          __typename
        }
        __typename
      }
      subscriptions {
        id
        business_id
        subscription_plan_code
        status
        processor
        store_subscription_body
        store_subscription_id
        promo_code_id
        trial_start
        trial_end
        trial_left
        current_billing_period_start
        current_billing_period_end
        subscription_end_date
        business_limit
        staff_limit
        subscription_charges {
          id
          business_id
          subscription_id
          store_revenue
          amount_billed
          created_at
          updated_at
          deleted_at
          __typename
        }
        promo_codes {
          id
          code
          type
          status
          duration
          amount
          created_at
          updated_at
          deleted_at
          __typename
        }
        current_revenue
        created_at
        updated_at
        deleted_at
        __typename
      }
      store {
        id
        shop_name
        address1
        address2
        city
        state
        zip
        country
        store_url
        timezone
        currency
        created_at
        updated_at
        deleted_at
        __typename
      }
      completed_onboarding_call
      has_visitor_session
      business_roas_goals {
        id
        roas_goal
        ad_platform_id
        ad_platforms {
          id
          name
          internal_source_name
          dark_theme_image_url
          light_theme_image_url
          status
          __typename
        }
        __typename
      }
      tour_completed_at
      tour_skipped_at
      feature_flags
      onboarding_call_completed_at
      pinned_inspirations
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessLogoNewMutationVariables,
  APITypes.UpdateBusinessLogoNewMutation
>;
export const updateBusinessMetaData = /* GraphQL */ `mutation UpdateBusinessMetaData(
  $updateBusinessMetaDataInput: UpdateBusinessMetaDataInput!
) {
  updateBusinessMetaData(
    updateBusinessMetaDataInput: $updateBusinessMetaDataInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBusinessMetaDataMutationVariables,
  APITypes.UpdateBusinessMetaDataMutation
>;
export const updateFacebookConnectionSettings = /* GraphQL */ `mutation UpdateFacebookConnectionSettings(
  $updateFacebookConnectionSettingsInput: UpdateFacebookConnectionSettingsInput!
) {
  updateFacebookConnectionSettings(
    updateFacebookConnectionSettingsInput: $updateFacebookConnectionSettingsInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFacebookConnectionSettingsMutationVariables,
  APITypes.UpdateFacebookConnectionSettingsMutation
>;
export const updateStaffAccountAccessNew = /* GraphQL */ `mutation UpdateStaffAccountAccessNew(
  $updateStaffAccountAccessInput: UpdateStaffAccountAccessNewInput!
) {
  updateStaffAccountAccessNew(
    updateStaffAccountAccessInput: $updateStaffAccountAccessInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStaffAccountAccessNewMutationVariables,
  APITypes.UpdateStaffAccountAccessNewMutation
>;
export const updateTimezoneCurrencyNew = /* GraphQL */ `mutation UpdateTimezoneCurrencyNew(
  $updateTimezoneCurrencyInput: UpdateTimezoneCurrencyInput!
) {
  updateTimezoneCurrencyNew(
    updateTimezoneCurrencyInput: $updateTimezoneCurrencyInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTimezoneCurrencyNewMutationVariables,
  APITypes.UpdateTimezoneCurrencyNewMutation
>;
export const updateUserNew = /* GraphQL */ `mutation UpdateUserNew($updateUserNewInput: UpdateUserNewInput!) {
  updateUserNew(updateUserNewInput: $updateUserNewInput) {
    data {
      id
      first_name
      last_name
      email
      address1
      password
      timezone
      currency
      created_at
      updated_at
      deleted_at
      businesses {
        user_id
        business_id
        business {
          id
          store_id
          name
          status
          logo
          vanity_name
          reminder_status
          show_budget_confirmation
          monthly_budget
          business_goal
          campaign_roas_goal
          adset_roas_goal
          ad_roas_goal
          created_at
          updated_at
          deleted_at
          ad_account_settings {
            id
            business_id
            ad_platform_id
            premium_page_views
            external_platform
            active_campaign_count
            paused_campaign_count
            social_account_id
            social_account_name
            social_account_currency
            social_account_timezone
            access_token
            utm_count
            social_integration
            conversion_api_enabled
            pixel_id
            social_refresh_token
            updateKey
            last_data_refreshed
            created_at
            updated_at
            deleted_at
            ad_platform {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          subscriptions {
            id
            business_id
            subscription_plan_code
            status
            processor
            store_subscription_body
            store_subscription_id
            promo_code_id
            trial_start
            trial_end
            trial_left
            current_billing_period_start
            current_billing_period_end
            subscription_end_date
            business_limit
            staff_limit
            subscription_charges {
              id
              business_id
              subscription_id
              store_revenue
              amount_billed
              created_at
              updated_at
              deleted_at
              __typename
            }
            promo_codes {
              id
              code
              type
              status
              duration
              amount
              created_at
              updated_at
              deleted_at
              __typename
            }
            current_revenue
            created_at
            updated_at
            deleted_at
            __typename
          }
          store {
            id
            shop_name
            address1
            address2
            city
            state
            zip
            country
            store_url
            timezone
            currency
            created_at
            updated_at
            deleted_at
            __typename
          }
          completed_onboarding_call
          has_visitor_session
          business_roas_goals {
            id
            roas_goal
            ad_platform_id
            ad_platforms {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          tour_completed_at
          tour_skipped_at
          feature_flags
          onboarding_call_completed_at
          pinned_inspirations
          __typename
        }
        user_type
        is_default_business
        created_at
        updated_at
        deleted_at
        __typename
      }
      profile_photo
      is_admin_user
      link_tracking_visit_count
      link_tracking_view_status
      growth_hub_clicked_at
      inspirations_tutorial_finished_at
      mini_shop_tutorial_finished_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserNewMutationVariables,
  APITypes.UpdateUserNewMutation
>;
export const updateUserProfilePictureNew = /* GraphQL */ `mutation UpdateUserProfilePictureNew(
  $updateUserProfilePictureInput: UpdateUserProfilePictureInput!
) {
  updateUserProfilePictureNew(
    updateUserProfilePictureInput: $updateUserProfilePictureInput
  ) {
    data {
      default_business_id
      account_state
      affiliate_auth_token
      auto_scaling_setting
      balance
      cancellation_reason
      card_expiry_date
      card_last_four_digits
      card_type
      city
      client_billing_account_id
      country_code
      country_name
      country_phone_prefix
      created_at
      currency
      current_billing_period_end
      current_billing_period_start
      data_deleting_on
      data_retention_period
      email
      end_trial_source
      facebook_accessToken
      facebook_userID
      first_name
      firstpromoter_auth_token
      full_address
      id
      invoices {
        created_at
        customer_id
        id
        invoice_body
        status
        updated_at
        __typename
      }
      last_name
      line1
      manager_id
      marketing_status
      phone_number
      postal_code
      products {
        plan_product_id
        price_id
        product_code
        product_id
        product_name
        __typename
      }
      profile_photo
      state
      status
      stripe_connect_account_id
      subscription {
        created_at
        customer_id
        id
        status
        subscription_body
        updated_at
        trial_end
        trial_start
        trial_left
        promo_code {
          code
          status
          duration
          amount
          type
          __typename
        }
        plan {
          business_limit
          page_view_limit
          plan_code
          plan_name
          plan_price_id
          plan_product_id
          staff_limit
          __typename
        }
        plan_changed
        plan_code
        current_revenue
        current_billing_period_start
        current_billing_period_end
        __typename
      }
      subscription_status
      tik_tok_access_token
      tik_tok_integration
      timezone
      two_factor_deactivate_business
      two_factor_remove_staff_account
      updated_at
      user_id
      user_plan {
        business_limit
        page_view_limit
        plan_code
        plan_name
        plan_price_id
        plan_product_id
        staff_limit
        __typename
      }
      verification_method
      shopify_store_url
      business_access {
        vanity_name
        __typename
      }
      sessions {
        browser_name
        browser_version
        created_at
        ip
        location
        os_name
        os_version
        user_id
        __typename
      }
      post_hog_user_id
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfilePictureNewMutationVariables,
  APITypes.UpdateUserProfilePictureNewMutation
>;
export const verifyTwoFactorNew = /* GraphQL */ `mutation VerifyTwoFactorNew($verifyTwoFactorInput: VerifyTwoFactorInput!) {
  verifyTwoFactorNew(verifyTwoFactorInput: $verifyTwoFactorInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.VerifyTwoFactorNewMutationVariables,
  APITypes.VerifyTwoFactorNewMutation
>;
export const refreshDataForBusiness = /* GraphQL */ `mutation RefreshDataForBusiness(
  $refreshDataForBusinessInput: RefreshDataForBusiness!
) {
  refreshDataForBusiness(
    refreshDataForBusinessInput: $refreshDataForBusinessInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RefreshDataForBusinessMutationVariables,
  APITypes.RefreshDataForBusinessMutation
>;
export const updateShopifyStoreUrlNew = /* GraphQL */ `mutation UpdateShopifyStoreUrlNew(
  $updateShopifyStoreUrlInput: UpdateShopifyStoreUrlInput!
) {
  updateShopifyStoreUrlNew(
    updateShopifyStoreUrlInput: $updateShopifyStoreUrlInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateShopifyStoreUrlNewMutationVariables,
  APITypes.UpdateShopifyStoreUrlNewMutation
>;
export const setUserSessionNew = /* GraphQL */ `mutation SetUserSessionNew {
  setUserSessionNew {
    data {
      browser_name
      browser_version
      created_at
      ip
      location
      os_name
      os_version
      user {
        connect {
          id
          __typename
        }
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetUserSessionNewMutationVariables,
  APITypes.SetUserSessionNewMutation
>;
export const createShopifySubscriptionNew = /* GraphQL */ `mutation CreateShopifySubscriptionNew(
  $createShopifySubscriptionInput: CreateShopifySubscriptionInput!
) {
  createShopifySubscriptionNew(
    createShopifySubscriptionInput: $createShopifySubscriptionInput
  ) {
    data {
      confirmationUrl
      id
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShopifySubscriptionNewMutationVariables,
  APITypes.CreateShopifySubscriptionNewMutation
>;
export const createShopifySubscriptionNewPricing = /* GraphQL */ `mutation CreateShopifySubscriptionNewPricing(
  $createShopifySubscriptionInput: CreateShopifySubscriptionInput!
) {
  createShopifySubscriptionNewPricing(
    createShopifySubscriptionInput: $createShopifySubscriptionInput
  ) {
    data {
      confirmationUrl
      id
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShopifySubscriptionNewPricingMutationVariables,
  APITypes.CreateShopifySubscriptionNewPricingMutation
>;
export const registerShopifyUserNew = /* GraphQL */ `mutation RegisterShopifyUserNew(
  $registerShopifyUserInput: RegisterShopifyUserInput!
) {
  registerShopifyUserNew(registerShopifyUserInput: $registerShopifyUserInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    user {
      default_business_id
      account_state
      affiliate_auth_token
      auto_scaling_setting
      balance
      cancellation_reason
      card_expiry_date
      card_last_four_digits
      card_type
      city
      client_billing_account_id
      country_code
      country_name
      country_phone_prefix
      created_at
      currency
      current_billing_period_end
      current_billing_period_start
      data_deleting_on
      data_retention_period
      email
      end_trial_source
      facebook_accessToken
      facebook_userID
      first_name
      firstpromoter_auth_token
      full_address
      id
      invoices {
        created_at
        customer_id
        id
        invoice_body
        status
        updated_at
        __typename
      }
      last_name
      line1
      manager_id
      marketing_status
      phone_number
      postal_code
      products {
        plan_product_id
        price_id
        product_code
        product_id
        product_name
        __typename
      }
      profile_photo
      state
      status
      stripe_connect_account_id
      subscription {
        created_at
        customer_id
        id
        status
        subscription_body
        updated_at
        trial_end
        trial_start
        trial_left
        promo_code {
          code
          status
          duration
          amount
          type
          __typename
        }
        plan {
          business_limit
          page_view_limit
          plan_code
          plan_name
          plan_price_id
          plan_product_id
          staff_limit
          __typename
        }
        plan_changed
        plan_code
        current_revenue
        current_billing_period_start
        current_billing_period_end
        __typename
      }
      subscription_status
      tik_tok_access_token
      tik_tok_integration
      timezone
      two_factor_deactivate_business
      two_factor_remove_staff_account
      updated_at
      user_id
      user_plan {
        business_limit
        page_view_limit
        plan_code
        plan_name
        plan_price_id
        plan_product_id
        staff_limit
        __typename
      }
      verification_method
      shopify_store_url
      business_access {
        vanity_name
        __typename
      }
      sessions {
        browser_name
        browser_version
        created_at
        ip
        location
        os_name
        os_version
        user_id
        __typename
      }
      post_hog_user_id
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RegisterShopifyUserNewMutationVariables,
  APITypes.RegisterShopifyUserNewMutation
>;
export const checkShopifyLoginNew = /* GraphQL */ `mutation CheckShopifyLoginNew(
  $checkShopifyLoginInput: CheckShopifyLoginInput!
) {
  checkShopifyLoginNew(checkShopifyLoginInput: $checkShopifyLoginInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CheckShopifyLoginNewMutationVariables,
  APITypes.CheckShopifyLoginNewMutation
>;
export const sendRegisterUserEmailNew = /* GraphQL */ `mutation SendRegisterUserEmailNew(
  $sendRegisterUserEmailInput: SendRegisterUserEmailInput!
) {
  sendRegisterUserEmailNew(
    sendRegisterUserEmailInput: $sendRegisterUserEmailInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    user {
      id
      first_name
      last_name
      email
      address1
      password
      timezone
      currency
      created_at
      updated_at
      deleted_at
      businesses {
        user_id
        business_id
        business {
          id
          store_id
          name
          status
          logo
          vanity_name
          reminder_status
          show_budget_confirmation
          monthly_budget
          business_goal
          campaign_roas_goal
          adset_roas_goal
          ad_roas_goal
          created_at
          updated_at
          deleted_at
          ad_account_settings {
            id
            business_id
            ad_platform_id
            premium_page_views
            external_platform
            active_campaign_count
            paused_campaign_count
            social_account_id
            social_account_name
            social_account_currency
            social_account_timezone
            access_token
            utm_count
            social_integration
            conversion_api_enabled
            pixel_id
            social_refresh_token
            updateKey
            last_data_refreshed
            created_at
            updated_at
            deleted_at
            ad_platform {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          subscriptions {
            id
            business_id
            subscription_plan_code
            status
            processor
            store_subscription_body
            store_subscription_id
            promo_code_id
            trial_start
            trial_end
            trial_left
            current_billing_period_start
            current_billing_period_end
            subscription_end_date
            business_limit
            staff_limit
            subscription_charges {
              id
              business_id
              subscription_id
              store_revenue
              amount_billed
              created_at
              updated_at
              deleted_at
              __typename
            }
            promo_codes {
              id
              code
              type
              status
              duration
              amount
              created_at
              updated_at
              deleted_at
              __typename
            }
            current_revenue
            created_at
            updated_at
            deleted_at
            __typename
          }
          store {
            id
            shop_name
            address1
            address2
            city
            state
            zip
            country
            store_url
            timezone
            currency
            created_at
            updated_at
            deleted_at
            __typename
          }
          completed_onboarding_call
          has_visitor_session
          business_roas_goals {
            id
            roas_goal
            ad_platform_id
            ad_platforms {
              id
              name
              internal_source_name
              dark_theme_image_url
              light_theme_image_url
              status
              __typename
            }
            __typename
          }
          tour_completed_at
          tour_skipped_at
          feature_flags
          onboarding_call_completed_at
          pinned_inspirations
          __typename
        }
        user_type
        is_default_business
        created_at
        updated_at
        deleted_at
        __typename
      }
      profile_photo
      is_admin_user
      link_tracking_visit_count
      link_tracking_view_status
      growth_hub_clicked_at
      inspirations_tutorial_finished_at
      mini_shop_tutorial_finished_at
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SendRegisterUserEmailNewMutationVariables,
  APITypes.SendRegisterUserEmailNewMutation
>;
export const cancelShopifySubscriptionNew = /* GraphQL */ `mutation CancelShopifySubscriptionNew(
  $cancelShopifySubscriptionInput: CancelShopifySubscriptionInput
) {
  cancelShopifySubscriptionNew(
    cancelShopifySubscriptionInput: $cancelShopifySubscriptionInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelShopifySubscriptionNewMutationVariables,
  APITypes.CancelShopifySubscriptionNewMutation
>;
export const cancelShopifySubscriptionEarlyNew = /* GraphQL */ `mutation CancelShopifySubscriptionEarlyNew(
  $cancelShopifySubscriptionEarlyInput: CancelShopifySubscriptionEarlyInput!
) {
  cancelShopifySubscriptionEarlyNew(
    cancelShopifySubscriptionEarlyInput: $cancelShopifySubscriptionEarlyInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelShopifySubscriptionEarlyNewMutationVariables,
  APITypes.CancelShopifySubscriptionEarlyNewMutation
>;
export const createShopifyScriptTagNew = /* GraphQL */ `mutation CreateShopifyScriptTagNew {
  createShopifyScriptTagNew {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateShopifyScriptTagNewMutationVariables,
  APITypes.CreateShopifyScriptTagNewMutation
>;
export const generateBusinessAIReport = /* GraphQL */ `mutation GenerateBusinessAIReport(
  $generateBusinessAIReportInput: GenerateBusinessAIReportInput!
) {
  generateBusinessAIReport(
    generateBusinessAIReportInput: $generateBusinessAIReportInput
  ) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateBusinessAIReportMutationVariables,
  APITypes.GenerateBusinessAIReportMutation
>;
export const setUtmValuesSocialCampaign = /* GraphQL */ `mutation SetUtmValuesSocialCampaign(
  $setUtmValuesSocialCampaignInput: SetUtmValuesSocialCampaignInput!
) {
  setUtmValuesSocialCampaign(
    setUtmValuesSocialCampaignInput: $setUtmValuesSocialCampaignInput
  ) {
    error {
      code
      message
      __typename
    }
    data {
      totalAdsToConnect
      adsConnected
      adsFailed {
        ad
        error
        __typename
      }
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetUtmValuesSocialCampaignMutationVariables,
  APITypes.SetUtmValuesSocialCampaignMutation
>;
export const addDiscountCodeNew = /* GraphQL */ `mutation AddDiscountCodeNew($addDiscountCodeInput: AddDiscountCodeInput!) {
  addDiscountCodeNew(addDiscountCodeInput: $addDiscountCodeInput) {
    data {
      id
      code
      type
      status
      duration
      amount
      created_at
      updated_at
      deleted_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDiscountCodeNewMutationVariables,
  APITypes.AddDiscountCodeNewMutation
>;
export const addDiscountCodeInactiveSubscriptionNew = /* GraphQL */ `mutation AddDiscountCodeInactiveSubscriptionNew(
  $addDiscountCodeInput: AddDiscountCodeInput!
) {
  addDiscountCodeInactiveSubscriptionNew(
    addDiscountCodeInput: $addDiscountCodeInput
  ) {
    data {
      id
      code
      type
      status
      duration
      amount
      created_at
      updated_at
      deleted_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AddDiscountCodeInactiveSubscriptionNewMutationVariables,
  APITypes.AddDiscountCodeInactiveSubscriptionNewMutation
>;
export const removeDiscountCodeNew = /* GraphQL */ `mutation RemoveDiscountCodeNew($addDiscountCodeInput: AddDiscountCodeInput!) {
  removeDiscountCodeNew(addDiscountCodeInput: $addDiscountCodeInput) {
    data {
      id
      business_id
      subscription_plan_code
      status
      processor
      store_subscription_body
      store_subscription_id
      promo_code_id
      trial_start
      trial_end
      trial_left
      current_billing_period_start
      current_billing_period_end
      subscription_end_date
      business_limit
      staff_limit
      subscription_charges {
        id
        business_id
        subscription_id
        store_revenue
        amount_billed
        created_at
        updated_at
        deleted_at
        __typename
      }
      promo_codes {
        id
        code
        type
        status
        duration
        amount
        created_at
        updated_at
        deleted_at
        __typename
      }
      current_revenue
      created_at
      updated_at
      deleted_at
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RemoveDiscountCodeNewMutationVariables,
  APITypes.RemoveDiscountCodeNewMutation
>;
export const updateAdLevelStatusNew = /* GraphQL */ `mutation UpdateAdLevelStatusNew(
  $updateAdLevelStatusInput: UpdateAdLevelStatusInput!
) {
  updateAdLevelStatusNew(updateAdLevelStatusInput: $updateAdLevelStatusInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdLevelStatusNewMutationVariables,
  APITypes.UpdateAdLevelStatusNewMutation
>;
export const updateAdDailyBudgetNew = /* GraphQL */ `mutation UpdateAdDailyBudgetNew(
  $updateAdDailyBudgetInput: updateAdDailyBudgetInput!
) {
  updateAdDailyBudgetNew(updateAdDailyBudgetInput: $updateAdDailyBudgetInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAdDailyBudgetNewMutationVariables,
  APITypes.UpdateAdDailyBudgetNewMutation
>;
export const createTrackableCopyNew = /* GraphQL */ `mutation CreateTrackableCopyNew(
  $createTrackableCopyInput: CreateTrackableCopyInput!
) {
  createTrackableCopyNew(createTrackableCopyInput: $createTrackableCopyInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTrackableCopyNewMutationVariables,
  APITypes.CreateTrackableCopyNewMutation
>;
export const deleteTrackableCopyNew = /* GraphQL */ `mutation DeleteTrackableCopyNew(
  $deleteTrackableCopyInput: DeleteTrackableCopyInput!
) {
  deleteTrackableCopyNew(deleteTrackableCopyInput: $deleteTrackableCopyInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTrackableCopyNewMutationVariables,
  APITypes.DeleteTrackableCopyNewMutation
>;
export const updateTrackableCopyNew = /* GraphQL */ `mutation UpdateTrackableCopyNew(
  $updateTrackableCopyInput: UpdateTrackableCopyInput!
) {
  updateTrackableCopyNew(updateTrackableCopyInput: $updateTrackableCopyInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTrackableCopyNewMutationVariables,
  APITypes.UpdateTrackableCopyNewMutation
>;
export const updateKeyMetricsMonthlyBudget = /* GraphQL */ `mutation UpdateKeyMetricsMonthlyBudget(
  $updateMonthlyBudgetInput: UpdateMonthlyBudgetInput!
) {
  updateKeyMetricsMonthlyBudget(
    updateMonthlyBudgetInput: $updateMonthlyBudgetInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeyMetricsMonthlyBudgetMutationVariables,
  APITypes.UpdateKeyMetricsMonthlyBudgetMutation
>;
export const updateKeyMetricsRoasTracker = /* GraphQL */ `mutation UpdateKeyMetricsRoasTracker(
  $updateKeyMetricsRoasGoalsInput: UpdateKeyMetricsRoasGoalsInput!
) {
  updateKeyMetricsRoasTracker(
    updateKeyMetricsRoasGoalsInput: $updateKeyMetricsRoasGoalsInput
  ) {
    data {
      id
      roas_goal
      ad_platform_id
      ad_platforms {
        id
        name
        internal_source_name
        dark_theme_image_url
        light_theme_image_url
        status
        __typename
      }
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateKeyMetricsRoasTrackerMutationVariables,
  APITypes.UpdateKeyMetricsRoasTrackerMutation
>;
export const updateAudienceSegmentQueryById = /* GraphQL */ `mutation UpdateAudienceSegmentQueryById(
  $updateAudienceSegmentInput: UpdateAudienceSegmentQueryInput!
) {
  updateAudienceSegmentQueryById(
    updateAudienceSegmentInput: $updateAudienceSegmentInput
  ) {
    data {
      success
      social_audience {
        facebook {
          error {
            message
            __typename
          }
          user_list_id
          audience_id
          __typename
        }
        google {
          error {
            message
            __typename
          }
          user_list_id
          audience_id
          __typename
        }
        __typename
      }
      audience_errors
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAudienceSegmentQueryByIdMutationVariables,
  APITypes.UpdateAudienceSegmentQueryByIdMutation
>;
export const deleteAudienceSegmentQueryById = /* GraphQL */ `mutation DeleteAudienceSegmentQueryById(
  $deleteAudienceSegmentInput: DeleteAudienceSegmentInput!
) {
  deleteAudienceSegmentQueryById(
    deleteAudienceSegmentInput: $deleteAudienceSegmentInput
  ) {
    data {
      success
      social_audience {
        facebook {
          error {
            message
            __typename
          }
          user_list_id
          audience_id
          __typename
        }
        google {
          error {
            message
            __typename
          }
          user_list_id
          audience_id
          __typename
        }
        __typename
      }
      audience_errors
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAudienceSegmentQueryByIdMutationVariables,
  APITypes.DeleteAudienceSegmentQueryByIdMutation
>;
export const createSuggestedSegments = /* GraphQL */ `mutation CreateSuggestedSegments(
  $createSuggestedSegmentsInput: CreateSuggestedSegmentsInput!
) {
  createSuggestedSegments(
    createSuggestedSegmentsInput: $createSuggestedSegmentsInput
  ) {
    data {
      id
      business_id
      no_of_customers
      added_revenue
      audience
      type
      status
      query_details
      created_at
      updated_at
      group_name
      deleted_at
      dynamic_query_params
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSuggestedSegmentsMutationVariables,
  APITypes.CreateSuggestedSegmentsMutation
>;
export const createUserIntercomEmailTwo = /* GraphQL */ `mutation CreateUserIntercomEmailTwo(
  $createUserIntercomEmailTwoInput: CreateUserIntercomEmailTwoInput!
) {
  createUserIntercomEmailTwo(
    createUserIntercomEmailTwoInput: $createUserIntercomEmailTwoInput
  ) {
    message
    error {
      code
      message
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserIntercomEmailTwoMutationVariables,
  APITypes.CreateUserIntercomEmailTwoMutation
>;
export const createUserStoreSessions = /* GraphQL */ `mutation CreateUserStoreSessions(
  $createUserStoreSessionsInput: CreateUserStoreSessionsInput!
) {
  createUserStoreSessions(
    createUserStoreSessionsInput: $createUserStoreSessionsInput
  ) {
    data {
      shop
      state
      isOnline
      scope
      expires
      accessToken
      __typename
    }
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserStoreSessionsMutationVariables,
  APITypes.CreateUserStoreSessionsMutation
>;
export const setStoreBuildConfig = /* GraphQL */ `mutation SetStoreBuildConfig(
  $setStoreBuildConfigInput: SetStoreBuildConfigInput!
) {
  setStoreBuildConfig(setStoreBuildConfigInput: $setStoreBuildConfigInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SetStoreBuildConfigMutationVariables,
  APITypes.SetStoreBuildConfigMutation
>;
export const createStore = /* GraphQL */ `mutation CreateStore($createStoreInput: CreateStoreInput!) {
  createStore(createStoreInput: $createStoreInput) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStoreMutationVariables,
  APITypes.CreateStoreMutation
>;
export const deleteStoreByShopName = /* GraphQL */ `mutation DeleteStoreByShopName(
  $deleteStoreByShopNameInput: DeleteStoreByShopNameInput!
) {
  deleteStoreByShopName(
    deleteStoreByShopNameInput: $deleteStoreByShopNameInput
  ) {
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStoreByShopNameMutationVariables,
  APITypes.DeleteStoreByShopNameMutation
>;
export const extendTrial = /* GraphQL */ `mutation ExtendTrial($extendTrialInput: ExtendTrialInput!) {
  extendTrial(extendTrialInput: $extendTrialInput) {
    data
    error {
      code
      message
      __typename
    }
    message
    nextToken
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ExtendTrialMutationVariables,
  APITypes.ExtendTrialMutation
>;
