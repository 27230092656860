/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ActivateBusinessInput = {
  business_id: string,
};

export type ActivateBusinessResponse = {
  __typename: "ActivateBusinessResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type ErrorResponse = {
  __typename: "ErrorResponse",
  code?: string | null,
  message?: string | null,
};

export type SetBusinessGoalInput = {
  business_id: string,
  goal: string,
};

export type ApplyPromoCodeResponse = {
  __typename: "ApplyPromoCodeResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetMiniShopDetailsInput = {
  business_id: string,
  dateStart: string,
  dateEnd: string,
};

export type UpdateMiniShopResponse = {
  __typename: "UpdateMiniShopResponse",
  data?: MiniShopDetails | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type MiniShopDetails = {
  __typename: "MiniShopDetails",
  topProducts?:  Array<PerformingProductsData | null > | null,
  miniShop?: MiniShop | null,
  trackableLink?: TrackableCopy | null,
};

export type PerformingProductsData = {
  __typename: "PerformingProductsData",
  product_id?: string | null,
  name?: string | null,
  total_revenue?: number | null,
  product_image?: string | null,
  handle?: string | null,
};

export type MiniShop = {
  __typename: "MiniShop",
  id?: string | null,
  shop_name?: string | null,
  shop_image?: string | null,
  business_id?: string | null,
  top_products?: string | null,
  deleted_at?: string | null,
  updated_at?: string | null,
  created_at?: string | null,
};

export type TrackableCopy = {
  __typename: "TrackableCopy",
  id: string,
  name: string,
  description?: string | null,
  type?: string | null,
  short_code: string,
  destination_url: string,
  url_position?: string | null,
  business_id?: string | null,
  created?: string | null,
  stats?: TrackableCopyStats | null,
  is_onboarding_default?: boolean | null,
  is_mini_shop?: boolean | null,
};

export type TrackableCopyStats = {
  __typename: "TrackableCopyStats",
  clicks?: number | null,
  purchases?: number | null,
  revenue?: number | null,
  prev_clicks?: number | null,
};

export type ChangePasswordInput = {
  password: string,
  two_factor_id: string,
};

export type ChangePasswordResponse = {
  __typename: "ChangePasswordResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type CreateTaskInput = {
  title: string,
  description: string,
  priority: number,
  objectives?: Array< string | null > | null,
  is_task_master: boolean,
  business_id?: string | null,
  deeplink_target_url?: string | null,
  target_button_title?: string | null,
  task_image_url?: string | null,
};

export type TaskResponse = {
  __typename: "TaskResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateTaskInput = {
  task_id: string,
  title: string,
  description: string,
  priority: number,
  objectives?: Array< string | null > | null,
  deeplink_target_url?: string | null,
  target_button_title?: string | null,
  task_image_url?: string | null,
};

export type UpdateBusinessOnboardingDataInput = {
  business_id: string,
};

export type UpdateBusinessOnboardingResponse = {
  __typename: "UpdateBusinessOnboardingResponse",
  success?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type UpsertStoreInput = {
  myshopify_url: string,
  access_token: string,
};

export type UpsertStoreResponse = {
  __typename: "UpsertStoreResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateTaskStatusInput = {
  task_x_business_id: string,
  status: string,
  business_id: string,
};

export type DeleteTaskInput = {
  task_id: string,
};

export type CreateStaffAccountInput = {
  email: string,
  first_name: string,
  last_name: string,
  password: string,
  business_id: string,
  staff_user_id?: string | null,
};

export type CreateStaffAccountResponse = {
  __typename: "CreateStaffAccountResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdatePinnedInspirationsInput = {
  business_id: string,
  add: string,
};

export type UpdatePinnedInspirationsResponse = {
  __typename: "UpdatePinnedInspirationsResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type DeleteStaffAccountInput = {
  staff_id: string,
  business_id: string,
};

export type DeleteStaffAccountResponse = {
  __typename: "DeleteStaffAccountResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type PasswordResetInput = {
  email: string,
};

export type PasswordResetResponse = {
  __typename: "PasswordResetResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type BusinessIdInput = {
  business_id: string,
};

export type GeneralApiResponse = {
  __typename: "GeneralApiResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type BusinessIdWithPlatform = {
  business_id: string,
  platform?: string | null,
};

export type RemoveSocialAdAccountResponse = {
  __typename: "RemoveSocialAdAccountResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type RemoveSocialAccessResponse = {
  __typename: "RemoveSocialAccessResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type SetSocialAccessInput = {
  platform: string,
  user_id?: string | null,
  business_id: string,
  access_token?: string | null,
  refresh_token?: string | null,
};

export type SetSocialAccessResponse = {
  __typename: "SetSocialAccessResponse",
  data?: AdAccountSettingsPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type AdAccountSettingsPrisma = {
  __typename: "AdAccountSettingsPrisma",
  id: string,
  business_id: string,
  ad_platform_id: string,
  premium_page_views: number,
  external_platform?: string | null,
  active_campaign_count: number,
  paused_campaign_count: number,
  social_account_id?: string | null,
  social_account_name?: string | null,
  social_account_currency?: string | null,
  social_account_timezone?: string | null,
  access_token?: string | null,
  utm_count: number,
  social_integration?: string | null,
  conversion_api_enabled?: string | null,
  pixel_id?: string | null,
  social_refresh_token?: string | null,
  updateKey?: string | null,
  last_data_refreshed?: string | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
  ad_platform: AdPlatformPrisma,
};

export type AdPlatformPrisma = {
  __typename: "AdPlatformPrisma",
  id: string,
  name: string,
  internal_source_name: string,
  dark_theme_image_url?: string | null,
  light_theme_image_url: string,
  status: string,
};

export type SetSocialAdAccountInput = {
  platform: string,
  ad_account_id: string,
  business_id: string,
  timezone?: string | null,
  currency?: string | null,
  is_onboarding?: boolean | null,
};

export type SetSocialAdAccountResponse = {
  __typename: "SetSocialAdAccountResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type TriggerBusinessDataLongFetchResponse = {
  __typename: "TriggerBusinessDataLongFetchResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type TriggerBusinessDayZeroDataCheckInput = {
  business_id: string,
};

export type TriggerBusinessDayZeroDataCheckResponse = {
  __typename: "TriggerBusinessDayZeroDataCheckResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type SetBusinessesInput = {
  business_id: string,
  business_name?: string | null,
  tour_skipped_at?: string | null,
  tour_completed_at?: string | null,
};

export type SetBusinessesPrismaInputResponse = {
  __typename: "SetBusinessesPrismaInputResponse",
  data?: BusinessPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type BusinessPrisma = {
  __typename: "BusinessPrisma",
  id: string,
  store_id: string,
  name: string,
  status: string,
  logo?: string | null,
  vanity_name: string,
  reminder_status?: boolean | null,
  show_budget_confirmation?: boolean | null,
  monthly_budget: number,
  business_goal?: string | null,
  campaign_roas_goal?: string | null,
  adset_roas_goal?: string | null,
  ad_roas_goal?: string | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
  ad_account_settings:  Array<AdAccountSettingsPrisma >,
  subscriptions:  Array<SubscriptionPrisma >,
  store?: StorePrisma | null,
  completed_onboarding_call?: boolean | null,
  has_visitor_session?: boolean | null,
  business_roas_goals?:  Array<BusinessRoasGoalPrisma | null > | null,
  tour_completed_at?: string | null,
  tour_skipped_at?: string | null,
  feature_flags?: Array< string | null > | null,
  onboarding_call_completed_at?: string | null,
  pinned_inspirations?: Array< string | null > | null,
};

export type SubscriptionPrisma = {
  __typename: "SubscriptionPrisma",
  id: string,
  business_id: string,
  subscription_plan_code?: string | null,
  status: string,
  processor?: string | null,
  store_subscription_body?: string | null,
  store_subscription_id?: string | null,
  promo_code_id?: string | null,
  trial_start?: string | null,
  trial_end?: string | null,
  trial_left?: number | null,
  current_billing_period_start?: string | null,
  current_billing_period_end?: string | null,
  subscription_end_date?: string | null,
  business_limit?: number | null,
  staff_limit?: number | null,
  subscription_charges?:  Array<SubscriptionChargesPrisma | null > | null,
  promo_codes?: PromoCodePrisma | null,
  current_revenue?: number | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
};

export type SubscriptionChargesPrisma = {
  __typename: "SubscriptionChargesPrisma",
  id: string,
  business_id: string,
  subscription_id?: string | null,
  store_revenue: number,
  amount_billed: number,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
};

export type PromoCodePrisma = {
  __typename: "PromoCodePrisma",
  id?: string | null,
  code?: string | null,
  type?: string | null,
  status?: string | null,
  duration?: number | null,
  amount?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
};

export type StorePrisma = {
  __typename: "StorePrisma",
  id?: string | null,
  shop_name?: string | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  store_url?: string | null,
  timezone?: string | null,
  currency?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
};

export type BusinessRoasGoalPrisma = {
  __typename: "BusinessRoasGoalPrisma",
  id: string,
  roas_goal: number,
  ad_platform_id: string,
  ad_platforms: AdPlatformPrisma,
};

export type UpdateBusinessLogoInput = {
  business_id: string,
  file_url: string,
};

export type UpdateBusinessLogoNewResponse = {
  __typename: "UpdateBusinessLogoNewResponse",
  data?: BusinessPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateBusinessMetaDataInput = {
  business_id: string,
  metadata?: string | null,
};

export type UpdateBusinessMetaDataResponse = {
  __typename: "UpdateBusinessMetaDataResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateFacebookConnectionSettingsInput = {
  business_id: string,
  fb_pixel_id: number,
};

export type UpdateFacebookConnectionSettingsResponse = {
  __typename: "UpdateFacebookConnectionSettingsResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type UpdateStaffAccountAccessNewInput = {
  staff_id: string,
  business_id: string,
};

export type UpdateStaffAccountAccessResponse = {
  __typename: "UpdateStaffAccountAccessResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateTimezoneCurrencyInput = {
  currency: string,
  timezone: string,
  business_id: string,
};

export type UpdateTimezoneCurrencyResponse = {
  __typename: "UpdateTimezoneCurrencyResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type UpdateUserNewInput = {
  first_name?: string | null,
  address1?: string | null,
  address2?: string | null,
  last_name?: string | null,
  zip?: string | null,
  street_name?: string | null,
  street_number?: string | null,
  country?: string | null,
  country_code?: string | null,
  business_id: string,
  profile_photo?: string | null,
  link_tracking_visit_count?: number | null,
  link_tracking_view_status?: string | null,
  growth_hub_clicked?: boolean | null,
  inspirations_tutorial_finished?: boolean | null,
  mini_shop_tutorial_finished?: boolean | null,
};

export type UpdateUserNewResponse = {
  __typename: "UpdateUserNewResponse",
  data?: UserPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UserPrisma = {
  __typename: "UserPrisma",
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  address1?: string | null,
  password?: string | null,
  timezone?: string | null,
  currency?: string | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
  businesses?:  Array<UserXBusinessPrisma > | null,
  profile_photo?: string | null,
  is_admin_user?: boolean | null,
  link_tracking_visit_count?: number | null,
  link_tracking_view_status?: string | null,
  growth_hub_clicked_at?: string | null,
  inspirations_tutorial_finished_at?: string | null,
  mini_shop_tutorial_finished_at?: string | null,
};

export type UserXBusinessPrisma = {
  __typename: "UserXBusinessPrisma",
  user_id: string,
  business_id: string,
  business: BusinessPrisma,
  user_type: string,
  is_default_business: boolean,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
};

export type UpdateUserProfilePictureInput = {
  file_url: string,
  business_id: string,
};

export type UpdateUserProfilePictureResponse = {
  __typename: "UpdateUserProfilePictureResponse",
  data?: User | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type User = {
  __typename: "User",
  default_business_id?: string | null,
  account_state?: string | null,
  affiliate_auth_token?: string | null,
  auto_scaling_setting?: number | null,
  balance?: number | null,
  cancellation_reason?: string | null,
  card_expiry_date?: string | null,
  card_last_four_digits?: string | null,
  card_type?: string | null,
  city?: string | null,
  client_billing_account_id?: string | null,
  country_code?: string | null,
  country_name?: string | null,
  country_phone_prefix?: string | null,
  created_at?: string | null,
  currency?: string | null,
  current_billing_period_end?: number | null,
  current_billing_period_start?: number | null,
  data_deleting_on?: number | null,
  data_retention_period?: number | null,
  email?: string | null,
  end_trial_source?: string | null,
  facebook_accessToken?: string | null,
  facebook_userID?: string | null,
  first_name?: string | null,
  firstpromoter_auth_token?: string | null,
  full_address?: string | null,
  id?: string | null,
  invoices?:  Array<Invoice | null > | null,
  last_name?: string | null,
  line1?: string | null,
  manager_id?: string | null,
  marketing_status?: number | null,
  phone_number?: string | null,
  postal_code?: string | null,
  products?: Product | null,
  profile_photo?: string | null,
  state?: string | null,
  status?: string | null,
  stripe_connect_account_id?: string | null,
  subscription?: Subscription | null,
  subscription_status?: string | null,
  tik_tok_access_token?: string | null,
  tik_tok_integration?: boolean | null,
  timezone?: string | null,
  two_factor_deactivate_business?: number | null,
  two_factor_remove_staff_account?: number | null,
  updated_at?: string | null,
  user_id?: string | null,
  user_plan?: Plan | null,
  verification_method?: string | null,
  shopify_store_url?: string | null,
  business_access?:  Array<BusinessAccess | null > | null,
  sessions?:  Array<UserSession | null > | null,
  post_hog_user_id?: string | null,
};

export type Invoice = {
  __typename: "Invoice",
  created_at?: string | null,
  customer_id?: string | null,
  id?: string | null,
  invoice_body?: string | null,
  status?: string | null,
  updated_at?: string | null,
};

export type Product = {
  __typename: "Product",
  plan_product_id?: string | null,
  price_id?: string | null,
  product_code?: string | null,
  product_id?: string | null,
  product_name?: string | null,
};

export type Subscription = {
  __typename: "Subscription",
  created_at?: string | null,
  customer_id?: string | null,
  id?: string | null,
  status?: string | null,
  subscription_body?: string | null,
  updated_at?: string | null,
  trial_end?: string | null,
  trial_start?: string | null,
  trial_left?: number | null,
  promo_code?: DiscountCode | null,
  plan?: Plan | null,
  plan_changed?: boolean | null,
  plan_code?: string | null,
  current_revenue?: number | null,
  current_billing_period_start?: string | null,
  current_billing_period_end?: string | null,
};

export type DiscountCode = {
  __typename: "DiscountCode",
  code?: string | null,
  status?: string | null,
  duration?: number | null,
  amount?: number | null,
  type?: string | null,
};

export type Plan = {
  __typename: "Plan",
  business_limit?: number | null,
  page_view_limit?: number | null,
  plan_code?: string | null,
  plan_name?: string | null,
  plan_price_id?: string | null,
  plan_product_id?: string | null,
  staff_limit?: number | null,
};

export type BusinessAccess = {
  __typename: "BusinessAccess",
  vanity_name?: string | null,
};

export type UserSession = {
  __typename: "UserSession",
  browser_name?: string | null,
  browser_version?: string | null,
  created_at?: string | null,
  ip?: string | null,
  location?: string | null,
  os_name?: string | null,
  os_version?: string | null,
  user_id?: string | null,
};

export type VerifyTwoFactorInput = {
  two_factor_id?: string | null,
  email_confirm?: boolean | null,
};

export type VerifyTwoFactorResponse = {
  __typename: "VerifyTwoFactorResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type RefreshDataForBusiness = {
  business_id?: string | null,
};

export type RefreshDataForResponse = {
  __typename: "RefreshDataForResponse",
  data?: string | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateShopifyStoreUrlInput = {
  business_id: string,
  shopify_url: string,
};

export type UpdateShopifyStoreUrlResponse = {
  __typename: "UpdateShopifyStoreUrlResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type SetUserSessionResponsePrisma = {
  __typename: "SetUserSessionResponsePrisma",
  data?: UserSessionPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UserSessionPrisma = {
  __typename: "UserSessionPrisma",
  browser_name?: string | null,
  browser_version?: string | null,
  created_at?: string | null,
  ip?: string | null,
  location?: string | null,
  os_name?: string | null,
  os_version?: string | null,
  user?: UserConnectionPrisma | null,
};

export type UserConnectionPrisma = {
  __typename: "UserConnectionPrisma",
  connect: UserIDPrisma,
};

export type UserIDPrisma = {
  __typename: "UserIDPrisma",
  id: string,
};

export type CreateShopifySubscriptionInput = {
  plan_name: string,
  business_id: string,
  irclick_id?: string | null,
};

export type CreateShopifySubscriptionResponse = {
  __typename: "CreateShopifySubscriptionResponse",
  data?: ShopifySubscription | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type ShopifySubscription = {
  __typename: "ShopifySubscription",
  confirmationUrl?: string | null,
  id?: string | null,
};

export type RegisterShopifyUserInput = {
  email: string,
  first_name: string,
  last_name: string,
  password: string,
  shopify_store_url: string,
  post_hog_user_id?: string | null,
  business_id?: string | null,
  business_vanity_name?: string | null,
};

export type RegisterShopifyUserResponse = {
  __typename: "RegisterShopifyUserResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  user?: User | null,
};

export type CheckShopifyLoginInput = {
  shopify_store_url?: string | null,
};

export type CheckShopifyLoginResponse = {
  __typename: "CheckShopifyLoginResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type SendRegisterUserEmailInput = {
  email: string,
  first_name: string,
  last_name: string,
  password: string,
};

export type SendRegisterUserEmailPrismaResponse = {
  __typename: "SendRegisterUserEmailPrismaResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  user?: UserPrisma | null,
};

export type CancelShopifySubscriptionInput = {
  businessId: string,
};

export type CancelShopifySubscriptionResponse = {
  __typename: "CancelShopifySubscriptionResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CancelShopifySubscriptionEarlyInput = {
  businessId: string,
};

export type CreateShopifyScriptTagResponse = {
  __typename: "CreateShopifyScriptTagResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GenerateBusinessAIReportInput = {
  business_id: string,
};

export type GenerateBusinessAIReportResponse = {
  __typename: "GenerateBusinessAIReportResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type SetUtmValuesSocialCampaignInput = {
  businessId: string,
  campaignId?: string | null,
  adsetId?: string | null,
  adId?: string | null,
  itemType: string,
  source: string,
};

export type SetUtmValuesSocialCampaignResponse = {
  __typename: "SetUtmValuesSocialCampaignResponse",
  error?: ErrorResponse | null,
  data?: SetUtmValuesFbCampaignResponseData | null,
  message?: string | null,
  nextToken?: string | null,
};

export type SetUtmValuesFbCampaignResponseData = {
  __typename: "SetUtmValuesFbCampaignResponseData",
  totalAdsToConnect?: number | null,
  adsConnected?: Array< string | null > | null,
  adsFailed?:  Array<SetUtmValuesFbCampaignErrorAd | null > | null,
};

export type SetUtmValuesFbCampaignErrorAd = {
  __typename: "SetUtmValuesFbCampaignErrorAd",
  ad?: string | null,
  error?: string | null,
};

export type AddDiscountCodeInput = {
  discount_code: string,
  business_id?: string | null,
};

export type AddDiscountCodeResponsePrisma = {
  __typename: "AddDiscountCodeResponsePrisma",
  data?: PromoCode | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type PromoCode = {
  __typename: "PromoCode",
  id?: string | null,
  code?: string | null,
  type?: string | null,
  status?: string | null,
  duration?: number | null,
  amount?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
};

export type RemoveDiscountCodeNewResponse = {
  __typename: "RemoveDiscountCodeNewResponse",
  data?: SubscriptionPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateAdLevelStatusInput = {
  id: string,
  businessId: string,
  source: string,
  typeOfAd: string,
  status: string,
  reminderStatus: boolean,
};

export type UpdateAdLevelStatusResponse = {
  __typename: "UpdateAdLevelStatusResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type updateAdDailyBudgetInput = {
  id: string,
  businessId: string,
  source: string,
  typeOfAd: string,
  amount: number,
  budget_type: string,
  reminderStatus: boolean,
};

export type UpdateAdDailyBudgetResponse = {
  __typename: "UpdateAdDailyBudgetResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CreateTrackableCopyInput = {
  name?: string | null,
  description?: string | null,
  type?: string | null,
  short_code?: string | null,
  destination_url?: string | null,
  url_position?: string | null,
  business_id?: string | null,
  shouldIgnoreValidation?: boolean | null,
  newTrackableCopyId?: string | null,
};

export type CreateTrackableCopyResponse = {
  __typename: "CreateTrackableCopyResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type DeleteTrackableCopyInput = {
  id?: string | null,
  short_code?: string | null,
  business_id?: string | null,
};

export type DeleteTrackableCopyResponse = {
  __typename: "DeleteTrackableCopyResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateTrackableCopyInput = {
  id?: string | null,
  business_id?: string | null,
  short_code?: string | null,
  name?: string | null,
  description?: string | null,
  destination_url?: string | null,
  url_position?: string | null,
};

export type UpdateTrackableCopyResponse = {
  __typename: "UpdateTrackableCopyResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateMonthlyBudgetInput = {
  business_id: string,
  amount: number,
};

export type UpdateMonthlyBudgetResponse = {
  __typename: "UpdateMonthlyBudgetResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateKeyMetricsRoasGoalsInput = {
  business_id: string,
  roas_goal?: RoasGoalInput | null,
};

export type RoasGoalInput = {
  facebook?: number | null,
  tiktok?: number | null,
  google?: number | null,
};

export type UpdateKeyMetricsRoasGoalsResponse = {
  __typename: "UpdateKeyMetricsRoasGoalsResponse",
  data?:  Array<BusinessRoasGoalPrisma | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UpdateAudienceSegmentQueryInput = {
  id: string,
  business_id: string,
  fields?: UpdateAudienceFields | null,
};

export type UpdateAudienceFields = {
  group_name?: string | null,
  dynamic_query_params?: string | null,
  audience?: string | null,
  query_type?: SegmentTypes | null,
  status?: string | null,
};

export enum SegmentTypes {
  static = "static",
  dynamic = "dynamic",
}


export type UpdateAudienceSegmentResponse = {
  __typename: "UpdateAudienceSegmentResponse",
  data?: AudienceSuccessResponse | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type AudienceSuccessResponse = {
  __typename: "AudienceSuccessResponse",
  success?: boolean | null,
  social_audience?: AudienceSocial | null,
  audience_errors?: Array< string | null > | null,
};

export type AudienceSocial = {
  __typename: "AudienceSocial",
  facebook?: AudienceData | null,
  google?: AudienceData | null,
};

export type AudienceData = {
  __typename: "AudienceData",
  error?: AudienceErrorMessage | null,
  user_list_id?: string | null,
  audience_id?: string | null,
};

export type AudienceErrorMessage = {
  __typename: "AudienceErrorMessage",
  message?: string | null,
};

export type DeleteAudienceSegmentInput = {
  id: string,
  business_id: string,
};

export type DeleteAudienceSegmentResponse = {
  __typename: "DeleteAudienceSegmentResponse",
  data?: AudienceSuccessResponse | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CreateSuggestedSegmentsInput = {
  business_id: string,
  query_type: SuggestedSegmentTypes,
};

export enum SuggestedSegmentTypes {
  repeat_shoppers = "repeat_shoppers",
  best_customers = "best_customers",
  best_source = "best_source",
  dormant = "dormant",
  purchase_last_30_days_ago = "purchase_last_30_days_ago",
  purchase_last_90_days_ago = "purchase_last_90_days_ago",
  abandoned_cart_last_30_days = "abandoned_cart_last_30_days",
  abandoned_cart_last_90_days = "abandoned_cart_last_90_days",
}


export type CreateSuggestedSegmentsResponse = {
  __typename: "CreateSuggestedSegmentsResponse",
  data?: Segments | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type Segments = {
  __typename: "Segments",
  id: string,
  business_id: string,
  no_of_customers?: number | null,
  added_revenue?: number | null,
  audience?: string | null,
  type: string,
  status: string,
  query_details?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  group_name: string,
  deleted_at?: string | null,
  dynamic_query_params: string,
};

export type CreateUserIntercomEmailTwoInput = {
  business_id?: string | null,
  email_two?: string | null,
};

export type CreateUserIntercomEmailTwoResponse = {
  __typename: "CreateUserIntercomEmailTwoResponse",
  message?: string | null,
  error?: ErrorResponse | null,
  nextToken?: string | null,
};

export type CreateUserStoreSessionsInput = {
  id: string,
  shop: string,
  state?: string | null,
  isOnline?: boolean | null,
  scope?: string | null,
  expires?: string | null,
  accessToken?: string | null,
};

export type CreateUserStoreSessionsResponse = {
  __typename: "CreateUserStoreSessionsResponse",
  data?: CreateUserStoreSessionsResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CreateUserStoreSessionsResponseData = {
  __typename: "CreateUserStoreSessionsResponseData",
  shop?: string | null,
  state?: string | null,
  isOnline?: boolean | null,
  scope?: string | null,
  expires?: string | null,
  accessToken?: string | null,
};

export type SetStoreBuildConfigInput = {
  business_id?: string | null,
  shop: string,
  email_address?: string | null,
  buildConfig: string,
};

export type SetStoreBuildConfigResponse = {
  __typename: "SetStoreBuildConfigResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CreateStoreInput = {
  platform_id?: string | null,
  shop_name?: string | null,
  admin_email?: string | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  store_url?: string | null,
  access_token?: string | null,
  timezone?: string | null,
  info?: string | null,
};

export type CreateStoreResponse = {
  __typename: "CreateStoreResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type DeleteStoreByShopNameInput = {
  shop: string,
};

export type DeleteStoreByShopNameResponse = {
  __typename: "DeleteStoreByShopNameResponse",
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type ExtendTrialInput = {
  business_id: string,
};

export type ExtendTrialResponse = {
  __typename: "ExtendTrialResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type AutoScalingSettingResponse = {
  __typename: "AutoScalingSettingResponse",
  error?: ErrorResponse | null,
  message?: string | null,
};

export type GetBusinessTechStackInput = {
  businessVanityName: string,
  business_id: string,
};

export type GetBusinessTechStackResponse = {
  __typename: "GetBusinessTechStackResponse",
  data?: TechStackResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type TechStackResponseData = {
  __typename: "TechStackResponseData",
  business_id?: string | null,
  build_config?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
};

export type GetBusinessBillingInfoInput = {
  business_id: string,
};

export type GetBusinessBillingInfoResponse = {
  __typename: "GetBusinessBillingInfoResponse",
  data?: BusinessBillingInfo | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type BusinessBillingInfo = {
  __typename: "BusinessBillingInfo",
  business_revenue?: number | null,
  business_orders_count?: number | null,
  plan_code?: string | null,
  plan_name?: string | null,
  plan_price?: string | null,
  next_billing_plan_code?: string | null,
  next_billing_plan_name?: string | null,
  next_billing_plan_price?: string | null,
  next_billing_date?: string | null,
  promo_code?: string | null,
  pricing_model?: string | null,
};

export type GetMiniShopDetailsResponse = {
  __typename: "GetMiniShopDetailsResponse",
  data?: MiniShopDetails | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetDefaultTrackableCopyResponse = {
  __typename: "GetDefaultTrackableCopyResponse",
  data?: DefaultTrackableCopyResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type DefaultTrackableCopyResponseData = {
  __typename: "DefaultTrackableCopyResponseData",
  default_trackable_copy?: TrackableCopy | null,
  has_manually_created_trackable_copy?: boolean | null,
};

export type GetAllAdminTasksInput = {
  query?: string | null,
  numberOfPage?: number | null,
  businessId?: string | null,
  type?: string | null,
};

export type GetAllAdminTasksResponse = {
  __typename: "GetAllAdminTasksResponse",
  data?:  Array<AdminTask | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type AdminTask = {
  __typename: "AdminTask",
  id: string,
  title: string,
  description: string,
  priority: number,
  objectives?: Array< string | null > | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
  views: number,
  completed: number,
  is_task_master: boolean,
  deeplink_target_url?: string | null,
  target_button_title?: string | null,
  task_image_url?: string | null,
  task_group_by_week?:  Array<TaskGroupByWeek > | null,
};

export type TaskGroupByWeek = {
  __typename: "TaskGroupByWeek",
  week_start: string,
  task_count: number,
};

export type GetUserTasksInput = {
  businessId: string,
  numberOfPage?: number | null,
  limit?: number | null,
  objective?: string | null,
};

export type GetUserTasksResponse = {
  __typename: "GetUserTasksResponse",
  data?:  Array<UserTask | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type UserTask = {
  __typename: "UserTask",
  id: string,
  task_x_business_id: string,
  title: string,
  description: string,
  priority: number,
  objectives?: Array< string | null > | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
  deeplink_target_url?: string | null,
  target_button_title?: string | null,
  task_image_url?: string | null,
  parameters?: string | null,
  type?: string | null,
};

export type GetHomepageMetricsInput = {
  business_id: string,
  dateStart: string,
  dateEnd: string,
  platform?: string | null,
};

export type GetHomepageMetricsResponse = {
  __typename: "GetHomepageMetricsResponse",
  data?: HomepageMetrics | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type HomepageMetrics = {
  __typename: "HomepageMetrics",
  total_orders?: number | null,
  average_order_value?: number | null,
  total_refunds?: number | null,
  sirge_total_conversion_value?: number | null,
  total_sales?: number | null,
  blended_roas?: number | null,
  total_amount_spent?: number | null,
  total_amount_spent_to_date?: number | null,
  total_conversion_value?: number | null,
  monthly_budget?: number | null,
  ads_generating_revenue_percentage?: number | null,
  total_facebook_orders?: number | null,
  total_google_orders?: number | null,
  total_tiktok_orders?: number | null,
  break_even_roas?: number | null,
  total_sales_percentage?: number | null,
  has_utm_not_connected_ad?: boolean | null,
};

export type GetSalesBreakdownInput = {
  business_id: string,
  dateStart: string,
  dateEnd: string,
};

export type GetSalesBreakdownResponse = {
  __typename: "GetSalesBreakdownResponse",
  data?: SalesBreakdown | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type SalesBreakdown = {
  __typename: "SalesBreakdown",
  total_sales?: SalesBreakdownDetail | null,
  total_revenue?: SalesBreakdownDetail | null,
  ads?: SalesBreakdownDetail | null,
  email?: SalesBreakdownDetail | null,
  social_media?: SalesBreakdownDetail | null,
  others?: SalesBreakdownDetail | null,
};

export type SalesBreakdownDetail = {
  __typename: "SalesBreakdownDetail",
  value?: number | null,
  order_count?: number | null,
  percentage?: number | null,
};

export type GenerateUploadUrlInput = {
  business_id: string,
  content_type: string,
  extension_type: string,
};

export type GenerateUploadUrlResponse = {
  __typename: "GenerateUploadUrlResponse",
  data?: UploadUrlResponse | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UploadUrlResponse = {
  __typename: "UploadUrlResponse",
  upload_url?: string | null,
  url?: string | null,
};

export type GetMemberCountByAdGroupIDsInputNew = {
  selected_campaign_ids?: Array< string | null > | null,
  selected_ad_set_ids?: Array< string | null > | null,
  business_id?: string | null,
};

export type GetMemberCountByAdGroupIDsResponse = {
  __typename: "GetMemberCountByAdGroupIDsResponse",
  data?: MemberCount | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type MemberCount = {
  __typename: "MemberCount",
  adSetCount?: number | null,
  adsCount?: number | null,
};

export type GetAllStaffAccountsInput = {
  business_id?: string | null,
};

export type GetAllStaffAccountsResponsePrisma = {
  __typename: "GetAllStaffAccountsResponsePrisma",
  data?:  Array<StaffPrisma | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type StaffPrisma = {
  __typename: "StaffPrisma",
  id: string,
  first_name: string,
  last_name: string,
  email: string,
  address1?: string | null,
  password?: string | null,
  timezone?: string | null,
  currency?: string | null,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
  businesses?:  Array<StaffBusinessPrisma > | null,
  profile_photo?: string | null,
  is_admin_user?: boolean | null,
};

export type StaffBusinessPrisma = {
  __typename: "StaffBusinessPrisma",
  user_id: string,
  business_id: string,
  user_type: string,
  is_default_business: boolean,
  created_at: string,
  updated_at: string,
  deleted_at?: string | null,
};

export type GetAdGroupBudgetDetailsInput = {
  business_id?: string | null,
  itemType?: string | null,
  id?: string | null,
  dateStart?: string | null,
  dateEnd?: string | null,
};

export type GetAdGroupBudgetDetailsResponse = {
  __typename: "GetAdGroupBudgetDetailsResponse",
  data?: AdGroupBudgetDetailsObject | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type AdGroupBudgetDetailsObject = {
  __typename: "AdGroupBudgetDetailsObject",
  adGroup?: AdGroupBudgetDetails | null,
  relatedItems?:  Array<Performance | null > | null,
};

export type AdGroupBudgetDetails = {
  __typename: "AdGroupBudgetDetails",
  daily_budget?: number | null,
  lifetime_budget?: number | null,
  name?: string | null,
  source_delivery_status?: string | null,
  source_secondary_status?: string | null,
  shared_budget_name?: string | null,
};

export type Performance = {
  __typename: "Performance",
  id?: string | null,
  source?: string | null,
  business_id?: string | null,
  purchases_count?: number | null,
  clicks_count?: number | null,
  daily_budget?: number | null,
  lifetime_budget?: number | null,
  budget?: number | null,
  shared_budget_name?: string | null,
  campaign_name?: string | null,
  campaign_budget?: AdGroupBudgetDetails | null,
  ad_set_budget?: AdGroupBudgetDetails | null,
  ad_images?: Array< string | null > | null,
  itemType?: string | null,
  ad_image?: string | null,
  ad_set?: string | null,
  ad_set_name?: string | null,
  ad?: string | null,
  ad_name?: string | null,
  sirge_ad_id?: string | null,
  sirge_adset_id?: string | null,
  sirge_campaign_id?: string | null,
  total_title?: string | null,
  source_delivery_status?: string | null,
  source_secondary_status?: string | null,
  clicks?: number | null,
  purchases?: number | null,
  purchases_source?: number | null,
  roas?: string | null,
  cost_per_purchase?: number | null,
  amount_spent?: number | null,
  // Deprecated. Moved to PerformanceDrawerStats
  average_cpm?: number | null,
  conversion_value?: number | null,
  total_conversion_value?: number | null,
  roas_ltv?: number | null,
  sirge_clicks?: number | null,
  sirge_purchases?: number | null,
  sirge_roas?: string | null,
  sirge_cost_per_purchase?: number | null,
  sirge_total_conversion_value?: number | null,
  created?: string | null,
  updated_at?: string | null,
  ad_type?: string | null,
  utm_status?: boolean | null,
  are_all_ads_connected?: boolean | null,
  transform?: boolean | null,
};

export type GetBusinessSourcesByIdInput = {
  business_id: string,
  date_from?: string | null,
  date_to?: string | null,
  sort?: SourcesSortObjectType | null,
  numberOfPage?: number | null,
};

export type SourcesSortObjectType = {
  sort?: string | null,
  field?: string | null,
};

export type GetBusinessSourcesByIdResponse = {
  __typename: "GetBusinessSourcesByIdResponse",
  data?: GetBusinessSourcesObject | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type GetBusinessSourcesObject = {
  __typename: "GetBusinessSourcesObject",
  sources?:  Array<SourcesBusiness | null > | null,
  totalInfo?: TotalInfo | null,
};

export type SourcesBusiness = {
  __typename: "SourcesBusiness",
  source?: string | null,
  unique_visitor?: number | null,
  clicks_count?: number | null,
  purchases_count?: number | null,
  url?: string | null,
  created?: string | null,
  referer?: string | null,
  business_id?: string | null,
};

export type TotalInfo = {
  __typename: "TotalInfo",
  total_clicks?: number | null,
  total_purchases?: number | null,
  totalrecords?: number | null,
  total_visitors?: number | null,
  total_pageviews?: number | null,
};

export type GetBusinessSourcesDetailsByIdInput = {
  business_id: string,
  date_from: string,
  date_to: string,
  source_name: string,
  sort?: SourcesSortObjectType | null,
  numberOfPage?: number | null,
};

export type GetBusinessSourcesDetailsByIdResponse = {
  __typename: "GetBusinessSourcesDetailsByIdResponse",
  data?:  Array<SourcesBusiness | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type GetBusinessGoogleTokenNewInput = {
  business_id: string,
  social_refresh_token: string,
};

export type GetBusinessGoogleTokenResponse = {
  __typename: "GetBusinessGoogleTokenResponse",
  data?: string | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type GetCurrentUserSessionsInput = {
  business_id: string,
  limit?: number | null,
};

export type GetCurrentUserSessionsResponse = {
  __typename: "GetCurrentUserSessionsResponse",
  data?:  Array<UserSession | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetAllPlatformBusinessesInput = {
  query?: string | null,
  numberOfPage?: number | null,
  recentBusiness?: boolean | null,
};

export type GetAllPlatformBusinessesResponse = {
  __typename: "GetAllPlatformBusinessesResponse",
  data?: GetAllPlatformBusinessesData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type GetAllPlatformBusinessesData = {
  __typename: "GetAllPlatformBusinessesData",
  businessList?:  Array<AllPlatformBusiness | null > | null,
};

export type AllPlatformBusiness = {
  __typename: "AllPlatformBusiness",
  id?: string | null,
  name?: string | null,
  logo?: string | null,
  manager_email?: string | null,
  manager_name?: string | null,
  vanity_name?: string | null,
  techStackTracked?: boolean | null,
  onboarding_call_booked?: boolean | null,
  onboarding_call_completed_at?: string | null,
};

export type FetchBusinessMetaDataResponse = {
  __typename: "FetchBusinessMetaDataResponse",
  data?: BusinessMetaData | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type BusinessMetaData = {
  __typename: "BusinessMetaData",
  metadata?: string | null,
};

export type SocialAdAccountsResponse = {
  __typename: "SocialAdAccountsResponse",
  data?:  Array<SocialAdAccount | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type SocialAdAccount = {
  __typename: "SocialAdAccount",
  id?: string | null,
  currency?: string | null,
  name?: string | null,
  timezone?: string | null,
};

export type GetStoreByShopNameInput = {
  shop: string,
};

export type GetStoreByShopNameResponse = {
  __typename: "GetStoreByShopNameResponse",
  data?: GetStoreByShopNameResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetStoreByShopNameResponseData = {
  __typename: "GetStoreByShopNameResponseData",
  id?: string | null,
  platform_id?: string | null,
  shop_name?: string | null,
  admin_email?: string | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  store_url?: string | null,
  access_token?: string | null,
  script_tag_id?: string | null,
  timezone?: string | null,
  currency?: string | null,
  script_installed?: boolean | null,
  script_uninstalled_date?: string | null,
  info?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
  business?:  Array<StoresBusiness | null > | null,
};

export type StoresBusiness = {
  __typename: "StoresBusiness",
  id?: string | null,
};

export type GetBusinessByShopInput = {
  shop: string,
};

export type GetBusinessByShopResponse = {
  __typename: "GetBusinessByShopResponse",
  data?: BusinessByShopData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type BusinessByShopData = {
  __typename: "BusinessByShopData",
  id?: string | null,
  store_id?: string | null,
  store_url?: string | null,
  shop_name?: string | null,
};

export type GetUserByEmailInput = {
  email: string,
};

export type GetUserByEmailResponse = {
  __typename: "GetUserByEmailResponse",
  data?: GetUserByEmailResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetUserByEmailResponseData = {
  __typename: "GetUserByEmailResponseData",
  id?: string | null,
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  address1?: string | null,
  address2?: string | null,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  timezone?: string | null,
  currency?: string | null,
  profile_photo?: string | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
  auto_scaling_setting?: number | null,
};

export type GetMiniShopByIdInput = {
  id: string,
};

export type GetMiniShopByIdResponse = {
  __typename: "GetMiniShopByIdResponse",
  data?: MiniShopPublic | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type MiniShopPublic = {
  __typename: "MiniShopPublic",
  id?: string | null,
  shop_name?: string | null,
  shop_image?: string | null,
  shop_url?: string | null,
  business_id?: string | null,
  top_products?: string | null,
  deleted_at?: string | null,
  updated_at?: string | null,
  created_at?: string | null,
};

export type CheckUserExistsByShopUrlInput = {
  myshopify_store_url: string,
};

export type CheckUserExistsByShopUrlResponse = {
  __typename: "CheckUserExistsByShopUrlResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetUserStoreSessionsInput = {
  session_id?: string | null,
};

export type GetUserStoreSessionsResponse = {
  __typename: "GetUserStoreSessionsResponse",
  data?: GetUserStoreSessionsResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetUserStoreSessionsResponseData = {
  __typename: "GetUserStoreSessionsResponseData",
  shop?: string | null,
  state?: string | null,
  isOnline?: boolean | null,
  scope?: string | null,
  expires?: string | null,
  accessToken?: string | null,
};

export type GetStoreDataForCallbackAuthInput = {
  myshopify_store_url: string,
};

export type GetStoreDataForCallbackAuthResponse = {
  __typename: "GetStoreDataForCallbackAuthResponse",
  data?: StoreDataForCallbackAuth | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type StoreDataForCallbackAuth = {
  __typename: "StoreDataForCallbackAuth",
  first_name?: string | null,
  last_name?: string | null,
  email?: string | null,
  is_installed?: boolean | null,
  business_id?: string | null,
  user_exists?: boolean | null,
};

export type GetStaffByIdInput = {
  staff_id: string,
  business_id: string,
};

export type GetUserResponsePrisma = {
  __typename: "GetUserResponsePrisma",
  data?: UserPrisma | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetUserInput = {
  post_hog_user_id?: string | null,
};

export type GetPerformanceDetailsInput = {
  business_id: string,
  itemType?: string | null,
  source?: string | null,
  selected_campaigns?: Array< string | null > | null,
  selected_ad_sets?: Array< string | null > | null,
  selected_ads?: Array< string | null > | null,
  sort?: PerformanceSortObjectType | null,
  filterCondition?: FilterConditionType | null,
  numberOfPage?: number | null,
  limitPerPage?: number | null,
  dateStart?: string | null,
  dateEnd?: string | null,
};

export type PerformanceSortObjectType = {
  sort?: boolean | null,
  field?: string | null,
};

export type FilterConditionType = {
  Condition?: Array< filterConditionArg | null > | null,
  filterStatus?: boolean | null,
  activeChecked?: boolean | null,
  roas?: string | null,
};

export type filterConditionArg = {
  column?: string | null,
  operator?: string | null,
  columnValue?: string | null,
  logicalOperator?: string | null,
};

export type GetPerformanceDetailsResponseNew = {
  __typename: "GetPerformanceDetailsResponseNew",
  data?: GetBusinessPerformanceDetailsNew | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type GetBusinessPerformanceDetailsNew = {
  __typename: "GetBusinessPerformanceDetailsNew",
  performance?:  Array<PerformanceNew | null > | null,
  summary?: PerformanceSummary | null,
  isRoasGoalsSet?: boolean | null,
};

export type PerformanceNew = {
  __typename: "PerformanceNew",
  id: string,
  source?: string | null,
  business_id: string,
  purchases_count?: number | null,
  clicks_count?: number | null,
  daily_budget?: number | null,
  lifetime_budget?: number | null,
  budget?: number | null,
  shared_budget_name?: string | null,
  campaign_name?: string | null,
  campaign_budget?: number | null,
  ad_set_budget?: number | null,
  ad_images?: Array< string | null > | null,
  itemType?: string | null,
  ad_image?: string | null,
  ad_set?: string | null,
  ad_set_name?: string | null,
  ad?: string | null,
  ad_name?: string | null,
  sirge_ad_id?: string | null,
  sirge_adset_id?: string | null,
  sirge_campaign_id?: string | null,
  total_title?: string | null,
  source_delivery_status?: string | null,
  source_secondary_status?: string | null,
  clicks?: number | null,
  purchases?: number | null,
  purchases_source?: number | null,
  roas?: string | null,
  cost_per_purchase?: number | null,
  amount_spent?: number | null,
  // Deprecated. Moved to PerformanceDrawerStats
  average_cpm?: number | null,
  conversion_value?: number | null,
  total_conversion_value?: number | null,
  prev_total_conversion_value?: number | null,
  roas_ltv?: number | null,
  sirge_clicks?: number | null,
  sirge_purchases?: number | null,
  sirge_roas?: string | null,
  sirge_cost_per_purchase?: number | null,
  sirge_total_conversion_value?: number | null,
  prev_sirge_total_conversion_value?: number | null,
  created?: string | null,
  updated_at?: string | null,
  ad_type?: string | null,
  utm_status?: boolean | null,
  are_all_ads_connected?: boolean | null,
  transform?: boolean | null,
};

export type PerformanceSummary = {
  __typename: "PerformanceSummary",
  amount_spent?: number | null,
  clicks?: number | null,
  purchases?: number | null,
  purchases_source?: number | null,
  cost_per_purchase?: number | null,
  total_conversion_value?: number | null,
  prev_total_conversion_value?: number | null,
  roas?: string | null,
  ft_clicks?: number | null,
  ft_purchases?: number | null,
  ft_cost_per_purchase?: number | null,
  ft_total_conversion_value?: number | null,
  prev_ft_total_conversion_value: number,
  ft_roas_ltv?: number | null,
  ft_roas?: string | null,
  ft_average_cpm?: number | null,
  number_of_records?: number | null,
};

export type GetBusinessConnectionsInput = {
  business_id?: string | null,
};

export type GetBusinessConnectionsResponse = {
  __typename: "GetBusinessConnectionsResponse",
  data?: BusinessConnection | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type BusinessConnection = {
  __typename: "BusinessConnection",
  facebook_ad_account_id?: string | null,
  facebook_ad_account_name?: string | null,
  script_installed?: boolean | null,
  shopify_store?: string | null,
  tik_tok_ad_account_id?: string | null,
  tik_tok_ad_account_name?: string | null,
};

export type GetPurchaseByBusinessInput = {
  business_id: string,
  date_from: string,
  date_to: string,
  typePurchases: string,
  source?: string | null,
  selected_ids: Array< string | null >,
  page_no?: number | null,
  per_page?: number | null,
};

export type GetPurchasesByBusinessResponseNew = {
  __typename: "GetPurchasesByBusinessResponseNew",
  data?:  Array<PurchaseByBusiness | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type PurchaseByBusiness = {
  __typename: "PurchaseByBusiness",
  customer_name?: string | null,
  customer_id?: string | null,
  created?: string | null,
  purchases_count?: number | null,
  conversion_value?: number | null,
};

export type GetUserDetailsInput = {
  vanity_name?: string | null,
  business_id?: string | null,
};

export type GetUserDetailsResponse = {
  __typename: "GetUserDetailsResponse",
  data?: UserDetails | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UserDetails = {
  __typename: "UserDetails",
  business?: BusinessPrisma | null,
  businesses?: BusinessesPrisma | null,
  status?: BusinessActiveStatus | null,
  user?: UserPrisma | null,
};

export type BusinessesPrisma = {
  __typename: "BusinessesPrisma",
  business_active_count?: number | null,
  business_count?: number | null,
  business_list?:  Array<UserXBusinessPrisma | null > | null,
};

export type BusinessActiveStatus = {
  __typename: "BusinessActiveStatus",
  active?: boolean | null,
};

export type GetUserFacebookAccountInput = {
  facebook_accessToken: string,
  business_id: string,
};

export type GetUserFacebookAccountResponse = {
  __typename: "GetUserFacebookAccountResponse",
  data?: FacebookAccount | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type FacebookAccount = {
  __typename: "FacebookAccount",
  id?: string | null,
  name?: string | null,
};

export type GetDiscountCodeStatusInput = {
  discount_code: string,
};

export type GetDiscountCodeStatusNewResponse = {
  __typename: "GetDiscountCodeStatusNewResponse",
  data?: PromoCode | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetKeyMetricsAnalyticsStatisticsInput = {
  business_id?: string | null,
  dateStart?: string | null,
  dateEnd?: string | null,
};

export type GetKeyMetricsStatisticsGraphResponse = {
  __typename: "GetKeyMetricsStatisticsGraphResponse",
  data?: StatisticsGraphData | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type StatisticsGraphData = {
  __typename: "StatisticsGraphData",
  orders?:  Array<StatisticGraphDetail | null > | null,
  amount_spent?:  Array<StatisticGraphDetail | null > | null,
  store_visits?:  Array<StatisticGraphDetail | null > | null,
  total_sales?:  Array<StatisticGraphDetail | null > | null,
  ad_clicks?:  Array<StatisticGraphDetail | null > | null,
};

export type StatisticGraphDetail = {
  __typename: "StatisticGraphDetail",
  date?: string | null,
  value?: number | null,
};

export type GetShopifyScopesStatusResponse = {
  __typename: "GetShopifyScopesStatusResponse",
  data?: boolean | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetAccountsCampaignsInput = {
  business_id: string,
  itemType: string,
  getfromRRSSApis?: boolean | null,
};

export type GetAccountsCampaignsResponse = {
  __typename: "GetAccountsCampaignsResponse",
  data?: GetBusinessAccountsCampaigns | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetBusinessAccountsCampaigns = {
  __typename: "GetBusinessAccountsCampaigns",
  facebook?:  Array<ItemSocialMediaIntegration | null > | null,
  tiktok?:  Array<ItemSocialMediaIntegration | null > | null,
  google?:  Array<ItemSocialMediaIntegration | null > | null,
};

export type ItemSocialMediaIntegration = {
  __typename: "ItemSocialMediaIntegration",
  id?: string | null,
  name?: string | null,
  status?: string | null,
  utm_status?: boolean | null,
  utm_injection_supported?: boolean | null,
};

export type GetPerformanceDrawerBasicDetailsInput = {
  id: string,
  ad_type: string,
  business_id: string,
};

export type PerformanceDrawerBasicDetailsResponse = {
  __typename: "PerformanceDrawerBasicDetailsResponse",
  data?: PerformanceDrawerBasicDetails | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type PerformanceDrawerBasicDetails = {
  __typename: "PerformanceDrawerBasicDetails",
  id: string,
  name?: string | null,
  campaign_name?: string | null,
  source?: string | null,
  source_delivery_status?: string | null,
  source_secondary_status?: string | null,
  no_of_ads?: number | null,
  no_of_adsets?: number | null,
  utm_status?: boolean | null,
  are_all_ads_connected?: boolean | null,
  daily_budget?: number | null,
  shared_budget_name?: string | null,
  lifetime_budget?: number | null,
  campaign_budget?: number | null,
  ad_set_budget?: number | null,
  ad_images?: Array< string | null > | null,
  ad_type?: string | null,
  created?: string | null,
};

export type GetPerformanceDrawerStatsInput = {
  id: string,
  ad_type: string,
  dateStart: string,
  dateEnd: string,
  dateStartGraph?: string | null,
  dateEndGraph?: string | null,
  business_id: string,
};

export type PerformanceDrawerStatsResponse = {
  __typename: "PerformanceDrawerStatsResponse",
  data?: PerformanceDrawerStats | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type PerformanceDrawerStats = {
  __typename: "PerformanceDrawerStats",
  total_amount_spent?: number | null,
  total_roas?: number | null,
  total_conversion?: number | null,
  total_purchases?: number | null,
  average_cpm?: number | null,
  total_clicks?: number | null,
  cost_per_click?: number | null,
  total_impressions?: number | null,
  prev_total_amount_spent?: number | null,
  prev_total_roas?: number | null,
  prev_total_cost_per_click?: number | null,
  prev_total_conversion?: number | null,
  graph?:  Array<PerformanceDrawerStatsGraph | null > | null,
};

export type PerformanceDrawerStatsGraph = {
  __typename: "PerformanceDrawerStatsGraph",
  total_amount_spent?: number | null,
  total_conversion_value?: number | null,
  created?: string | null,
};

export type GenerateTrackableCopyPathResponse = {
  __typename: "GenerateTrackableCopyPathResponse",
  data?: GeneratedPath | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GeneratedPath = {
  __typename: "GeneratedPath",
  path?: string | null,
};

export type GetTrackableCopiesInput = {
  business_id?: string | null,
  page?: number | null,
  date_from?: string | null,
  date_to?: string | null,
  sortField?: string | null,
  sortOrder?: string | null,
};

export type GetTrackableCopiesResponse = {
  __typename: "GetTrackableCopiesResponse",
  data?: GetTrackableCopyWithSummary | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type GetTrackableCopyWithSummary = {
  __typename: "GetTrackableCopyWithSummary",
  rows?:  Array<TrackableCopy > | null,
  summary?: TrackableCopySummary | null,
};

export type TrackableCopySummary = {
  __typename: "TrackableCopySummary",
  clicks?: number | null,
  purchases?: number | null,
  revenue?: number | null,
  total_row?: number | null,
};

export type GetTrackableCopyTypesResponse = {
  __typename: "GetTrackableCopyTypesResponse",
  data?: Array< string | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type GetTrackableCopyOrdersInput = {
  business_id?: string | null,
  source?: string | null,
  page?: number | null,
  date_from?: string | null,
  date_to?: string | null,
};

export type GetTrackableCopyOrdersResponse = {
  __typename: "GetTrackableCopyOrdersResponse",
  data?:  Array<TrackableCopyOrder | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type TrackableCopyOrder = {
  __typename: "TrackableCopyOrder",
  business_id?: string | null,
  conversion_value?: number | null,
  created?: string | null,
  order_id?: string | null,
  source?: string | null,
  customer_id?: string | null,
  customer_name?: string | null,
};

export type GetKeyMetricsMonthlyBudgetInput = {
  business_id: string,
};

export type GetKeyMetricsMonthlyBudgetResponse = {
  __typename: "GetKeyMetricsMonthlyBudgetResponse",
  data?:  Array<MonthlyBudgetResponse | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type MonthlyBudgetResponse = {
  __typename: "MonthlyBudgetResponse",
  amount_spent?: number | null,
  source?: string | null,
};

export type GetKeyMetricsRoasTrackerInput = {
  business_id: string,
  dateStart: string,
  dateEnd: string,
};

export type GetKeyMetricsRoasTrackerResponse = {
  __typename: "GetKeyMetricsRoasTrackerResponse",
  data?: RoasTrackerResponse | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type RoasTrackerResponse = {
  __typename: "RoasTrackerResponse",
  facebook?: RoasTrackerResponseDetails | null,
  tiktok?: RoasTrackerResponseDetails | null,
  google?: RoasTrackerResponseDetails | null,
};

export type RoasTrackerResponseDetails = {
  __typename: "RoasTrackerResponseDetails",
  over?: number | null,
  under?: number | null,
  total?: number | null,
};

export type GetBusinessAnalyticsInput = {
  business_id: string,
  dateStart?: string | null,
  dateEnd?: string | null,
  isAdComparison?: boolean | null,
  fetchOnlyActive?: boolean | null,
  limit?: number | null,
  numberPages?: number | null,
  level?: string | null,
  source?: string | null,
  searchText?: string | null,
  sort?: Array< AnalyticsSortObjectType | null > | null,
};

export type AnalyticsSortObjectType = {
  direction?: string | null,
  field?: string | null,
};

export type GetAdcomparisonDataResponse = {
  __typename: "GetAdcomparisonDataResponse",
  data?:  Array<AnalyticsPerformanceData | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type AnalyticsPerformanceData = {
  __typename: "AnalyticsPerformanceData",
  id?: string | null,
  ad_id?: string | null,
  ad_name?: string | null,
  ad_primary_status?: string | null,
  ad_secondary_status?: string | null,
  total_amount_spent?: number | null,
  roas?: number | null,
  total_orders?: number | null,
  total_conversion_value?: number | null,
  ad_images?: string | null,
  source?: string | null,
};

export type GetAllCustomersInput = {
  business_id: string,
  date_from: string,
  date_to: string,
  sort?: SourcesSortObjectType | null,
  filters?: Array< FilterObjectType | null > | null,
  numberOfPage?: number | null,
};

export type FilterObjectType = {
  field: string,
  operator: string,
  value?: string | null,
  logicalOperator?: string | null,
};

export type GetAllCustomersResponse = {
  __typename: "GetAllCustomersResponse",
  data?: GetAllCustomersObject | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type GetAllCustomersObject = {
  __typename: "GetAllCustomersObject",
  customers?:  Array<AllBusinessCustomers | null > | null,
  totalInfo?: TotalInfoCustomers | null,
};

export type AllBusinessCustomers = {
  __typename: "AllBusinessCustomers",
  last_visit?: string | null,
  first_visit?: string | null,
  total_purchases?: string | null,
  total_pageviews?: string | null,
  customer_id?: string | null,
  customer_name?: string | null,
};

export type TotalInfoCustomers = {
  __typename: "TotalInfoCustomers",
  total_purchases?: number | null,
  totalrecords?: number | null,
  total_visitors?: number | null,
  total_pageviews?: number | null,
  total_clicks?: number | null,
  total_customers?: number | null,
};

export type GetCustomerJourneyVisitorGraphInput = {
  business_id: string,
  category?: string | null,
  date_from?: string | null,
  date_to?: string | null,
};

export type GetCustomerJourneyVisitorGraphResponse = {
  __typename: "GetCustomerJourneyVisitorGraphResponse",
  data?:  Array<CustomerJourneyVisitorGraph | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CustomerJourneyVisitorGraph = {
  __typename: "CustomerJourneyVisitorGraph",
  date?: string | null,
  new_visitors?: string | null,
  returning_visitors?: string | null,
};

export type GetSegmentBuilderBasicDetailsInput = {
  business_id: string,
};

export type GetSegmentBuilderBasicDetailsResponse = {
  __typename: "GetSegmentBuilderBasicDetailsResponse",
  data?: GetSegmentBuilderBasicDetails | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetSegmentBuilderBasicDetails = {
  __typename: "GetSegmentBuilderBasicDetails",
  cities: Array< string >,
  states: Array< string >,
  countries: Array< string >,
  dynamicQueryRules?: string | null,
  predefinedQueries?: string | null,
};

export type GetAudienceSegmentInput = {
  id: string,
  business_id: string,
};

export type GetAudienceSegmentResponse = {
  __typename: "GetAudienceSegmentResponse",
  data?: Segments | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type GetCustomerJourneySourcesInput = {
  business_id: string,
  date_from?: string | null,
  date_to?: string | null,
};

export type GetCustomerJourneySourcesResponse = {
  __typename: "GetCustomerJourneySourcesResponse",
  data?: GetCustomerJourneySourcesObject | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type GetCustomerJourneySourcesObject = {
  __typename: "GetCustomerJourneySourcesObject",
  sources?:  Array<CustomerJourneySource | null > | null,
  totalInfo?: TotalInfoCustomers | null,
};

export type CustomerJourneySource = {
  __typename: "CustomerJourneySource",
  source?: string | null,
  unique_visitor?: number | null,
  unique_sessions?: number | null,
  purchases_count?: number | null,
};

export type GetCustomerDetailsSourcesInput = {
  business_id: string,
  customer_id: string,
  page: number,
};

export type GetCustomerDetailsSourcesResponse = {
  __typename: "GetCustomerDetailsSourcesResponse",
  data?:  Array<CustomerDetailsSource | null > | null,
  total_records?: number | null,
  numberPages?: number | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CustomerDetailsSource = {
  __typename: "CustomerDetailsSource",
  channel_name?: string | null,
  referer?: string | null,
  created_at?: string | null,
};

export type GetCustomerDetailsInput = {
  business_id: string,
  customer_id: string,
};

export type GetCustomerDetailsResponse = {
  __typename: "GetCustomerDetailsResponse",
  data?: CustomerDetails | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CustomerDetails = {
  __typename: "CustomerDetails",
  customer_email?: string | null,
  country?: string | null,
  state?: string | null,
  city?: string | null,
  total_pageviews?: number | null,
  first_visit?: string | null,
  total_purchases?: number | null,
  total_purchase_conversion_value?: number | null,
  customer_name?: string | null,
};

export type getCustomerDetailsPageViewsInput = {
  business_id: string,
  customer_id: string,
  page: number,
};

export type GetCustomerDetailsPageviewsResponse = {
  __typename: "GetCustomerDetailsPageviewsResponse",
  data?:  Array<CustomerDetailsPageView | null > | null,
  total_records?: number | null,
  numberPages?: number | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type CustomerDetailsPageView = {
  __typename: "CustomerDetailsPageView",
  ad?: string | null,
  ad_set?: string | null,
  campaign?: string | null,
  conversion_value?: number | null,
  created?: string | null,
  referer?: string | null,
  sirge_source_name?: string | null,
  url?: string | null,
};

export type GetKeyMetricsAnalyticsStatisticsResponse = {
  __typename: "GetKeyMetricsAnalyticsStatisticsResponse",
  data?: KeyMetricsAnalyticsStatistics | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type KeyMetricsAnalyticsStatistics = {
  __typename: "KeyMetricsAnalyticsStatistics",
  total_order?: KeyMetricsStatistics | null,
  total_sales?: KeyMetricsStatistics | null,
  average_order_value?: KeyMetricsStatistics | null,
  blended_roas?: BlendedRoasStatistics | null,
  visits?: KeyMetricsStatistics | null,
  conversion_rate?: KeyMetricsStatistics | null,
  total_conversion_value?: KeyMetricsStatistics | null,
  total_refunds?: KeyMetricsStatistics | null,
};

export type KeyMetricsStatistics = {
  __typename: "KeyMetricsStatistics",
  amount?: number | null,
  percentage?: number | null,
  trend?:  Array<KeyMetricsStatisticsTrend | null > | null,
};

export type KeyMetricsStatisticsTrend = {
  __typename: "KeyMetricsStatisticsTrend",
  date?: string | null,
  value?: number | null,
};

export type BlendedRoasStatistics = {
  __typename: "BlendedRoasStatistics",
  amount?: number | null,
  percentage?: number | null,
  trend?:  Array<KeyMetricsStatisticsTrend | null > | null,
  sources?: RoasBySources | null,
};

export type RoasBySources = {
  __typename: "RoasBySources",
  facebook?: number | null,
  tiktok?: number | null,
  google?: number | null,
};

export type GetCustomerChannelsInput = {
  business_id: string,
  date_from?: string | null,
  date_to?: string | null,
  sort?: SourcesSortObjectType | null,
  numberOfPage?: number | null,
};

export type GetCustomerChannelsResponse = {
  __typename: "GetCustomerChannelsResponse",
  data?: GetCustomerChannelObject | null,
  error?: ErrorResponse | null,
  message?: string | null,
  numberPages?: number | null,
};

export type GetCustomerChannelObject = {
  __typename: "GetCustomerChannelObject",
  channels?:  Array<CustomerChannel | null > | null,
  totalInfo?: TotalInfo | null,
};

export type CustomerChannel = {
  __typename: "CustomerChannel",
  source?: string | null,
  source_id?: string | null,
  referer?: string | null,
  unique_visitor?: number | null,
  unique_sessions?: number | null,
  purchases_count?: number | null,
};

export type GetCustomerChannelsDetailsInput = {
  business_id: string,
  date_from: string,
  date_to: string,
  channel_id: string,
  sort?: SourcesSortObjectType | null,
};

export type GetCustomerChannelsDetailsResponse = {
  __typename: "GetCustomerChannelsDetailsResponse",
  data?: GetCustomerChannelsDetailsResponseData | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type GetCustomerChannelsDetailsResponseData = {
  __typename: "GetCustomerChannelsDetailsResponseData",
  channels?:  Array<CustomerChannel | null > | null,
};

export type GetSourcesOrdersInput = {
  business_id?: string | null,
  source?: string | null,
  page?: number | null,
  date_from?: string | null,
  date_to?: string | null,
};

export type GetSourcesOrdersResponse = {
  __typename: "GetSourcesOrdersResponse",
  data?:  Array<SourceOrder | null > | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
  numberPages?: number | null,
};

export type SourceOrder = {
  __typename: "SourceOrder",
  business_id?: string | null,
  conversion_value?: number | null,
  created?: string | null,
  order_id?: string | null,
  source?: string | null,
  customer_id?: string | null,
  customer_name?: string | null,
};

export type GetSuggestedSegmentsStatsInput = {
  business_id?: string | null,
};

export type GetSuggestedSegmentsStatsResponse = {
  __typename: "GetSuggestedSegmentsStatsResponse",
  data?:  Array<GetSuggestedSegmentsStatsResult | null > | null,
  total_records?: number | null,
  message?: string | null,
  error?: ErrorResponse | null,
  nextToken?: string | null,
};

export type GetSuggestedSegmentsStatsResult = {
  __typename: "GetSuggestedSegmentsStatsResult",
  no_of_customers?: number | null,
  query_type?: SuggestedSegmentTypes | null,
};

export type GetCustomerOrdersInput = {
  business_id: string,
  customer_id: string,
  page?: number | null,
  limit?: number | null,
};

export type GetCustomerOrdersResponse = {
  __typename: "GetCustomerOrdersResponse",
  data?:  Array<CustomerOrders | null > | null,
  total_records?: number | null,
  message?: string | null,
  error?: ErrorResponse | null,
  nextToken?: string | null,
};

export type CustomerOrders = {
  __typename: "CustomerOrders",
  id: string,
  customer_id?: string | null,
  customer_name?: string | null,
  platform_order_id: string,
  city?: string | null,
  state?: string | null,
  zip?: string | null,
  country?: string | null,
  currency?: string | null,
  conversion_value?: number | null,
  insight_date?: string | null,
  visitor_id?: string | null,
  deleted_at?: string | null,
  updated_at?: string | null,
  created_at?: string | null,
};

export type GetAllStaticAudienceSegmentQueryInput = {
  business_id: string,
};

export type GetAllStaticAudienceSegmentResponse = {
  __typename: "GetAllStaticAudienceSegmentResponse",
  data?:  Array<StaticSegments | null > | null,
  total_records?: number | null,
  message?: string | null,
  error?: ErrorResponse | null,
  nextToken?: string | null,
};

export type StaticSegments = {
  __typename: "StaticSegments",
  id: string,
  business_id: string,
  group_name: string,
};

export type GetAllAudienceSegmentQueryInput = {
  sort?: AudienceSortObjectType | null,
  filter?: AudienceFilterObjectType | null,
  page?: number | null,
  limit?: number | null,
  business_id: string,
};

export type AudienceSortObjectType = {
  column?: string | null,
  order?: string | null,
};

export type AudienceFilterObjectType = {
  startDate?: string | null,
  endDate?: string | null,
};

export type GetAllAudienceSegmentSourcesResponse = {
  __typename: "GetAllAudienceSegmentSourcesResponse",
  data?:  Array<Segments | null > | null,
  total_records?: number | null,
  message?: string | null,
  error?: ErrorResponse | null,
  nextToken?: string | null,
};

export type GetBusinessAIReportInput = {
  business_id: string,
};

export type GetBusinessAIReportResponse = {
  __typename: "GetBusinessAIReportResponse",
  data?: string | null,
  error?: ErrorResponse | null,
  message?: string | null,
};

export type UserTaskProgressInput = {
  business_id: string,
  objective?: string | null,
};

export type UserTaskProgressResponse = {
  __typename: "UserTaskProgressResponse",
  data?:  Array<UserTaskProgress | null > | null,
};

export type UserTaskProgress = {
  __typename: "UserTaskProgress",
  task_count?: number | null,
  status?: string | null,
  objective?: string | null,
};

export type GetSubscriptionNewInput = {
  business_id?: string | null,
};

export type GetSubscriptionNewResponse = {
  __typename: "GetSubscriptionNewResponse",
  data?: SubscriptionsTableSchema | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type SubscriptionsTableSchema = {
  __typename: "SubscriptionsTableSchema",
  id?: string | null,
  business_id?: string | null,
  subscription_plan_code?: string | null,
  status?: string | null,
  processor?: string | null,
  store_subscription_body?: string | null,
  store_subscription_id?: string | null,
  promo_code_id?: string | null,
  promo_code_applied_at?: string | null,
  trial_start?: string | null,
  trial_end?: string | null,
  trial_left?: number | null,
  trial_extended?: boolean | null,
  current_billing_period_start?: string | null,
  current_billing_period_end?: string | null,
  subscription_end_date?: string | null,
  business_limit?: number | null,
  staff_limit?: number | null,
  created_at?: string | null,
  updated_at?: string | null,
  deleted_at?: string | null,
};

export type GetUserTaskCounterInput = {
  business_id: string,
};

export type GetUserTaskCounterResponse = {
  __typename: "GetUserTaskCounterResponse",
  data?: UserTaskCounter | null,
  error?: ErrorResponse | null,
  message?: string | null,
  nextToken?: string | null,
};

export type UserTaskCounter = {
  __typename: "UserTaskCounter",
  completed_tasks?: number | null,
  total_tasks?: number | null,
};

export type ActivateBusinessNewMutationVariables = {
  activateBusinessInput: ActivateBusinessInput,
};

export type ActivateBusinessNewMutation = {
  activateBusinessNew?:  {
    __typename: "ActivateBusinessResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SetBusinessGoalMutationVariables = {
  setBusinessGoalInput: SetBusinessGoalInput,
};

export type SetBusinessGoalMutation = {
  setBusinessGoal?:  {
    __typename: "ApplyPromoCodeResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateMiniShopMutationVariables = {
  updateMiniShopInput: GetMiniShopDetailsInput,
};

export type UpdateMiniShopMutation = {
  updateMiniShop?:  {
    __typename: "UpdateMiniShopResponse",
    data?:  {
      __typename: "MiniShopDetails",
      topProducts?:  Array< {
        __typename: "PerformingProductsData",
        product_id?: string | null,
        name?: string | null,
        total_revenue?: number | null,
        product_image?: string | null,
        handle?: string | null,
      } | null > | null,
      miniShop?:  {
        __typename: "MiniShop",
        id?: string | null,
        shop_name?: string | null,
        shop_image?: string | null,
        business_id?: string | null,
        top_products?: string | null,
        deleted_at?: string | null,
        updated_at?: string | null,
        created_at?: string | null,
      } | null,
      trackableLink?:  {
        __typename: "TrackableCopy",
        id: string,
        name: string,
        description?: string | null,
        type?: string | null,
        short_code: string,
        destination_url: string,
        url_position?: string | null,
        business_id?: string | null,
        created?: string | null,
        stats?:  {
          __typename: "TrackableCopyStats",
          clicks?: number | null,
          purchases?: number | null,
          revenue?: number | null,
          prev_clicks?: number | null,
        } | null,
        is_onboarding_default?: boolean | null,
        is_mini_shop?: boolean | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type ChangePasswordNewMutationVariables = {
  changePasswordInput: ChangePasswordInput,
};

export type ChangePasswordNewMutation = {
  changePasswordNew?:  {
    __typename: "ChangePasswordResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type CreateTaskMutationVariables = {
  createTaskInput: CreateTaskInput,
};

export type CreateTaskMutation = {
  createTask?:  {
    __typename: "TaskResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateTaskMutationVariables = {
  updateTaskInput: UpdateTaskInput,
};

export type UpdateTaskMutation = {
  updateTask?:  {
    __typename: "TaskResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateBusinessOnboardingDataMutationVariables = {
  updateBusinessOnboardingDataInput: UpdateBusinessOnboardingDataInput,
};

export type UpdateBusinessOnboardingDataMutation = {
  updateBusinessOnboardingData?:  {
    __typename: "UpdateBusinessOnboardingResponse",
    success?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpsertStoreNewMutationVariables = {
  upsertStoreInput: UpsertStoreInput,
};

export type UpsertStoreNewMutation = {
  upsertStoreNew?:  {
    __typename: "UpsertStoreResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateTaskStatusMutationVariables = {
  updateTaskStatusInput: UpdateTaskStatusInput,
};

export type UpdateTaskStatusMutation = {
  updateTaskStatus?:  {
    __typename: "TaskResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type DeleteTaskMutationVariables = {
  deleteTaskInput: DeleteTaskInput,
};

export type DeleteTaskMutation = {
  deleteTask?:  {
    __typename: "TaskResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateStaffAccountNewMutationVariables = {
  createStaffAccountInput: CreateStaffAccountInput,
};

export type CreateStaffAccountNewMutation = {
  createStaffAccountNew?:  {
    __typename: "CreateStaffAccountResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdatePinnedInspirationsMutationVariables = {
  updatePinnedInspirationsInput: UpdatePinnedInspirationsInput,
};

export type UpdatePinnedInspirationsMutation = {
  updatePinnedInspirations?:  {
    __typename: "UpdatePinnedInspirationsResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type DeleteStaffAccountNewMutationVariables = {
  deleteStaffAccountInput: DeleteStaffAccountInput,
};

export type DeleteStaffAccountNewMutation = {
  deleteStaffAccountNew?:  {
    __typename: "DeleteStaffAccountResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type PasswordResetLinkNewMutationVariables = {
  passwordResetInput: PasswordResetInput,
};

export type PasswordResetLinkNewMutation = {
  passwordResetLinkNew?:  {
    __typename: "PasswordResetResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpdateUserDefaultBusinessNewMutationVariables = {
  updateUserDefaultBusinessNewInput: BusinessIdInput,
};

export type UpdateUserDefaultBusinessNewMutation = {
  updateUserDefaultBusinessNew?:  {
    __typename: "GeneralApiResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SendUnlockFullPotentialEmailMutationVariables = {
  businessIdInput: BusinessIdInput,
};

export type SendUnlockFullPotentialEmailMutation = {
  sendUnlockFullPotentialEmail?:  {
    __typename: "GeneralApiResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type RemoveSocialAdAccountMutationVariables = {
  removeSocialAdAccountInput: BusinessIdWithPlatform,
};

export type RemoveSocialAdAccountMutation = {
  removeSocialAdAccount?:  {
    __typename: "RemoveSocialAdAccountResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type RemoveSocialUserAccessMutationVariables = {
  businessIdInput: BusinessIdWithPlatform,
};

export type RemoveSocialUserAccessMutation = {
  removeSocialUserAccess?:  {
    __typename: "RemoveSocialAccessResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SetSocialUserAccessMutationVariables = {
  socialAccessStoreInput: SetSocialAccessInput,
};

export type SetSocialUserAccessMutation = {
  setSocialUserAccess?:  {
    __typename: "SetSocialAccessResponse",
    data?:  {
      __typename: "AdAccountSettingsPrisma",
      id: string,
      business_id: string,
      ad_platform_id: string,
      premium_page_views: number,
      external_platform?: string | null,
      active_campaign_count: number,
      paused_campaign_count: number,
      social_account_id?: string | null,
      social_account_name?: string | null,
      social_account_currency?: string | null,
      social_account_timezone?: string | null,
      access_token?: string | null,
      utm_count: number,
      social_integration?: string | null,
      conversion_api_enabled?: string | null,
      pixel_id?: string | null,
      social_refresh_token?: string | null,
      updateKey?: string | null,
      last_data_refreshed?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      ad_platform:  {
        __typename: "AdPlatformPrisma",
        id: string,
        name: string,
        internal_source_name: string,
        dark_theme_image_url?: string | null,
        light_theme_image_url: string,
        status: string,
      },
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SetSocialAdAccountMutationVariables = {
  setSocialAdAccountInput: SetSocialAdAccountInput,
};

export type SetSocialAdAccountMutation = {
  setSocialAdAccount?:  {
    __typename: "SetSocialAdAccountResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type TriggerBusinessDataLongFetchNewMutationVariables = {
  triggerBusinessDataLongFetchInput?: BusinessIdInput | null,
};

export type TriggerBusinessDataLongFetchNewMutation = {
  triggerBusinessDataLongFetchNew?:  {
    __typename: "TriggerBusinessDataLongFetchResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type TriggerBusinessDayZeroDataCheckMutationVariables = {
  triggerBusinessDayZeroDataCheckInput?: TriggerBusinessDayZeroDataCheckInput | null,
};

export type TriggerBusinessDayZeroDataCheckMutation = {
  triggerBusinessDayZeroDataCheck?:  {
    __typename: "TriggerBusinessDayZeroDataCheckResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpdateBusinessByBusinessIdNewMutationVariables = {
  setBusinessesInput: SetBusinessesInput,
};

export type UpdateBusinessByBusinessIdNewMutation = {
  updateBusinessByBusinessIdNew?:  {
    __typename: "SetBusinessesPrismaInputResponse",
    data?:  {
      __typename: "BusinessPrisma",
      id: string,
      store_id: string,
      name: string,
      status: string,
      logo?: string | null,
      vanity_name: string,
      reminder_status?: boolean | null,
      show_budget_confirmation?: boolean | null,
      monthly_budget: number,
      business_goal?: string | null,
      campaign_roas_goal?: string | null,
      adset_roas_goal?: string | null,
      ad_roas_goal?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      ad_account_settings:  Array< {
        __typename: "AdAccountSettingsPrisma",
        id: string,
        business_id: string,
        ad_platform_id: string,
        premium_page_views: number,
        external_platform?: string | null,
        active_campaign_count: number,
        paused_campaign_count: number,
        social_account_id?: string | null,
        social_account_name?: string | null,
        social_account_currency?: string | null,
        social_account_timezone?: string | null,
        access_token?: string | null,
        utm_count: number,
        social_integration?: string | null,
        conversion_api_enabled?: string | null,
        pixel_id?: string | null,
        social_refresh_token?: string | null,
        updateKey?: string | null,
        last_data_refreshed?: string | null,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
        ad_platform:  {
          __typename: "AdPlatformPrisma",
          id: string,
          name: string,
          internal_source_name: string,
          dark_theme_image_url?: string | null,
          light_theme_image_url: string,
          status: string,
        },
      } >,
      subscriptions:  Array< {
        __typename: "SubscriptionPrisma",
        id: string,
        business_id: string,
        subscription_plan_code?: string | null,
        status: string,
        processor?: string | null,
        store_subscription_body?: string | null,
        store_subscription_id?: string | null,
        promo_code_id?: string | null,
        trial_start?: string | null,
        trial_end?: string | null,
        trial_left?: number | null,
        current_billing_period_start?: string | null,
        current_billing_period_end?: string | null,
        subscription_end_date?: string | null,
        business_limit?: number | null,
        staff_limit?: number | null,
        subscription_charges?:  Array< {
          __typename: "SubscriptionChargesPrisma",
          id: string,
          business_id: string,
          subscription_id?: string | null,
          store_revenue: number,
          amount_billed: number,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
        } | null > | null,
        promo_codes?:  {
          __typename: "PromoCodePrisma",
          id?: string | null,
          code?: string | null,
          type?: string | null,
          status?: string | null,
          duration?: number | null,
          amount?: number | null,
          created_at?: string | null,
          updated_at?: string | null,
          deleted_at?: string | null,
        } | null,
        current_revenue?: number | null,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } >,
      store?:  {
        __typename: "StorePrisma",
        id?: string | null,
        shop_name?: string | null,
        address1?: string | null,
        address2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        country?: string | null,
        store_url?: string | null,
        timezone?: string | null,
        currency?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
      } | null,
      completed_onboarding_call?: boolean | null,
      has_visitor_session?: boolean | null,
      business_roas_goals?:  Array< {
        __typename: "BusinessRoasGoalPrisma",
        id: string,
        roas_goal: number,
        ad_platform_id: string,
        ad_platforms:  {
          __typename: "AdPlatformPrisma",
          id: string,
          name: string,
          internal_source_name: string,
          dark_theme_image_url?: string | null,
          light_theme_image_url: string,
          status: string,
        },
      } | null > | null,
      tour_completed_at?: string | null,
      tour_skipped_at?: string | null,
      feature_flags?: Array< string | null > | null,
      onboarding_call_completed_at?: string | null,
      pinned_inspirations?: Array< string | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateBusinessLogoNewMutationVariables = {
  updateBusinessLogoInput: UpdateBusinessLogoInput,
};

export type UpdateBusinessLogoNewMutation = {
  updateBusinessLogoNew?:  {
    __typename: "UpdateBusinessLogoNewResponse",
    data?:  {
      __typename: "BusinessPrisma",
      id: string,
      store_id: string,
      name: string,
      status: string,
      logo?: string | null,
      vanity_name: string,
      reminder_status?: boolean | null,
      show_budget_confirmation?: boolean | null,
      monthly_budget: number,
      business_goal?: string | null,
      campaign_roas_goal?: string | null,
      adset_roas_goal?: string | null,
      ad_roas_goal?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      ad_account_settings:  Array< {
        __typename: "AdAccountSettingsPrisma",
        id: string,
        business_id: string,
        ad_platform_id: string,
        premium_page_views: number,
        external_platform?: string | null,
        active_campaign_count: number,
        paused_campaign_count: number,
        social_account_id?: string | null,
        social_account_name?: string | null,
        social_account_currency?: string | null,
        social_account_timezone?: string | null,
        access_token?: string | null,
        utm_count: number,
        social_integration?: string | null,
        conversion_api_enabled?: string | null,
        pixel_id?: string | null,
        social_refresh_token?: string | null,
        updateKey?: string | null,
        last_data_refreshed?: string | null,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
        ad_platform:  {
          __typename: "AdPlatformPrisma",
          id: string,
          name: string,
          internal_source_name: string,
          dark_theme_image_url?: string | null,
          light_theme_image_url: string,
          status: string,
        },
      } >,
      subscriptions:  Array< {
        __typename: "SubscriptionPrisma",
        id: string,
        business_id: string,
        subscription_plan_code?: string | null,
        status: string,
        processor?: string | null,
        store_subscription_body?: string | null,
        store_subscription_id?: string | null,
        promo_code_id?: string | null,
        trial_start?: string | null,
        trial_end?: string | null,
        trial_left?: number | null,
        current_billing_period_start?: string | null,
        current_billing_period_end?: string | null,
        subscription_end_date?: string | null,
        business_limit?: number | null,
        staff_limit?: number | null,
        subscription_charges?:  Array< {
          __typename: "SubscriptionChargesPrisma",
          id: string,
          business_id: string,
          subscription_id?: string | null,
          store_revenue: number,
          amount_billed: number,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
        } | null > | null,
        promo_codes?:  {
          __typename: "PromoCodePrisma",
          id?: string | null,
          code?: string | null,
          type?: string | null,
          status?: string | null,
          duration?: number | null,
          amount?: number | null,
          created_at?: string | null,
          updated_at?: string | null,
          deleted_at?: string | null,
        } | null,
        current_revenue?: number | null,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } >,
      store?:  {
        __typename: "StorePrisma",
        id?: string | null,
        shop_name?: string | null,
        address1?: string | null,
        address2?: string | null,
        city?: string | null,
        state?: string | null,
        zip?: string | null,
        country?: string | null,
        store_url?: string | null,
        timezone?: string | null,
        currency?: string | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
      } | null,
      completed_onboarding_call?: boolean | null,
      has_visitor_session?: boolean | null,
      business_roas_goals?:  Array< {
        __typename: "BusinessRoasGoalPrisma",
        id: string,
        roas_goal: number,
        ad_platform_id: string,
        ad_platforms:  {
          __typename: "AdPlatformPrisma",
          id: string,
          name: string,
          internal_source_name: string,
          dark_theme_image_url?: string | null,
          light_theme_image_url: string,
          status: string,
        },
      } | null > | null,
      tour_completed_at?: string | null,
      tour_skipped_at?: string | null,
      feature_flags?: Array< string | null > | null,
      onboarding_call_completed_at?: string | null,
      pinned_inspirations?: Array< string | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateBusinessMetaDataMutationVariables = {
  updateBusinessMetaDataInput: UpdateBusinessMetaDataInput,
};

export type UpdateBusinessMetaDataMutation = {
  updateBusinessMetaData?:  {
    __typename: "UpdateBusinessMetaDataResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateFacebookConnectionSettingsMutationVariables = {
  updateFacebookConnectionSettingsInput: UpdateFacebookConnectionSettingsInput,
};

export type UpdateFacebookConnectionSettingsMutation = {
  updateFacebookConnectionSettings?:  {
    __typename: "UpdateFacebookConnectionSettingsResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpdateStaffAccountAccessNewMutationVariables = {
  updateStaffAccountAccessInput: UpdateStaffAccountAccessNewInput,
};

export type UpdateStaffAccountAccessNewMutation = {
  updateStaffAccountAccessNew?:  {
    __typename: "UpdateStaffAccountAccessResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateTimezoneCurrencyNewMutationVariables = {
  updateTimezoneCurrencyInput: UpdateTimezoneCurrencyInput,
};

export type UpdateTimezoneCurrencyNewMutation = {
  updateTimezoneCurrencyNew?:  {
    __typename: "UpdateTimezoneCurrencyResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type UpdateUserNewMutationVariables = {
  updateUserNewInput: UpdateUserNewInput,
};

export type UpdateUserNewMutation = {
  updateUserNew?:  {
    __typename: "UpdateUserNewResponse",
    data?:  {
      __typename: "UserPrisma",
      id: string,
      first_name: string,
      last_name: string,
      email: string,
      address1?: string | null,
      password?: string | null,
      timezone?: string | null,
      currency?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      businesses?:  Array< {
        __typename: "UserXBusinessPrisma",
        user_id: string,
        business_id: string,
        business:  {
          __typename: "BusinessPrisma",
          id: string,
          store_id: string,
          name: string,
          status: string,
          logo?: string | null,
          vanity_name: string,
          reminder_status?: boolean | null,
          show_budget_confirmation?: boolean | null,
          monthly_budget: number,
          business_goal?: string | null,
          campaign_roas_goal?: string | null,
          adset_roas_goal?: string | null,
          ad_roas_goal?: string | null,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
          ad_account_settings:  Array< {
            __typename: "AdAccountSettingsPrisma",
            id: string,
            business_id: string,
            ad_platform_id: string,
            premium_page_views: number,
            external_platform?: string | null,
            active_campaign_count: number,
            paused_campaign_count: number,
            social_account_id?: string | null,
            social_account_name?: string | null,
            social_account_currency?: string | null,
            social_account_timezone?: string | null,
            access_token?: string | null,
            utm_count: number,
            social_integration?: string | null,
            conversion_api_enabled?: string | null,
            pixel_id?: string | null,
            social_refresh_token?: string | null,
            updateKey?: string | null,
            last_data_refreshed?: string | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
            ad_platform:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } >,
          subscriptions:  Array< {
            __typename: "SubscriptionPrisma",
            id: string,
            business_id: string,
            subscription_plan_code?: string | null,
            status: string,
            processor?: string | null,
            store_subscription_body?: string | null,
            store_subscription_id?: string | null,
            promo_code_id?: string | null,
            trial_start?: string | null,
            trial_end?: string | null,
            trial_left?: number | null,
            current_billing_period_start?: string | null,
            current_billing_period_end?: string | null,
            subscription_end_date?: string | null,
            business_limit?: number | null,
            staff_limit?: number | null,
            subscription_charges?:  Array< {
              __typename: "SubscriptionChargesPrisma",
              id: string,
              business_id: string,
              subscription_id?: string | null,
              store_revenue: number,
              amount_billed: number,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
            } | null > | null,
            promo_codes?:  {
              __typename: "PromoCodePrisma",
              id?: string | null,
              code?: string | null,
              type?: string | null,
              status?: string | null,
              duration?: number | null,
              amount?: number | null,
              created_at?: string | null,
              updated_at?: string | null,
              deleted_at?: string | null,
            } | null,
            current_revenue?: number | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
          } >,
          store?:  {
            __typename: "StorePrisma",
            id?: string | null,
            shop_name?: string | null,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            state?: string | null,
            zip?: string | null,
            country?: string | null,
            store_url?: string | null,
            timezone?: string | null,
            currency?: string | null,
            created_at?: string | null,
            updated_at?: string | null,
            deleted_at?: string | null,
          } | null,
          completed_onboarding_call?: boolean | null,
          has_visitor_session?: boolean | null,
          business_roas_goals?:  Array< {
            __typename: "BusinessRoasGoalPrisma",
            id: string,
            roas_goal: number,
            ad_platform_id: string,
            ad_platforms:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } | null > | null,
          tour_completed_at?: string | null,
          tour_skipped_at?: string | null,
          feature_flags?: Array< string | null > | null,
          onboarding_call_completed_at?: string | null,
          pinned_inspirations?: Array< string | null > | null,
        },
        user_type: string,
        is_default_business: boolean,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } > | null,
      profile_photo?: string | null,
      is_admin_user?: boolean | null,
      link_tracking_visit_count?: number | null,
      link_tracking_view_status?: string | null,
      growth_hub_clicked_at?: string | null,
      inspirations_tutorial_finished_at?: string | null,
      mini_shop_tutorial_finished_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateUserProfilePictureNewMutationVariables = {
  updateUserProfilePictureInput: UpdateUserProfilePictureInput,
};

export type UpdateUserProfilePictureNewMutation = {
  updateUserProfilePictureNew?:  {
    __typename: "UpdateUserProfilePictureResponse",
    data?:  {
      __typename: "User",
      default_business_id?: string | null,
      account_state?: string | null,
      affiliate_auth_token?: string | null,
      auto_scaling_setting?: number | null,
      balance?: number | null,
      cancellation_reason?: string | null,
      card_expiry_date?: string | null,
      card_last_four_digits?: string | null,
      card_type?: string | null,
      city?: string | null,
      client_billing_account_id?: string | null,
      country_code?: string | null,
      country_name?: string | null,
      country_phone_prefix?: string | null,
      created_at?: string | null,
      currency?: string | null,
      current_billing_period_end?: number | null,
      current_billing_period_start?: number | null,
      data_deleting_on?: number | null,
      data_retention_period?: number | null,
      email?: string | null,
      end_trial_source?: string | null,
      facebook_accessToken?: string | null,
      facebook_userID?: string | null,
      first_name?: string | null,
      firstpromoter_auth_token?: string | null,
      full_address?: string | null,
      id?: string | null,
      invoices?:  Array< {
        __typename: "Invoice",
        created_at?: string | null,
        customer_id?: string | null,
        id?: string | null,
        invoice_body?: string | null,
        status?: string | null,
        updated_at?: string | null,
      } | null > | null,
      last_name?: string | null,
      line1?: string | null,
      manager_id?: string | null,
      marketing_status?: number | null,
      phone_number?: string | null,
      postal_code?: string | null,
      products?:  {
        __typename: "Product",
        plan_product_id?: string | null,
        price_id?: string | null,
        product_code?: string | null,
        product_id?: string | null,
        product_name?: string | null,
      } | null,
      profile_photo?: string | null,
      state?: string | null,
      status?: string | null,
      stripe_connect_account_id?: string | null,
      subscription?:  {
        __typename: "Subscription",
        created_at?: string | null,
        customer_id?: string | null,
        id?: string | null,
        status?: string | null,
        subscription_body?: string | null,
        updated_at?: string | null,
        trial_end?: string | null,
        trial_start?: string | null,
        trial_left?: number | null,
        promo_code?:  {
          __typename: "DiscountCode",
          code?: string | null,
          status?: string | null,
          duration?: number | null,
          amount?: number | null,
          type?: string | null,
        } | null,
        plan?:  {
          __typename: "Plan",
          business_limit?: number | null,
          page_view_limit?: number | null,
          plan_code?: string | null,
          plan_name?: string | null,
          plan_price_id?: string | null,
          plan_product_id?: string | null,
          staff_limit?: number | null,
        } | null,
        plan_changed?: boolean | null,
        plan_code?: string | null,
        current_revenue?: number | null,
        current_billing_period_start?: string | null,
        current_billing_period_end?: string | null,
      } | null,
      subscription_status?: string | null,
      tik_tok_access_token?: string | null,
      tik_tok_integration?: boolean | null,
      timezone?: string | null,
      two_factor_deactivate_business?: number | null,
      two_factor_remove_staff_account?: number | null,
      updated_at?: string | null,
      user_id?: string | null,
      user_plan?:  {
        __typename: "Plan",
        business_limit?: number | null,
        page_view_limit?: number | null,
        plan_code?: string | null,
        plan_name?: string | null,
        plan_price_id?: string | null,
        plan_product_id?: string | null,
        staff_limit?: number | null,
      } | null,
      verification_method?: string | null,
      shopify_store_url?: string | null,
      business_access?:  Array< {
        __typename: "BusinessAccess",
        vanity_name?: string | null,
      } | null > | null,
      sessions?:  Array< {
        __typename: "UserSession",
        browser_name?: string | null,
        browser_version?: string | null,
        created_at?: string | null,
        ip?: string | null,
        location?: string | null,
        os_name?: string | null,
        os_version?: string | null,
        user_id?: string | null,
      } | null > | null,
      post_hog_user_id?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type VerifyTwoFactorNewMutationVariables = {
  verifyTwoFactorInput: VerifyTwoFactorInput,
};

export type VerifyTwoFactorNewMutation = {
  verifyTwoFactorNew?:  {
    __typename: "VerifyTwoFactorResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type RefreshDataForBusinessMutationVariables = {
  refreshDataForBusinessInput: RefreshDataForBusiness,
};

export type RefreshDataForBusinessMutation = {
  refreshDataForBusiness?:  {
    __typename: "RefreshDataForResponse",
    data?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateShopifyStoreUrlNewMutationVariables = {
  updateShopifyStoreUrlInput: UpdateShopifyStoreUrlInput,
};

export type UpdateShopifyStoreUrlNewMutation = {
  updateShopifyStoreUrlNew?:  {
    __typename: "UpdateShopifyStoreUrlResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SetUserSessionNewMutationVariables = {
};

export type SetUserSessionNewMutation = {
  setUserSessionNew?:  {
    __typename: "SetUserSessionResponsePrisma",
    data?:  {
      __typename: "UserSessionPrisma",
      browser_name?: string | null,
      browser_version?: string | null,
      created_at?: string | null,
      ip?: string | null,
      location?: string | null,
      os_name?: string | null,
      os_version?: string | null,
      user?:  {
        __typename: "UserConnectionPrisma",
        connect:  {
          __typename: "UserIDPrisma",
          id: string,
        },
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateShopifySubscriptionNewMutationVariables = {
  createShopifySubscriptionInput: CreateShopifySubscriptionInput,
};

export type CreateShopifySubscriptionNewMutation = {
  createShopifySubscriptionNew?:  {
    __typename: "CreateShopifySubscriptionResponse",
    data?:  {
      __typename: "ShopifySubscription",
      confirmationUrl?: string | null,
      id?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateShopifySubscriptionNewPricingMutationVariables = {
  createShopifySubscriptionInput: CreateShopifySubscriptionInput,
};

export type CreateShopifySubscriptionNewPricingMutation = {
  createShopifySubscriptionNewPricing?:  {
    __typename: "CreateShopifySubscriptionResponse",
    data?:  {
      __typename: "ShopifySubscription",
      confirmationUrl?: string | null,
      id?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type RegisterShopifyUserNewMutationVariables = {
  registerShopifyUserInput: RegisterShopifyUserInput,
};

export type RegisterShopifyUserNewMutation = {
  registerShopifyUserNew?:  {
    __typename: "RegisterShopifyUserResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    user?:  {
      __typename: "User",
      default_business_id?: string | null,
      account_state?: string | null,
      affiliate_auth_token?: string | null,
      auto_scaling_setting?: number | null,
      balance?: number | null,
      cancellation_reason?: string | null,
      card_expiry_date?: string | null,
      card_last_four_digits?: string | null,
      card_type?: string | null,
      city?: string | null,
      client_billing_account_id?: string | null,
      country_code?: string | null,
      country_name?: string | null,
      country_phone_prefix?: string | null,
      created_at?: string | null,
      currency?: string | null,
      current_billing_period_end?: number | null,
      current_billing_period_start?: number | null,
      data_deleting_on?: number | null,
      data_retention_period?: number | null,
      email?: string | null,
      end_trial_source?: string | null,
      facebook_accessToken?: string | null,
      facebook_userID?: string | null,
      first_name?: string | null,
      firstpromoter_auth_token?: string | null,
      full_address?: string | null,
      id?: string | null,
      invoices?:  Array< {
        __typename: "Invoice",
        created_at?: string | null,
        customer_id?: string | null,
        id?: string | null,
        invoice_body?: string | null,
        status?: string | null,
        updated_at?: string | null,
      } | null > | null,
      last_name?: string | null,
      line1?: string | null,
      manager_id?: string | null,
      marketing_status?: number | null,
      phone_number?: string | null,
      postal_code?: string | null,
      products?:  {
        __typename: "Product",
        plan_product_id?: string | null,
        price_id?: string | null,
        product_code?: string | null,
        product_id?: string | null,
        product_name?: string | null,
      } | null,
      profile_photo?: string | null,
      state?: string | null,
      status?: string | null,
      stripe_connect_account_id?: string | null,
      subscription?:  {
        __typename: "Subscription",
        created_at?: string | null,
        customer_id?: string | null,
        id?: string | null,
        status?: string | null,
        subscription_body?: string | null,
        updated_at?: string | null,
        trial_end?: string | null,
        trial_start?: string | null,
        trial_left?: number | null,
        promo_code?:  {
          __typename: "DiscountCode",
          code?: string | null,
          status?: string | null,
          duration?: number | null,
          amount?: number | null,
          type?: string | null,
        } | null,
        plan?:  {
          __typename: "Plan",
          business_limit?: number | null,
          page_view_limit?: number | null,
          plan_code?: string | null,
          plan_name?: string | null,
          plan_price_id?: string | null,
          plan_product_id?: string | null,
          staff_limit?: number | null,
        } | null,
        plan_changed?: boolean | null,
        plan_code?: string | null,
        current_revenue?: number | null,
        current_billing_period_start?: string | null,
        current_billing_period_end?: string | null,
      } | null,
      subscription_status?: string | null,
      tik_tok_access_token?: string | null,
      tik_tok_integration?: boolean | null,
      timezone?: string | null,
      two_factor_deactivate_business?: number | null,
      two_factor_remove_staff_account?: number | null,
      updated_at?: string | null,
      user_id?: string | null,
      user_plan?:  {
        __typename: "Plan",
        business_limit?: number | null,
        page_view_limit?: number | null,
        plan_code?: string | null,
        plan_name?: string | null,
        plan_price_id?: string | null,
        plan_product_id?: string | null,
        staff_limit?: number | null,
      } | null,
      verification_method?: string | null,
      shopify_store_url?: string | null,
      business_access?:  Array< {
        __typename: "BusinessAccess",
        vanity_name?: string | null,
      } | null > | null,
      sessions?:  Array< {
        __typename: "UserSession",
        browser_name?: string | null,
        browser_version?: string | null,
        created_at?: string | null,
        ip?: string | null,
        location?: string | null,
        os_name?: string | null,
        os_version?: string | null,
        user_id?: string | null,
      } | null > | null,
      post_hog_user_id?: string | null,
    } | null,
  } | null,
};

export type CheckShopifyLoginNewMutationVariables = {
  checkShopifyLoginInput: CheckShopifyLoginInput,
};

export type CheckShopifyLoginNewMutation = {
  checkShopifyLoginNew?:  {
    __typename: "CheckShopifyLoginResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SendRegisterUserEmailNewMutationVariables = {
  sendRegisterUserEmailInput: SendRegisterUserEmailInput,
};

export type SendRegisterUserEmailNewMutation = {
  sendRegisterUserEmailNew?:  {
    __typename: "SendRegisterUserEmailPrismaResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    user?:  {
      __typename: "UserPrisma",
      id: string,
      first_name: string,
      last_name: string,
      email: string,
      address1?: string | null,
      password?: string | null,
      timezone?: string | null,
      currency?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      businesses?:  Array< {
        __typename: "UserXBusinessPrisma",
        user_id: string,
        business_id: string,
        business:  {
          __typename: "BusinessPrisma",
          id: string,
          store_id: string,
          name: string,
          status: string,
          logo?: string | null,
          vanity_name: string,
          reminder_status?: boolean | null,
          show_budget_confirmation?: boolean | null,
          monthly_budget: number,
          business_goal?: string | null,
          campaign_roas_goal?: string | null,
          adset_roas_goal?: string | null,
          ad_roas_goal?: string | null,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
          ad_account_settings:  Array< {
            __typename: "AdAccountSettingsPrisma",
            id: string,
            business_id: string,
            ad_platform_id: string,
            premium_page_views: number,
            external_platform?: string | null,
            active_campaign_count: number,
            paused_campaign_count: number,
            social_account_id?: string | null,
            social_account_name?: string | null,
            social_account_currency?: string | null,
            social_account_timezone?: string | null,
            access_token?: string | null,
            utm_count: number,
            social_integration?: string | null,
            conversion_api_enabled?: string | null,
            pixel_id?: string | null,
            social_refresh_token?: string | null,
            updateKey?: string | null,
            last_data_refreshed?: string | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
            ad_platform:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } >,
          subscriptions:  Array< {
            __typename: "SubscriptionPrisma",
            id: string,
            business_id: string,
            subscription_plan_code?: string | null,
            status: string,
            processor?: string | null,
            store_subscription_body?: string | null,
            store_subscription_id?: string | null,
            promo_code_id?: string | null,
            trial_start?: string | null,
            trial_end?: string | null,
            trial_left?: number | null,
            current_billing_period_start?: string | null,
            current_billing_period_end?: string | null,
            subscription_end_date?: string | null,
            business_limit?: number | null,
            staff_limit?: number | null,
            subscription_charges?:  Array< {
              __typename: "SubscriptionChargesPrisma",
              id: string,
              business_id: string,
              subscription_id?: string | null,
              store_revenue: number,
              amount_billed: number,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
            } | null > | null,
            promo_codes?:  {
              __typename: "PromoCodePrisma",
              id?: string | null,
              code?: string | null,
              type?: string | null,
              status?: string | null,
              duration?: number | null,
              amount?: number | null,
              created_at?: string | null,
              updated_at?: string | null,
              deleted_at?: string | null,
            } | null,
            current_revenue?: number | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
          } >,
          store?:  {
            __typename: "StorePrisma",
            id?: string | null,
            shop_name?: string | null,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            state?: string | null,
            zip?: string | null,
            country?: string | null,
            store_url?: string | null,
            timezone?: string | null,
            currency?: string | null,
            created_at?: string | null,
            updated_at?: string | null,
            deleted_at?: string | null,
          } | null,
          completed_onboarding_call?: boolean | null,
          has_visitor_session?: boolean | null,
          business_roas_goals?:  Array< {
            __typename: "BusinessRoasGoalPrisma",
            id: string,
            roas_goal: number,
            ad_platform_id: string,
            ad_platforms:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } | null > | null,
          tour_completed_at?: string | null,
          tour_skipped_at?: string | null,
          feature_flags?: Array< string | null > | null,
          onboarding_call_completed_at?: string | null,
          pinned_inspirations?: Array< string | null > | null,
        },
        user_type: string,
        is_default_business: boolean,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } > | null,
      profile_photo?: string | null,
      is_admin_user?: boolean | null,
      link_tracking_visit_count?: number | null,
      link_tracking_view_status?: string | null,
      growth_hub_clicked_at?: string | null,
      inspirations_tutorial_finished_at?: string | null,
      mini_shop_tutorial_finished_at?: string | null,
    } | null,
  } | null,
};

export type CancelShopifySubscriptionNewMutationVariables = {
  cancelShopifySubscriptionInput?: CancelShopifySubscriptionInput | null,
};

export type CancelShopifySubscriptionNewMutation = {
  cancelShopifySubscriptionNew?:  {
    __typename: "CancelShopifySubscriptionResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CancelShopifySubscriptionEarlyNewMutationVariables = {
  cancelShopifySubscriptionEarlyInput: CancelShopifySubscriptionEarlyInput,
};

export type CancelShopifySubscriptionEarlyNewMutation = {
  cancelShopifySubscriptionEarlyNew?:  {
    __typename: "CancelShopifySubscriptionResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateShopifyScriptTagNewMutationVariables = {
};

export type CreateShopifyScriptTagNewMutation = {
  createShopifyScriptTagNew?:  {
    __typename: "CreateShopifyScriptTagResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GenerateBusinessAIReportMutationVariables = {
  generateBusinessAIReportInput: GenerateBusinessAIReportInput,
};

export type GenerateBusinessAIReportMutation = {
  generateBusinessAIReport?:  {
    __typename: "GenerateBusinessAIReportResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SetUtmValuesSocialCampaignMutationVariables = {
  setUtmValuesSocialCampaignInput: SetUtmValuesSocialCampaignInput,
};

export type SetUtmValuesSocialCampaignMutation = {
  setUtmValuesSocialCampaign?:  {
    __typename: "SetUtmValuesSocialCampaignResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    data?:  {
      __typename: "SetUtmValuesFbCampaignResponseData",
      totalAdsToConnect?: number | null,
      adsConnected?: Array< string | null > | null,
      adsFailed?:  Array< {
        __typename: "SetUtmValuesFbCampaignErrorAd",
        ad?: string | null,
        error?: string | null,
      } | null > | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type AddDiscountCodeNewMutationVariables = {
  addDiscountCodeInput: AddDiscountCodeInput,
};

export type AddDiscountCodeNewMutation = {
  addDiscountCodeNew?:  {
    __typename: "AddDiscountCodeResponsePrisma",
    data?:  {
      __typename: "PromoCode",
      id?: string | null,
      code?: string | null,
      type?: string | null,
      status?: string | null,
      duration?: number | null,
      amount?: number | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type AddDiscountCodeInactiveSubscriptionNewMutationVariables = {
  addDiscountCodeInput: AddDiscountCodeInput,
};

export type AddDiscountCodeInactiveSubscriptionNewMutation = {
  addDiscountCodeInactiveSubscriptionNew?:  {
    __typename: "AddDiscountCodeResponsePrisma",
    data?:  {
      __typename: "PromoCode",
      id?: string | null,
      code?: string | null,
      type?: string | null,
      status?: string | null,
      duration?: number | null,
      amount?: number | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type RemoveDiscountCodeNewMutationVariables = {
  addDiscountCodeInput: AddDiscountCodeInput,
};

export type RemoveDiscountCodeNewMutation = {
  removeDiscountCodeNew?:  {
    __typename: "RemoveDiscountCodeNewResponse",
    data?:  {
      __typename: "SubscriptionPrisma",
      id: string,
      business_id: string,
      subscription_plan_code?: string | null,
      status: string,
      processor?: string | null,
      store_subscription_body?: string | null,
      store_subscription_id?: string | null,
      promo_code_id?: string | null,
      trial_start?: string | null,
      trial_end?: string | null,
      trial_left?: number | null,
      current_billing_period_start?: string | null,
      current_billing_period_end?: string | null,
      subscription_end_date?: string | null,
      business_limit?: number | null,
      staff_limit?: number | null,
      subscription_charges?:  Array< {
        __typename: "SubscriptionChargesPrisma",
        id: string,
        business_id: string,
        subscription_id?: string | null,
        store_revenue: number,
        amount_billed: number,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } | null > | null,
      promo_codes?:  {
        __typename: "PromoCodePrisma",
        id?: string | null,
        code?: string | null,
        type?: string | null,
        status?: string | null,
        duration?: number | null,
        amount?: number | null,
        created_at?: string | null,
        updated_at?: string | null,
        deleted_at?: string | null,
      } | null,
      current_revenue?: number | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateAdLevelStatusNewMutationVariables = {
  updateAdLevelStatusInput: UpdateAdLevelStatusInput,
};

export type UpdateAdLevelStatusNewMutation = {
  updateAdLevelStatusNew?:  {
    __typename: "UpdateAdLevelStatusResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateAdDailyBudgetNewMutationVariables = {
  updateAdDailyBudgetInput: updateAdDailyBudgetInput,
};

export type UpdateAdDailyBudgetNewMutation = {
  updateAdDailyBudgetNew?:  {
    __typename: "UpdateAdDailyBudgetResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateTrackableCopyNewMutationVariables = {
  createTrackableCopyInput: CreateTrackableCopyInput,
};

export type CreateTrackableCopyNewMutation = {
  createTrackableCopyNew?:  {
    __typename: "CreateTrackableCopyResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type DeleteTrackableCopyNewMutationVariables = {
  deleteTrackableCopyInput: DeleteTrackableCopyInput,
};

export type DeleteTrackableCopyNewMutation = {
  deleteTrackableCopyNew?:  {
    __typename: "DeleteTrackableCopyResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateTrackableCopyNewMutationVariables = {
  updateTrackableCopyInput: UpdateTrackableCopyInput,
};

export type UpdateTrackableCopyNewMutation = {
  updateTrackableCopyNew?:  {
    __typename: "UpdateTrackableCopyResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateKeyMetricsMonthlyBudgetMutationVariables = {
  updateMonthlyBudgetInput: UpdateMonthlyBudgetInput,
};

export type UpdateKeyMetricsMonthlyBudgetMutation = {
  updateKeyMetricsMonthlyBudget?:  {
    __typename: "UpdateMonthlyBudgetResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateKeyMetricsRoasTrackerMutationVariables = {
  updateKeyMetricsRoasGoalsInput: UpdateKeyMetricsRoasGoalsInput,
};

export type UpdateKeyMetricsRoasTrackerMutation = {
  updateKeyMetricsRoasTracker?:  {
    __typename: "UpdateKeyMetricsRoasGoalsResponse",
    data?:  Array< {
      __typename: "BusinessRoasGoalPrisma",
      id: string,
      roas_goal: number,
      ad_platform_id: string,
      ad_platforms:  {
        __typename: "AdPlatformPrisma",
        id: string,
        name: string,
        internal_source_name: string,
        dark_theme_image_url?: string | null,
        light_theme_image_url: string,
        status: string,
      },
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type UpdateAudienceSegmentQueryByIdMutationVariables = {
  updateAudienceSegmentInput: UpdateAudienceSegmentQueryInput,
};

export type UpdateAudienceSegmentQueryByIdMutation = {
  updateAudienceSegmentQueryById?:  {
    __typename: "UpdateAudienceSegmentResponse",
    data?:  {
      __typename: "AudienceSuccessResponse",
      success?: boolean | null,
      social_audience?:  {
        __typename: "AudienceSocial",
        facebook?:  {
          __typename: "AudienceData",
          error?:  {
            __typename: "AudienceErrorMessage",
            message?: string | null,
          } | null,
          user_list_id?: string | null,
          audience_id?: string | null,
        } | null,
        google?:  {
          __typename: "AudienceData",
          error?:  {
            __typename: "AudienceErrorMessage",
            message?: string | null,
          } | null,
          user_list_id?: string | null,
          audience_id?: string | null,
        } | null,
      } | null,
      audience_errors?: Array< string | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type DeleteAudienceSegmentQueryByIdMutationVariables = {
  deleteAudienceSegmentInput: DeleteAudienceSegmentInput,
};

export type DeleteAudienceSegmentQueryByIdMutation = {
  deleteAudienceSegmentQueryById?:  {
    __typename: "DeleteAudienceSegmentResponse",
    data?:  {
      __typename: "AudienceSuccessResponse",
      success?: boolean | null,
      social_audience?:  {
        __typename: "AudienceSocial",
        facebook?:  {
          __typename: "AudienceData",
          error?:  {
            __typename: "AudienceErrorMessage",
            message?: string | null,
          } | null,
          user_list_id?: string | null,
          audience_id?: string | null,
        } | null,
        google?:  {
          __typename: "AudienceData",
          error?:  {
            __typename: "AudienceErrorMessage",
            message?: string | null,
          } | null,
          user_list_id?: string | null,
          audience_id?: string | null,
        } | null,
      } | null,
      audience_errors?: Array< string | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateSuggestedSegmentsMutationVariables = {
  createSuggestedSegmentsInput: CreateSuggestedSegmentsInput,
};

export type CreateSuggestedSegmentsMutation = {
  createSuggestedSegments?:  {
    __typename: "CreateSuggestedSegmentsResponse",
    data?:  {
      __typename: "Segments",
      id: string,
      business_id: string,
      no_of_customers?: number | null,
      added_revenue?: number | null,
      audience?: string | null,
      type: string,
      status: string,
      query_details?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      group_name: string,
      deleted_at?: string | null,
      dynamic_query_params: string,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateUserIntercomEmailTwoMutationVariables = {
  createUserIntercomEmailTwoInput: CreateUserIntercomEmailTwoInput,
};

export type CreateUserIntercomEmailTwoMutation = {
  createUserIntercomEmailTwo?:  {
    __typename: "CreateUserIntercomEmailTwoResponse",
    message?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    nextToken?: string | null,
  } | null,
};

export type CreateUserStoreSessionsMutationVariables = {
  createUserStoreSessionsInput: CreateUserStoreSessionsInput,
};

export type CreateUserStoreSessionsMutation = {
  createUserStoreSessions?:  {
    __typename: "CreateUserStoreSessionsResponse",
    data?:  {
      __typename: "CreateUserStoreSessionsResponseData",
      shop?: string | null,
      state?: string | null,
      isOnline?: boolean | null,
      scope?: string | null,
      expires?: string | null,
      accessToken?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type SetStoreBuildConfigMutationVariables = {
  setStoreBuildConfigInput: SetStoreBuildConfigInput,
};

export type SetStoreBuildConfigMutation = {
  setStoreBuildConfig?:  {
    __typename: "SetStoreBuildConfigResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CreateStoreMutationVariables = {
  createStoreInput: CreateStoreInput,
};

export type CreateStoreMutation = {
  createStore?:  {
    __typename: "CreateStoreResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type DeleteStoreByShopNameMutationVariables = {
  deleteStoreByShopNameInput: DeleteStoreByShopNameInput,
};

export type DeleteStoreByShopNameMutation = {
  deleteStoreByShopName?:  {
    __typename: "DeleteStoreByShopNameResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type ExtendTrialMutationVariables = {
  extendTrialInput: ExtendTrialInput,
};

export type ExtendTrialMutation = {
  extendTrial?:  {
    __typename: "ExtendTrialResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type AutoScalingSettingNewQueryVariables = {
};

export type AutoScalingSettingNewQuery = {
  autoScalingSettingNew?:  {
    __typename: "AutoScalingSettingResponse",
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetBusinessTechStackQueryVariables = {
  getBusinessTechStackInput: GetBusinessTechStackInput,
};

export type GetBusinessTechStackQuery = {
  getBusinessTechStack?:  {
    __typename: "GetBusinessTechStackResponse",
    data?:  {
      __typename: "TechStackResponseData",
      business_id?: string | null,
      build_config?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessBillingInfoQueryVariables = {
  getBusinessBillingInfoInput: GetBusinessBillingInfoInput,
};

export type GetBusinessBillingInfoQuery = {
  getBusinessBillingInfo?:  {
    __typename: "GetBusinessBillingInfoResponse",
    data?:  {
      __typename: "BusinessBillingInfo",
      business_revenue?: number | null,
      business_orders_count?: number | null,
      plan_code?: string | null,
      plan_name?: string | null,
      plan_price?: string | null,
      next_billing_plan_code?: string | null,
      next_billing_plan_name?: string | null,
      next_billing_plan_price?: string | null,
      next_billing_date?: string | null,
      promo_code?: string | null,
      pricing_model?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetMiniShopDetailsQueryVariables = {
  getMiniShopDetailsInput: GetMiniShopDetailsInput,
};

export type GetMiniShopDetailsQuery = {
  getMiniShopDetails?:  {
    __typename: "GetMiniShopDetailsResponse",
    data?:  {
      __typename: "MiniShopDetails",
      topProducts?:  Array< {
        __typename: "PerformingProductsData",
        product_id?: string | null,
        name?: string | null,
        total_revenue?: number | null,
        product_image?: string | null,
        handle?: string | null,
      } | null > | null,
      miniShop?:  {
        __typename: "MiniShop",
        id?: string | null,
        shop_name?: string | null,
        shop_image?: string | null,
        business_id?: string | null,
        top_products?: string | null,
        deleted_at?: string | null,
        updated_at?: string | null,
        created_at?: string | null,
      } | null,
      trackableLink?:  {
        __typename: "TrackableCopy",
        id: string,
        name: string,
        description?: string | null,
        type?: string | null,
        short_code: string,
        destination_url: string,
        url_position?: string | null,
        business_id?: string | null,
        created?: string | null,
        stats?:  {
          __typename: "TrackableCopyStats",
          clicks?: number | null,
          purchases?: number | null,
          revenue?: number | null,
          prev_clicks?: number | null,
        } | null,
        is_onboarding_default?: boolean | null,
        is_mini_shop?: boolean | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetDefaultTrackableCopyQueryVariables = {
  getDefaultTrackableCopyInput: BusinessIdInput,
};

export type GetDefaultTrackableCopyQuery = {
  getDefaultTrackableCopy?:  {
    __typename: "GetDefaultTrackableCopyResponse",
    data?:  {
      __typename: "DefaultTrackableCopyResponseData",
      default_trackable_copy?:  {
        __typename: "TrackableCopy",
        id: string,
        name: string,
        description?: string | null,
        type?: string | null,
        short_code: string,
        destination_url: string,
        url_position?: string | null,
        business_id?: string | null,
        created?: string | null,
        stats?:  {
          __typename: "TrackableCopyStats",
          clicks?: number | null,
          purchases?: number | null,
          revenue?: number | null,
          prev_clicks?: number | null,
        } | null,
        is_onboarding_default?: boolean | null,
        is_mini_shop?: boolean | null,
      } | null,
      has_manually_created_trackable_copy?: boolean | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllAdminTasksQueryVariables = {
  getAllAdminTasksInput: GetAllAdminTasksInput,
};

export type GetAllAdminTasksQuery = {
  getAllAdminTasks?:  {
    __typename: "GetAllAdminTasksResponse",
    data?:  Array< {
      __typename: "AdminTask",
      id: string,
      title: string,
      description: string,
      priority: number,
      objectives?: Array< string | null > | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      views: number,
      completed: number,
      is_task_master: boolean,
      deeplink_target_url?: string | null,
      target_button_title?: string | null,
      task_image_url?: string | null,
      task_group_by_week?:  Array< {
        __typename: "TaskGroupByWeek",
        week_start: string,
        task_count: number,
      } > | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetUserTasksQueryVariables = {
  getUserTasksInput?: GetUserTasksInput | null,
};

export type GetUserTasksQuery = {
  getUserTasks?:  {
    __typename: "GetUserTasksResponse",
    data?:  Array< {
      __typename: "UserTask",
      id: string,
      task_x_business_id: string,
      title: string,
      description: string,
      priority: number,
      objectives?: Array< string | null > | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      deeplink_target_url?: string | null,
      target_button_title?: string | null,
      task_image_url?: string | null,
      parameters?: string | null,
      type?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetHomepageMetricsQueryVariables = {
  getHomepageMetricsInput?: GetHomepageMetricsInput | null,
};

export type GetHomepageMetricsQuery = {
  getHomepageMetrics?:  {
    __typename: "GetHomepageMetricsResponse",
    data?:  {
      __typename: "HomepageMetrics",
      total_orders?: number | null,
      average_order_value?: number | null,
      total_refunds?: number | null,
      sirge_total_conversion_value?: number | null,
      total_sales?: number | null,
      blended_roas?: number | null,
      total_amount_spent?: number | null,
      total_amount_spent_to_date?: number | null,
      total_conversion_value?: number | null,
      monthly_budget?: number | null,
      ads_generating_revenue_percentage?: number | null,
      total_facebook_orders?: number | null,
      total_google_orders?: number | null,
      total_tiktok_orders?: number | null,
      break_even_roas?: number | null,
      total_sales_percentage?: number | null,
      has_utm_not_connected_ad?: boolean | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetSalesBreakdownQueryVariables = {
  getSalesBreakdownInput?: GetSalesBreakdownInput | null,
};

export type GetSalesBreakdownQuery = {
  getSalesBreakdown?:  {
    __typename: "GetSalesBreakdownResponse",
    data?:  {
      __typename: "SalesBreakdown",
      total_sales?:  {
        __typename: "SalesBreakdownDetail",
        value?: number | null,
        order_count?: number | null,
        percentage?: number | null,
      } | null,
      total_revenue?:  {
        __typename: "SalesBreakdownDetail",
        value?: number | null,
        order_count?: number | null,
        percentage?: number | null,
      } | null,
      ads?:  {
        __typename: "SalesBreakdownDetail",
        value?: number | null,
        order_count?: number | null,
        percentage?: number | null,
      } | null,
      email?:  {
        __typename: "SalesBreakdownDetail",
        value?: number | null,
        order_count?: number | null,
        percentage?: number | null,
      } | null,
      social_media?:  {
        __typename: "SalesBreakdownDetail",
        value?: number | null,
        order_count?: number | null,
        percentage?: number | null,
      } | null,
      others?:  {
        __typename: "SalesBreakdownDetail",
        value?: number | null,
        order_count?: number | null,
        percentage?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GenerateUploadUrlNewQueryVariables = {
  generateUploadUrlInput: GenerateUploadUrlInput,
};

export type GenerateUploadUrlNewQuery = {
  generateUploadUrlNew?:  {
    __typename: "GenerateUploadUrlResponse",
    data?:  {
      __typename: "UploadUrlResponse",
      upload_url?: string | null,
      url?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetMemberCountByAdGroupIDsNewQueryVariables = {
  getMemberCountByAdGroupIDsInput: GetMemberCountByAdGroupIDsInputNew,
};

export type GetMemberCountByAdGroupIDsNewQuery = {
  getMemberCountByAdGroupIDsNew?:  {
    __typename: "GetMemberCountByAdGroupIDsResponse",
    data?:  {
      __typename: "MemberCount",
      adSetCount?: number | null,
      adsCount?: number | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetAllStaffAccountsNewQueryVariables = {
  getAllStaffAccountsInput?: GetAllStaffAccountsInput | null,
};

export type GetAllStaffAccountsNewQuery = {
  getAllStaffAccountsNew?:  {
    __typename: "GetAllStaffAccountsResponsePrisma",
    data?:  Array< {
      __typename: "StaffPrisma",
      id: string,
      first_name: string,
      last_name: string,
      email: string,
      address1?: string | null,
      password?: string | null,
      timezone?: string | null,
      currency?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      businesses?:  Array< {
        __typename: "StaffBusinessPrisma",
        user_id: string,
        business_id: string,
        user_type: string,
        is_default_business: boolean,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } > | null,
      profile_photo?: string | null,
      is_admin_user?: boolean | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetAdGroupBudgetDetailsNewQueryVariables = {
  getAdGroupBudgetDetailsInput: GetAdGroupBudgetDetailsInput,
};

export type GetAdGroupBudgetDetailsNewQuery = {
  getAdGroupBudgetDetailsNew?:  {
    __typename: "GetAdGroupBudgetDetailsResponse",
    data?:  {
      __typename: "AdGroupBudgetDetailsObject",
      adGroup?:  {
        __typename: "AdGroupBudgetDetails",
        daily_budget?: number | null,
        lifetime_budget?: number | null,
        name?: string | null,
        source_delivery_status?: string | null,
        source_secondary_status?: string | null,
        shared_budget_name?: string | null,
      } | null,
      relatedItems?:  Array< {
        __typename: "Performance",
        id?: string | null,
        source?: string | null,
        business_id?: string | null,
        purchases_count?: number | null,
        clicks_count?: number | null,
        daily_budget?: number | null,
        lifetime_budget?: number | null,
        budget?: number | null,
        shared_budget_name?: string | null,
        campaign_name?: string | null,
        campaign_budget?:  {
          __typename: "AdGroupBudgetDetails",
          daily_budget?: number | null,
          lifetime_budget?: number | null,
          name?: string | null,
          source_delivery_status?: string | null,
          source_secondary_status?: string | null,
          shared_budget_name?: string | null,
        } | null,
        ad_set_budget?:  {
          __typename: "AdGroupBudgetDetails",
          daily_budget?: number | null,
          lifetime_budget?: number | null,
          name?: string | null,
          source_delivery_status?: string | null,
          source_secondary_status?: string | null,
          shared_budget_name?: string | null,
        } | null,
        ad_images?: Array< string | null > | null,
        itemType?: string | null,
        ad_image?: string | null,
        ad_set?: string | null,
        ad_set_name?: string | null,
        ad?: string | null,
        ad_name?: string | null,
        sirge_ad_id?: string | null,
        sirge_adset_id?: string | null,
        sirge_campaign_id?: string | null,
        total_title?: string | null,
        source_delivery_status?: string | null,
        source_secondary_status?: string | null,
        clicks?: number | null,
        purchases?: number | null,
        purchases_source?: number | null,
        roas?: string | null,
        cost_per_purchase?: number | null,
        amount_spent?: number | null,
        // Deprecated. Moved to PerformanceDrawerStats
        average_cpm?: number | null,
        conversion_value?: number | null,
        total_conversion_value?: number | null,
        roas_ltv?: number | null,
        sirge_clicks?: number | null,
        sirge_purchases?: number | null,
        sirge_roas?: string | null,
        sirge_cost_per_purchase?: number | null,
        sirge_total_conversion_value?: number | null,
        created?: string | null,
        updated_at?: string | null,
        ad_type?: string | null,
        utm_status?: boolean | null,
        are_all_ads_connected?: boolean | null,
        transform?: boolean | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetBusinessSourcesByIdNewQueryVariables = {
  getBusinessSourcesByIdInput: GetBusinessSourcesByIdInput,
};

export type GetBusinessSourcesByIdNewQuery = {
  getBusinessSourcesByIdNew?:  {
    __typename: "GetBusinessSourcesByIdResponse",
    data?:  {
      __typename: "GetBusinessSourcesObject",
      sources?:  Array< {
        __typename: "SourcesBusiness",
        source?: string | null,
        unique_visitor?: number | null,
        clicks_count?: number | null,
        purchases_count?: number | null,
        url?: string | null,
        created?: string | null,
        referer?: string | null,
        business_id?: string | null,
      } | null > | null,
      totalInfo?:  {
        __typename: "TotalInfo",
        total_clicks?: number | null,
        total_purchases?: number | null,
        totalrecords?: number | null,
        total_visitors?: number | null,
        total_pageviews?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetBusinessSourcesDetailsByIdNewQueryVariables = {
  getBusinessSourcesDetailsByIdInput: GetBusinessSourcesDetailsByIdInput,
};

export type GetBusinessSourcesDetailsByIdNewQuery = {
  getBusinessSourcesDetailsByIdNew?:  {
    __typename: "GetBusinessSourcesDetailsByIdResponse",
    data?:  Array< {
      __typename: "SourcesBusiness",
      source?: string | null,
      unique_visitor?: number | null,
      clicks_count?: number | null,
      purchases_count?: number | null,
      url?: string | null,
      created?: string | null,
      referer?: string | null,
      business_id?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetBusinessGoogleTokenNewQueryVariables = {
  getBusinessGoogleTokenNewInput: GetBusinessGoogleTokenNewInput,
};

export type GetBusinessGoogleTokenNewQuery = {
  getBusinessGoogleTokenNew?:  {
    __typename: "GetBusinessGoogleTokenResponse",
    data?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetCurrentUserSessionsNewQueryVariables = {
  getCurrentUserSessionsInput?: GetCurrentUserSessionsInput | null,
};

export type GetCurrentUserSessionsNewQuery = {
  getCurrentUserSessionsNew?:  {
    __typename: "GetCurrentUserSessionsResponse",
    data?:  Array< {
      __typename: "UserSession",
      browser_name?: string | null,
      browser_version?: string | null,
      created_at?: string | null,
      ip?: string | null,
      location?: string | null,
      os_name?: string | null,
      os_version?: string | null,
      user_id?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllPlatformBusinessesQueryVariables = {
  getAllPlatformBusinessesInput: GetAllPlatformBusinessesInput,
};

export type GetAllPlatformBusinessesQuery = {
  getAllPlatformBusinesses?:  {
    __typename: "GetAllPlatformBusinessesResponse",
    data?:  {
      __typename: "GetAllPlatformBusinessesData",
      businessList?:  Array< {
        __typename: "AllPlatformBusiness",
        id?: string | null,
        name?: string | null,
        logo?: string | null,
        manager_email?: string | null,
        manager_name?: string | null,
        vanity_name?: string | null,
        techStackTracked?: boolean | null,
        onboarding_call_booked?: boolean | null,
        onboarding_call_completed_at?: string | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type FetchBusinessMetaDataQueryVariables = {
  fetchBusinessMetaDataInput: BusinessIdInput,
};

export type FetchBusinessMetaDataQuery = {
  fetchBusinessMetaData?:  {
    __typename: "FetchBusinessMetaDataResponse",
    data?:  {
      __typename: "BusinessMetaData",
      metadata?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetSocialAdAccountsQueryVariables = {
  getSocialAdAccountsInput: BusinessIdWithPlatform,
};

export type GetSocialAdAccountsQuery = {
  getSocialAdAccounts?:  {
    __typename: "SocialAdAccountsResponse",
    data?:  Array< {
      __typename: "SocialAdAccount",
      id?: string | null,
      currency?: string | null,
      name?: string | null,
      timezone?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetStoreByShopNameQueryVariables = {
  getStoreByShopNameInput: GetStoreByShopNameInput,
};

export type GetStoreByShopNameQuery = {
  getStoreByShopName?:  {
    __typename: "GetStoreByShopNameResponse",
    data?:  {
      __typename: "GetStoreByShopNameResponseData",
      id?: string | null,
      platform_id?: string | null,
      shop_name?: string | null,
      admin_email?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
      store_url?: string | null,
      access_token?: string | null,
      script_tag_id?: string | null,
      timezone?: string | null,
      currency?: string | null,
      script_installed?: boolean | null,
      script_uninstalled_date?: string | null,
      info?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      business?:  Array< {
        __typename: "StoresBusiness",
        id?: string | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessByShopQueryVariables = {
  getBusinessByShopInput: GetBusinessByShopInput,
};

export type GetBusinessByShopQuery = {
  getBusinessByShop?:  {
    __typename: "GetBusinessByShopResponse",
    data?:  {
      __typename: "BusinessByShopData",
      id?: string | null,
      store_id?: string | null,
      store_url?: string | null,
      shop_name?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessByShopUrlQueryVariables = {
  getBusinessByShopInput: GetBusinessByShopInput,
};

export type GetBusinessByShopUrlQuery = {
  getBusinessByShopUrl?:  {
    __typename: "GetBusinessByShopResponse",
    data?:  {
      __typename: "BusinessByShopData",
      id?: string | null,
      store_id?: string | null,
      store_url?: string | null,
      shop_name?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserByEmailQueryVariables = {
  getUserByEmailInput: GetUserByEmailInput,
};

export type GetUserByEmailQuery = {
  getUserByEmail?:  {
    __typename: "GetUserByEmailResponse",
    data?:  {
      __typename: "GetUserByEmailResponseData",
      id?: string | null,
      first_name?: string | null,
      last_name?: string | null,
      email?: string | null,
      address1?: string | null,
      address2?: string | null,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
      timezone?: string | null,
      currency?: string | null,
      profile_photo?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
      auto_scaling_setting?: number | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetMiniShopByIdQueryVariables = {
  getMiniShopByIdInput: GetMiniShopByIdInput,
};

export type GetMiniShopByIdQuery = {
  getMiniShopById?:  {
    __typename: "GetMiniShopByIdResponse",
    data?:  {
      __typename: "MiniShopPublic",
      id?: string | null,
      shop_name?: string | null,
      shop_image?: string | null,
      shop_url?: string | null,
      business_id?: string | null,
      top_products?: string | null,
      deleted_at?: string | null,
      updated_at?: string | null,
      created_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type CheckUserExistsByShopUrlQueryVariables = {
  checkUserExistsByShopUrlInput: CheckUserExistsByShopUrlInput,
};

export type CheckUserExistsByShopUrlQuery = {
  checkUserExistsByShopUrl?:  {
    __typename: "CheckUserExistsByShopUrlResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserStoreSessionsQueryVariables = {
  getUserStoreSessionsInput: GetUserStoreSessionsInput,
};

export type GetUserStoreSessionsQuery = {
  getUserStoreSessions?:  {
    __typename: "GetUserStoreSessionsResponse",
    data?:  {
      __typename: "GetUserStoreSessionsResponseData",
      shop?: string | null,
      state?: string | null,
      isOnline?: boolean | null,
      scope?: string | null,
      expires?: string | null,
      accessToken?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetStoreDataForCallbackAuthQueryVariables = {
  getStoreDataForCallbackAuthInput?: GetStoreDataForCallbackAuthInput | null,
};

export type GetStoreDataForCallbackAuthQuery = {
  getStoreDataForCallbackAuth?:  {
    __typename: "GetStoreDataForCallbackAuthResponse",
    data?:  {
      __typename: "StoreDataForCallbackAuth",
      first_name?: string | null,
      last_name?: string | null,
      email?: string | null,
      is_installed?: boolean | null,
      business_id?: string | null,
      user_exists?: boolean | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetStaffByIdNewQueryVariables = {
  getStaffByIdInput: GetStaffByIdInput,
};

export type GetStaffByIdNewQuery = {
  getStaffByIdNew?:  {
    __typename: "GetUserResponsePrisma",
    data?:  {
      __typename: "UserPrisma",
      id: string,
      first_name: string,
      last_name: string,
      email: string,
      address1?: string | null,
      password?: string | null,
      timezone?: string | null,
      currency?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      businesses?:  Array< {
        __typename: "UserXBusinessPrisma",
        user_id: string,
        business_id: string,
        business:  {
          __typename: "BusinessPrisma",
          id: string,
          store_id: string,
          name: string,
          status: string,
          logo?: string | null,
          vanity_name: string,
          reminder_status?: boolean | null,
          show_budget_confirmation?: boolean | null,
          monthly_budget: number,
          business_goal?: string | null,
          campaign_roas_goal?: string | null,
          adset_roas_goal?: string | null,
          ad_roas_goal?: string | null,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
          ad_account_settings:  Array< {
            __typename: "AdAccountSettingsPrisma",
            id: string,
            business_id: string,
            ad_platform_id: string,
            premium_page_views: number,
            external_platform?: string | null,
            active_campaign_count: number,
            paused_campaign_count: number,
            social_account_id?: string | null,
            social_account_name?: string | null,
            social_account_currency?: string | null,
            social_account_timezone?: string | null,
            access_token?: string | null,
            utm_count: number,
            social_integration?: string | null,
            conversion_api_enabled?: string | null,
            pixel_id?: string | null,
            social_refresh_token?: string | null,
            updateKey?: string | null,
            last_data_refreshed?: string | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
            ad_platform:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } >,
          subscriptions:  Array< {
            __typename: "SubscriptionPrisma",
            id: string,
            business_id: string,
            subscription_plan_code?: string | null,
            status: string,
            processor?: string | null,
            store_subscription_body?: string | null,
            store_subscription_id?: string | null,
            promo_code_id?: string | null,
            trial_start?: string | null,
            trial_end?: string | null,
            trial_left?: number | null,
            current_billing_period_start?: string | null,
            current_billing_period_end?: string | null,
            subscription_end_date?: string | null,
            business_limit?: number | null,
            staff_limit?: number | null,
            subscription_charges?:  Array< {
              __typename: "SubscriptionChargesPrisma",
              id: string,
              business_id: string,
              subscription_id?: string | null,
              store_revenue: number,
              amount_billed: number,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
            } | null > | null,
            promo_codes?:  {
              __typename: "PromoCodePrisma",
              id?: string | null,
              code?: string | null,
              type?: string | null,
              status?: string | null,
              duration?: number | null,
              amount?: number | null,
              created_at?: string | null,
              updated_at?: string | null,
              deleted_at?: string | null,
            } | null,
            current_revenue?: number | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
          } >,
          store?:  {
            __typename: "StorePrisma",
            id?: string | null,
            shop_name?: string | null,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            state?: string | null,
            zip?: string | null,
            country?: string | null,
            store_url?: string | null,
            timezone?: string | null,
            currency?: string | null,
            created_at?: string | null,
            updated_at?: string | null,
            deleted_at?: string | null,
          } | null,
          completed_onboarding_call?: boolean | null,
          has_visitor_session?: boolean | null,
          business_roas_goals?:  Array< {
            __typename: "BusinessRoasGoalPrisma",
            id: string,
            roas_goal: number,
            ad_platform_id: string,
            ad_platforms:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } | null > | null,
          tour_completed_at?: string | null,
          tour_skipped_at?: string | null,
          feature_flags?: Array< string | null > | null,
          onboarding_call_completed_at?: string | null,
          pinned_inspirations?: Array< string | null > | null,
        },
        user_type: string,
        is_default_business: boolean,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } > | null,
      profile_photo?: string | null,
      is_admin_user?: boolean | null,
      link_tracking_visit_count?: number | null,
      link_tracking_view_status?: string | null,
      growth_hub_clicked_at?: string | null,
      inspirations_tutorial_finished_at?: string | null,
      mini_shop_tutorial_finished_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserNewQueryVariables = {
  getUserInput?: GetUserInput | null,
};

export type GetUserNewQuery = {
  getUserNew?:  {
    __typename: "GetUserResponsePrisma",
    data?:  {
      __typename: "UserPrisma",
      id: string,
      first_name: string,
      last_name: string,
      email: string,
      address1?: string | null,
      password?: string | null,
      timezone?: string | null,
      currency?: string | null,
      created_at: string,
      updated_at: string,
      deleted_at?: string | null,
      businesses?:  Array< {
        __typename: "UserXBusinessPrisma",
        user_id: string,
        business_id: string,
        business:  {
          __typename: "BusinessPrisma",
          id: string,
          store_id: string,
          name: string,
          status: string,
          logo?: string | null,
          vanity_name: string,
          reminder_status?: boolean | null,
          show_budget_confirmation?: boolean | null,
          monthly_budget: number,
          business_goal?: string | null,
          campaign_roas_goal?: string | null,
          adset_roas_goal?: string | null,
          ad_roas_goal?: string | null,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
          ad_account_settings:  Array< {
            __typename: "AdAccountSettingsPrisma",
            id: string,
            business_id: string,
            ad_platform_id: string,
            premium_page_views: number,
            external_platform?: string | null,
            active_campaign_count: number,
            paused_campaign_count: number,
            social_account_id?: string | null,
            social_account_name?: string | null,
            social_account_currency?: string | null,
            social_account_timezone?: string | null,
            access_token?: string | null,
            utm_count: number,
            social_integration?: string | null,
            conversion_api_enabled?: string | null,
            pixel_id?: string | null,
            social_refresh_token?: string | null,
            updateKey?: string | null,
            last_data_refreshed?: string | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
            ad_platform:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } >,
          subscriptions:  Array< {
            __typename: "SubscriptionPrisma",
            id: string,
            business_id: string,
            subscription_plan_code?: string | null,
            status: string,
            processor?: string | null,
            store_subscription_body?: string | null,
            store_subscription_id?: string | null,
            promo_code_id?: string | null,
            trial_start?: string | null,
            trial_end?: string | null,
            trial_left?: number | null,
            current_billing_period_start?: string | null,
            current_billing_period_end?: string | null,
            subscription_end_date?: string | null,
            business_limit?: number | null,
            staff_limit?: number | null,
            subscription_charges?:  Array< {
              __typename: "SubscriptionChargesPrisma",
              id: string,
              business_id: string,
              subscription_id?: string | null,
              store_revenue: number,
              amount_billed: number,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
            } | null > | null,
            promo_codes?:  {
              __typename: "PromoCodePrisma",
              id?: string | null,
              code?: string | null,
              type?: string | null,
              status?: string | null,
              duration?: number | null,
              amount?: number | null,
              created_at?: string | null,
              updated_at?: string | null,
              deleted_at?: string | null,
            } | null,
            current_revenue?: number | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
          } >,
          store?:  {
            __typename: "StorePrisma",
            id?: string | null,
            shop_name?: string | null,
            address1?: string | null,
            address2?: string | null,
            city?: string | null,
            state?: string | null,
            zip?: string | null,
            country?: string | null,
            store_url?: string | null,
            timezone?: string | null,
            currency?: string | null,
            created_at?: string | null,
            updated_at?: string | null,
            deleted_at?: string | null,
          } | null,
          completed_onboarding_call?: boolean | null,
          has_visitor_session?: boolean | null,
          business_roas_goals?:  Array< {
            __typename: "BusinessRoasGoalPrisma",
            id: string,
            roas_goal: number,
            ad_platform_id: string,
            ad_platforms:  {
              __typename: "AdPlatformPrisma",
              id: string,
              name: string,
              internal_source_name: string,
              dark_theme_image_url?: string | null,
              light_theme_image_url: string,
              status: string,
            },
          } | null > | null,
          tour_completed_at?: string | null,
          tour_skipped_at?: string | null,
          feature_flags?: Array< string | null > | null,
          onboarding_call_completed_at?: string | null,
          pinned_inspirations?: Array< string | null > | null,
        },
        user_type: string,
        is_default_business: boolean,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
      } > | null,
      profile_photo?: string | null,
      is_admin_user?: boolean | null,
      link_tracking_visit_count?: number | null,
      link_tracking_view_status?: string | null,
      growth_hub_clicked_at?: string | null,
      inspirations_tutorial_finished_at?: string | null,
      mini_shop_tutorial_finished_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetPerformanceDetailsNewQueryVariables = {
  getPerformanceDetailsInput?: GetPerformanceDetailsInput | null,
};

export type GetPerformanceDetailsNewQuery = {
  getPerformanceDetailsNew?:  {
    __typename: "GetPerformanceDetailsResponseNew",
    data?:  {
      __typename: "GetBusinessPerformanceDetailsNew",
      performance?:  Array< {
        __typename: "PerformanceNew",
        id: string,
        source?: string | null,
        business_id: string,
        purchases_count?: number | null,
        clicks_count?: number | null,
        daily_budget?: number | null,
        lifetime_budget?: number | null,
        budget?: number | null,
        shared_budget_name?: string | null,
        campaign_name?: string | null,
        campaign_budget?: number | null,
        ad_set_budget?: number | null,
        ad_images?: Array< string | null > | null,
        itemType?: string | null,
        ad_image?: string | null,
        ad_set?: string | null,
        ad_set_name?: string | null,
        ad?: string | null,
        ad_name?: string | null,
        sirge_ad_id?: string | null,
        sirge_adset_id?: string | null,
        sirge_campaign_id?: string | null,
        total_title?: string | null,
        source_delivery_status?: string | null,
        source_secondary_status?: string | null,
        clicks?: number | null,
        purchases?: number | null,
        purchases_source?: number | null,
        roas?: string | null,
        cost_per_purchase?: number | null,
        amount_spent?: number | null,
        // Deprecated. Moved to PerformanceDrawerStats
        average_cpm?: number | null,
        conversion_value?: number | null,
        total_conversion_value?: number | null,
        prev_total_conversion_value?: number | null,
        roas_ltv?: number | null,
        sirge_clicks?: number | null,
        sirge_purchases?: number | null,
        sirge_roas?: string | null,
        sirge_cost_per_purchase?: number | null,
        sirge_total_conversion_value?: number | null,
        prev_sirge_total_conversion_value?: number | null,
        created?: string | null,
        updated_at?: string | null,
        ad_type?: string | null,
        utm_status?: boolean | null,
        are_all_ads_connected?: boolean | null,
        transform?: boolean | null,
      } | null > | null,
      summary?:  {
        __typename: "PerformanceSummary",
        amount_spent?: number | null,
        clicks?: number | null,
        purchases?: number | null,
        purchases_source?: number | null,
        cost_per_purchase?: number | null,
        total_conversion_value?: number | null,
        prev_total_conversion_value?: number | null,
        roas?: string | null,
        ft_clicks?: number | null,
        ft_purchases?: number | null,
        ft_cost_per_purchase?: number | null,
        ft_total_conversion_value?: number | null,
        prev_ft_total_conversion_value: number,
        ft_roas_ltv?: number | null,
        ft_roas?: string | null,
        ft_average_cpm?: number | null,
        number_of_records?: number | null,
      } | null,
      isRoasGoalsSet?: boolean | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetBusinessConnectionsNewQueryVariables = {
  getBusinessConnectionsInput: GetBusinessConnectionsInput,
};

export type GetBusinessConnectionsNewQuery = {
  getBusinessConnectionsNew?:  {
    __typename: "GetBusinessConnectionsResponse",
    data?:  {
      __typename: "BusinessConnection",
      facebook_ad_account_id?: string | null,
      facebook_ad_account_name?: string | null,
      script_installed?: boolean | null,
      shopify_store?: string | null,
      tik_tok_ad_account_id?: string | null,
      tik_tok_ad_account_name?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetPurchasesByBusinessNewQueryVariables = {
  getPurchaseByBusinessInput: GetPurchaseByBusinessInput,
};

export type GetPurchasesByBusinessNewQuery = {
  getPurchasesByBusinessNew?:  {
    __typename: "GetPurchasesByBusinessResponseNew",
    data?:  Array< {
      __typename: "PurchaseByBusiness",
      customer_name?: string | null,
      customer_id?: string | null,
      created?: string | null,
      purchases_count?: number | null,
      conversion_value?: number | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetUserDetailsQueryVariables = {
  getUserDetailsInput: GetUserDetailsInput,
};

export type GetUserDetailsQuery = {
  getUserDetails?:  {
    __typename: "GetUserDetailsResponse",
    data?:  {
      __typename: "UserDetails",
      business?:  {
        __typename: "BusinessPrisma",
        id: string,
        store_id: string,
        name: string,
        status: string,
        logo?: string | null,
        vanity_name: string,
        reminder_status?: boolean | null,
        show_budget_confirmation?: boolean | null,
        monthly_budget: number,
        business_goal?: string | null,
        campaign_roas_goal?: string | null,
        adset_roas_goal?: string | null,
        ad_roas_goal?: string | null,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
        ad_account_settings:  Array< {
          __typename: "AdAccountSettingsPrisma",
          id: string,
          business_id: string,
          ad_platform_id: string,
          premium_page_views: number,
          external_platform?: string | null,
          active_campaign_count: number,
          paused_campaign_count: number,
          social_account_id?: string | null,
          social_account_name?: string | null,
          social_account_currency?: string | null,
          social_account_timezone?: string | null,
          access_token?: string | null,
          utm_count: number,
          social_integration?: string | null,
          conversion_api_enabled?: string | null,
          pixel_id?: string | null,
          social_refresh_token?: string | null,
          updateKey?: string | null,
          last_data_refreshed?: string | null,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
          ad_platform:  {
            __typename: "AdPlatformPrisma",
            id: string,
            name: string,
            internal_source_name: string,
            dark_theme_image_url?: string | null,
            light_theme_image_url: string,
            status: string,
          },
        } >,
        subscriptions:  Array< {
          __typename: "SubscriptionPrisma",
          id: string,
          business_id: string,
          subscription_plan_code?: string | null,
          status: string,
          processor?: string | null,
          store_subscription_body?: string | null,
          store_subscription_id?: string | null,
          promo_code_id?: string | null,
          trial_start?: string | null,
          trial_end?: string | null,
          trial_left?: number | null,
          current_billing_period_start?: string | null,
          current_billing_period_end?: string | null,
          subscription_end_date?: string | null,
          business_limit?: number | null,
          staff_limit?: number | null,
          subscription_charges?:  Array< {
            __typename: "SubscriptionChargesPrisma",
            id: string,
            business_id: string,
            subscription_id?: string | null,
            store_revenue: number,
            amount_billed: number,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
          } | null > | null,
          promo_codes?:  {
            __typename: "PromoCodePrisma",
            id?: string | null,
            code?: string | null,
            type?: string | null,
            status?: string | null,
            duration?: number | null,
            amount?: number | null,
            created_at?: string | null,
            updated_at?: string | null,
            deleted_at?: string | null,
          } | null,
          current_revenue?: number | null,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
        } >,
        store?:  {
          __typename: "StorePrisma",
          id?: string | null,
          shop_name?: string | null,
          address1?: string | null,
          address2?: string | null,
          city?: string | null,
          state?: string | null,
          zip?: string | null,
          country?: string | null,
          store_url?: string | null,
          timezone?: string | null,
          currency?: string | null,
          created_at?: string | null,
          updated_at?: string | null,
          deleted_at?: string | null,
        } | null,
        completed_onboarding_call?: boolean | null,
        has_visitor_session?: boolean | null,
        business_roas_goals?:  Array< {
          __typename: "BusinessRoasGoalPrisma",
          id: string,
          roas_goal: number,
          ad_platform_id: string,
          ad_platforms:  {
            __typename: "AdPlatformPrisma",
            id: string,
            name: string,
            internal_source_name: string,
            dark_theme_image_url?: string | null,
            light_theme_image_url: string,
            status: string,
          },
        } | null > | null,
        tour_completed_at?: string | null,
        tour_skipped_at?: string | null,
        feature_flags?: Array< string | null > | null,
        onboarding_call_completed_at?: string | null,
        pinned_inspirations?: Array< string | null > | null,
      } | null,
      businesses?:  {
        __typename: "BusinessesPrisma",
        business_active_count?: number | null,
        business_count?: number | null,
        business_list?:  Array< {
          __typename: "UserXBusinessPrisma",
          user_id: string,
          business_id: string,
          business:  {
            __typename: "BusinessPrisma",
            id: string,
            store_id: string,
            name: string,
            status: string,
            logo?: string | null,
            vanity_name: string,
            reminder_status?: boolean | null,
            show_budget_confirmation?: boolean | null,
            monthly_budget: number,
            business_goal?: string | null,
            campaign_roas_goal?: string | null,
            adset_roas_goal?: string | null,
            ad_roas_goal?: string | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
            ad_account_settings:  Array< {
              __typename: "AdAccountSettingsPrisma",
              id: string,
              business_id: string,
              ad_platform_id: string,
              premium_page_views: number,
              external_platform?: string | null,
              active_campaign_count: number,
              paused_campaign_count: number,
              social_account_id?: string | null,
              social_account_name?: string | null,
              social_account_currency?: string | null,
              social_account_timezone?: string | null,
              access_token?: string | null,
              utm_count: number,
              social_integration?: string | null,
              conversion_api_enabled?: string | null,
              pixel_id?: string | null,
              social_refresh_token?: string | null,
              updateKey?: string | null,
              last_data_refreshed?: string | null,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
              ad_platform:  {
                __typename: "AdPlatformPrisma",
                id: string,
                name: string,
                internal_source_name: string,
                dark_theme_image_url?: string | null,
                light_theme_image_url: string,
                status: string,
              },
            } >,
            subscriptions:  Array< {
              __typename: "SubscriptionPrisma",
              id: string,
              business_id: string,
              subscription_plan_code?: string | null,
              status: string,
              processor?: string | null,
              store_subscription_body?: string | null,
              store_subscription_id?: string | null,
              promo_code_id?: string | null,
              trial_start?: string | null,
              trial_end?: string | null,
              trial_left?: number | null,
              current_billing_period_start?: string | null,
              current_billing_period_end?: string | null,
              subscription_end_date?: string | null,
              business_limit?: number | null,
              staff_limit?: number | null,
              subscription_charges?:  Array< {
                __typename: "SubscriptionChargesPrisma",
                id: string,
                business_id: string,
                subscription_id?: string | null,
                store_revenue: number,
                amount_billed: number,
                created_at: string,
                updated_at: string,
                deleted_at?: string | null,
              } | null > | null,
              promo_codes?:  {
                __typename: "PromoCodePrisma",
                id?: string | null,
                code?: string | null,
                type?: string | null,
                status?: string | null,
                duration?: number | null,
                amount?: number | null,
                created_at?: string | null,
                updated_at?: string | null,
                deleted_at?: string | null,
              } | null,
              current_revenue?: number | null,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
            } >,
            store?:  {
              __typename: "StorePrisma",
              id?: string | null,
              shop_name?: string | null,
              address1?: string | null,
              address2?: string | null,
              city?: string | null,
              state?: string | null,
              zip?: string | null,
              country?: string | null,
              store_url?: string | null,
              timezone?: string | null,
              currency?: string | null,
              created_at?: string | null,
              updated_at?: string | null,
              deleted_at?: string | null,
            } | null,
            completed_onboarding_call?: boolean | null,
            has_visitor_session?: boolean | null,
            business_roas_goals?:  Array< {
              __typename: "BusinessRoasGoalPrisma",
              id: string,
              roas_goal: number,
              ad_platform_id: string,
              ad_platforms:  {
                __typename: "AdPlatformPrisma",
                id: string,
                name: string,
                internal_source_name: string,
                dark_theme_image_url?: string | null,
                light_theme_image_url: string,
                status: string,
              },
            } | null > | null,
            tour_completed_at?: string | null,
            tour_skipped_at?: string | null,
            feature_flags?: Array< string | null > | null,
            onboarding_call_completed_at?: string | null,
            pinned_inspirations?: Array< string | null > | null,
          },
          user_type: string,
          is_default_business: boolean,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
        } | null > | null,
      } | null,
      status?:  {
        __typename: "BusinessActiveStatus",
        active?: boolean | null,
      } | null,
      user?:  {
        __typename: "UserPrisma",
        id: string,
        first_name: string,
        last_name: string,
        email: string,
        address1?: string | null,
        password?: string | null,
        timezone?: string | null,
        currency?: string | null,
        created_at: string,
        updated_at: string,
        deleted_at?: string | null,
        businesses?:  Array< {
          __typename: "UserXBusinessPrisma",
          user_id: string,
          business_id: string,
          business:  {
            __typename: "BusinessPrisma",
            id: string,
            store_id: string,
            name: string,
            status: string,
            logo?: string | null,
            vanity_name: string,
            reminder_status?: boolean | null,
            show_budget_confirmation?: boolean | null,
            monthly_budget: number,
            business_goal?: string | null,
            campaign_roas_goal?: string | null,
            adset_roas_goal?: string | null,
            ad_roas_goal?: string | null,
            created_at: string,
            updated_at: string,
            deleted_at?: string | null,
            ad_account_settings:  Array< {
              __typename: "AdAccountSettingsPrisma",
              id: string,
              business_id: string,
              ad_platform_id: string,
              premium_page_views: number,
              external_platform?: string | null,
              active_campaign_count: number,
              paused_campaign_count: number,
              social_account_id?: string | null,
              social_account_name?: string | null,
              social_account_currency?: string | null,
              social_account_timezone?: string | null,
              access_token?: string | null,
              utm_count: number,
              social_integration?: string | null,
              conversion_api_enabled?: string | null,
              pixel_id?: string | null,
              social_refresh_token?: string | null,
              updateKey?: string | null,
              last_data_refreshed?: string | null,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
              ad_platform:  {
                __typename: "AdPlatformPrisma",
                id: string,
                name: string,
                internal_source_name: string,
                dark_theme_image_url?: string | null,
                light_theme_image_url: string,
                status: string,
              },
            } >,
            subscriptions:  Array< {
              __typename: "SubscriptionPrisma",
              id: string,
              business_id: string,
              subscription_plan_code?: string | null,
              status: string,
              processor?: string | null,
              store_subscription_body?: string | null,
              store_subscription_id?: string | null,
              promo_code_id?: string | null,
              trial_start?: string | null,
              trial_end?: string | null,
              trial_left?: number | null,
              current_billing_period_start?: string | null,
              current_billing_period_end?: string | null,
              subscription_end_date?: string | null,
              business_limit?: number | null,
              staff_limit?: number | null,
              subscription_charges?:  Array< {
                __typename: "SubscriptionChargesPrisma",
                id: string,
                business_id: string,
                subscription_id?: string | null,
                store_revenue: number,
                amount_billed: number,
                created_at: string,
                updated_at: string,
                deleted_at?: string | null,
              } | null > | null,
              promo_codes?:  {
                __typename: "PromoCodePrisma",
                id?: string | null,
                code?: string | null,
                type?: string | null,
                status?: string | null,
                duration?: number | null,
                amount?: number | null,
                created_at?: string | null,
                updated_at?: string | null,
                deleted_at?: string | null,
              } | null,
              current_revenue?: number | null,
              created_at: string,
              updated_at: string,
              deleted_at?: string | null,
            } >,
            store?:  {
              __typename: "StorePrisma",
              id?: string | null,
              shop_name?: string | null,
              address1?: string | null,
              address2?: string | null,
              city?: string | null,
              state?: string | null,
              zip?: string | null,
              country?: string | null,
              store_url?: string | null,
              timezone?: string | null,
              currency?: string | null,
              created_at?: string | null,
              updated_at?: string | null,
              deleted_at?: string | null,
            } | null,
            completed_onboarding_call?: boolean | null,
            has_visitor_session?: boolean | null,
            business_roas_goals?:  Array< {
              __typename: "BusinessRoasGoalPrisma",
              id: string,
              roas_goal: number,
              ad_platform_id: string,
              ad_platforms:  {
                __typename: "AdPlatformPrisma",
                id: string,
                name: string,
                internal_source_name: string,
                dark_theme_image_url?: string | null,
                light_theme_image_url: string,
                status: string,
              },
            } | null > | null,
            tour_completed_at?: string | null,
            tour_skipped_at?: string | null,
            feature_flags?: Array< string | null > | null,
            onboarding_call_completed_at?: string | null,
            pinned_inspirations?: Array< string | null > | null,
          },
          user_type: string,
          is_default_business: boolean,
          created_at: string,
          updated_at: string,
          deleted_at?: string | null,
        } > | null,
        profile_photo?: string | null,
        is_admin_user?: boolean | null,
        link_tracking_visit_count?: number | null,
        link_tracking_view_status?: string | null,
        growth_hub_clicked_at?: string | null,
        inspirations_tutorial_finished_at?: string | null,
        mini_shop_tutorial_finished_at?: string | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserFacebookAccountNewQueryVariables = {
  getUserFacebookAccountInput: GetUserFacebookAccountInput,
};

export type GetUserFacebookAccountNewQuery = {
  getUserFacebookAccountNew?:  {
    __typename: "GetUserFacebookAccountResponse",
    data?:  {
      __typename: "FacebookAccount",
      id?: string | null,
      name?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetDiscountCodeStatusNewQueryVariables = {
  getDiscountCodeStatusInput: GetDiscountCodeStatusInput,
};

export type GetDiscountCodeStatusNewQuery = {
  getDiscountCodeStatusNew?:  {
    __typename: "GetDiscountCodeStatusNewResponse",
    data?:  {
      __typename: "PromoCode",
      id?: string | null,
      code?: string | null,
      type?: string | null,
      status?: string | null,
      duration?: number | null,
      amount?: number | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetKeyMetricsStatisticsGraphQueryVariables = {
  getKeyMetricsAnalyticsStatisticsInput: GetKeyMetricsAnalyticsStatisticsInput,
};

export type GetKeyMetricsStatisticsGraphQuery = {
  getKeyMetricsStatisticsGraph?:  {
    __typename: "GetKeyMetricsStatisticsGraphResponse",
    data?:  {
      __typename: "StatisticsGraphData",
      orders?:  Array< {
        __typename: "StatisticGraphDetail",
        date?: string | null,
        value?: number | null,
      } | null > | null,
      amount_spent?:  Array< {
        __typename: "StatisticGraphDetail",
        date?: string | null,
        value?: number | null,
      } | null > | null,
      store_visits?:  Array< {
        __typename: "StatisticGraphDetail",
        date?: string | null,
        value?: number | null,
      } | null > | null,
      total_sales?:  Array< {
        __typename: "StatisticGraphDetail",
        date?: string | null,
        value?: number | null,
      } | null > | null,
      ad_clicks?:  Array< {
        __typename: "StatisticGraphDetail",
        date?: string | null,
        value?: number | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetShopifyScopesStatusNewQueryVariables = {
};

export type GetShopifyScopesStatusNewQuery = {
  getShopifyScopesStatusNew?:  {
    __typename: "GetShopifyScopesStatusResponse",
    data?: boolean | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetAccountsCampaignsNewQueryVariables = {
  getAccountsCampaignsInput?: GetAccountsCampaignsInput | null,
};

export type GetAccountsCampaignsNewQuery = {
  getAccountsCampaignsNew?:  {
    __typename: "GetAccountsCampaignsResponse",
    data?:  {
      __typename: "GetBusinessAccountsCampaigns",
      facebook?:  Array< {
        __typename: "ItemSocialMediaIntegration",
        id?: string | null,
        name?: string | null,
        status?: string | null,
        utm_status?: boolean | null,
        utm_injection_supported?: boolean | null,
      } | null > | null,
      tiktok?:  Array< {
        __typename: "ItemSocialMediaIntegration",
        id?: string | null,
        name?: string | null,
        status?: string | null,
        utm_status?: boolean | null,
        utm_injection_supported?: boolean | null,
      } | null > | null,
      google?:  Array< {
        __typename: "ItemSocialMediaIntegration",
        id?: string | null,
        name?: string | null,
        status?: string | null,
        utm_status?: boolean | null,
        utm_injection_supported?: boolean | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetPerformanceDrawerBasicDetailsNewQueryVariables = {
  getPerformanceDrawerBasicDetailsInput?: GetPerformanceDrawerBasicDetailsInput | null,
};

export type GetPerformanceDrawerBasicDetailsNewQuery = {
  getPerformanceDrawerBasicDetailsNew?:  {
    __typename: "PerformanceDrawerBasicDetailsResponse",
    data?:  {
      __typename: "PerformanceDrawerBasicDetails",
      id: string,
      name?: string | null,
      campaign_name?: string | null,
      source?: string | null,
      source_delivery_status?: string | null,
      source_secondary_status?: string | null,
      no_of_ads?: number | null,
      no_of_adsets?: number | null,
      utm_status?: boolean | null,
      are_all_ads_connected?: boolean | null,
      daily_budget?: number | null,
      shared_budget_name?: string | null,
      lifetime_budget?: number | null,
      campaign_budget?: number | null,
      ad_set_budget?: number | null,
      ad_images?: Array< string | null > | null,
      ad_type?: string | null,
      created?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetPerformanceDrawerStatsNewQueryVariables = {
  getPerformanceDrawerStatsInput?: GetPerformanceDrawerStatsInput | null,
};

export type GetPerformanceDrawerStatsNewQuery = {
  getPerformanceDrawerStatsNew?:  {
    __typename: "PerformanceDrawerStatsResponse",
    data?:  {
      __typename: "PerformanceDrawerStats",
      total_amount_spent?: number | null,
      total_roas?: number | null,
      total_conversion?: number | null,
      total_purchases?: number | null,
      average_cpm?: number | null,
      total_clicks?: number | null,
      cost_per_click?: number | null,
      total_impressions?: number | null,
      prev_total_amount_spent?: number | null,
      prev_total_roas?: number | null,
      prev_total_cost_per_click?: number | null,
      prev_total_conversion?: number | null,
      graph?:  Array< {
        __typename: "PerformanceDrawerStatsGraph",
        total_amount_spent?: number | null,
        total_conversion_value?: number | null,
        created?: string | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GenerateTrackableCopyPathNewQueryVariables = {
  generateTrackableCopyParams: BusinessIdInput,
};

export type GenerateTrackableCopyPathNewQuery = {
  generateTrackableCopyPathNew?:  {
    __typename: "GenerateTrackableCopyPathResponse",
    data?:  {
      __typename: "GeneratedPath",
      path?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetTrackableCopiesNewQueryVariables = {
  getTrackableCopiesInput: GetTrackableCopiesInput,
};

export type GetTrackableCopiesNewQuery = {
  getTrackableCopiesNew?:  {
    __typename: "GetTrackableCopiesResponse",
    data?:  {
      __typename: "GetTrackableCopyWithSummary",
      rows?:  Array< {
        __typename: "TrackableCopy",
        id: string,
        name: string,
        description?: string | null,
        type?: string | null,
        short_code: string,
        destination_url: string,
        url_position?: string | null,
        business_id?: string | null,
        created?: string | null,
        stats?:  {
          __typename: "TrackableCopyStats",
          clicks?: number | null,
          purchases?: number | null,
          revenue?: number | null,
          prev_clicks?: number | null,
        } | null,
        is_onboarding_default?: boolean | null,
        is_mini_shop?: boolean | null,
      } > | null,
      summary?:  {
        __typename: "TrackableCopySummary",
        clicks?: number | null,
        purchases?: number | null,
        revenue?: number | null,
        total_row?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetTrackableCopyTypesNewQueryVariables = {
  getTrackableCopyTypesInput: BusinessIdInput,
};

export type GetTrackableCopyTypesNewQuery = {
  getTrackableCopyTypesNew?:  {
    __typename: "GetTrackableCopyTypesResponse",
    data?: Array< string | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetTrackableCopyOrdersNewQueryVariables = {
  getTrackableCopyOrdersInput: GetTrackableCopyOrdersInput,
};

export type GetTrackableCopyOrdersNewQuery = {
  getTrackableCopyOrdersNew?:  {
    __typename: "GetTrackableCopyOrdersResponse",
    data?:  Array< {
      __typename: "TrackableCopyOrder",
      business_id?: string | null,
      conversion_value?: number | null,
      created?: string | null,
      order_id?: string | null,
      source?: string | null,
      customer_id?: string | null,
      customer_name?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetKeyMetricsMonthlyBudgetQueryVariables = {
  getKeyMetricsMonthlyBudgetInput: GetKeyMetricsMonthlyBudgetInput,
};

export type GetKeyMetricsMonthlyBudgetQuery = {
  getKeyMetricsMonthlyBudget?:  {
    __typename: "GetKeyMetricsMonthlyBudgetResponse",
    data?:  Array< {
      __typename: "MonthlyBudgetResponse",
      amount_spent?: number | null,
      source?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetKeyMetricsRoasTrackerQueryVariables = {
  getKeyMetricsRoasTrackerInput: GetKeyMetricsRoasTrackerInput,
};

export type GetKeyMetricsRoasTrackerQuery = {
  getKeyMetricsRoasTracker?:  {
    __typename: "GetKeyMetricsRoasTrackerResponse",
    data?:  {
      __typename: "RoasTrackerResponse",
      facebook?:  {
        __typename: "RoasTrackerResponseDetails",
        over?: number | null,
        under?: number | null,
        total?: number | null,
      } | null,
      tiktok?:  {
        __typename: "RoasTrackerResponseDetails",
        over?: number | null,
        under?: number | null,
        total?: number | null,
      } | null,
      google?:  {
        __typename: "RoasTrackerResponseDetails",
        over?: number | null,
        under?: number | null,
        total?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetAdcomparisonDataQueryVariables = {
  getBusinessAnalyticsInput: GetBusinessAnalyticsInput,
};

export type GetAdcomparisonDataQuery = {
  getAdcomparisonData?:  {
    __typename: "GetAdcomparisonDataResponse",
    data?:  Array< {
      __typename: "AnalyticsPerformanceData",
      id?: string | null,
      ad_id?: string | null,
      ad_name?: string | null,
      ad_primary_status?: string | null,
      ad_secondary_status?: string | null,
      total_amount_spent?: number | null,
      roas?: number | null,
      total_orders?: number | null,
      total_conversion_value?: number | null,
      ad_images?: string | null,
      source?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetAllCustomersNewQueryVariables = {
  getAllCustomersInput: GetAllCustomersInput,
};

export type GetAllCustomersNewQuery = {
  getAllCustomersNew?:  {
    __typename: "GetAllCustomersResponse",
    data?:  {
      __typename: "GetAllCustomersObject",
      customers?:  Array< {
        __typename: "AllBusinessCustomers",
        last_visit?: string | null,
        first_visit?: string | null,
        total_purchases?: string | null,
        total_pageviews?: string | null,
        customer_id?: string | null,
        customer_name?: string | null,
      } | null > | null,
      totalInfo?:  {
        __typename: "TotalInfoCustomers",
        total_purchases?: number | null,
        totalrecords?: number | null,
        total_visitors?: number | null,
        total_pageviews?: number | null,
        total_clicks?: number | null,
        total_customers?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetCustomerJourneyVisitorGraphNewQueryVariables = {
  getCustomerJourneyVisitorGraphInput: GetCustomerJourneyVisitorGraphInput,
};

export type GetCustomerJourneyVisitorGraphNewQuery = {
  getCustomerJourneyVisitorGraphNew?:  {
    __typename: "GetCustomerJourneyVisitorGraphResponse",
    data?:  Array< {
      __typename: "CustomerJourneyVisitorGraph",
      date?: string | null,
      new_visitors?: string | null,
      returning_visitors?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetSegmentBuilderBasicDetailsQueryVariables = {
  getSegmentBuilderBasicDetailsInput: GetSegmentBuilderBasicDetailsInput,
};

export type GetSegmentBuilderBasicDetailsQuery = {
  getSegmentBuilderBasicDetails?:  {
    __typename: "GetSegmentBuilderBasicDetailsResponse",
    data?:  {
      __typename: "GetSegmentBuilderBasicDetails",
      cities: Array< string >,
      states: Array< string >,
      countries: Array< string >,
      dynamicQueryRules?: string | null,
      predefinedQueries?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetAudienceSegmentQueryByIdQueryVariables = {
  getAudienceSegmentInput: GetAudienceSegmentInput,
};

export type GetAudienceSegmentQueryByIdQuery = {
  getAudienceSegmentQueryById?:  {
    __typename: "GetAudienceSegmentResponse",
    data?:  {
      __typename: "Segments",
      id: string,
      business_id: string,
      no_of_customers?: number | null,
      added_revenue?: number | null,
      audience?: string | null,
      type: string,
      status: string,
      query_details?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      group_name: string,
      deleted_at?: string | null,
      dynamic_query_params: string,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerJourneySourcesNewQueryVariables = {
  getCustomerJourneySourcesInput: GetCustomerJourneySourcesInput,
};

export type GetCustomerJourneySourcesNewQuery = {
  getCustomerJourneySourcesNew?:  {
    __typename: "GetCustomerJourneySourcesResponse",
    data?:  {
      __typename: "GetCustomerJourneySourcesObject",
      sources?:  Array< {
        __typename: "CustomerJourneySource",
        source?: string | null,
        unique_visitor?: number | null,
        unique_sessions?: number | null,
        purchases_count?: number | null,
      } | null > | null,
      totalInfo?:  {
        __typename: "TotalInfoCustomers",
        total_purchases?: number | null,
        totalrecords?: number | null,
        total_visitors?: number | null,
        total_pageviews?: number | null,
        total_clicks?: number | null,
        total_customers?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetCustomerDetailsSourcesNewQueryVariables = {
  getCustomerDetailsSourcesInput: GetCustomerDetailsSourcesInput,
};

export type GetCustomerDetailsSourcesNewQuery = {
  getCustomerDetailsSourcesNew?:  {
    __typename: "GetCustomerDetailsSourcesResponse",
    data?:  Array< {
      __typename: "CustomerDetailsSource",
      channel_name?: string | null,
      referer?: string | null,
      created_at?: string | null,
    } | null > | null,
    total_records?: number | null,
    numberPages?: number | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerDetailsNewQueryVariables = {
  getCustomerDetailsInput: GetCustomerDetailsInput,
};

export type GetCustomerDetailsNewQuery = {
  getCustomerDetailsNew?:  {
    __typename: "GetCustomerDetailsResponse",
    data?:  {
      __typename: "CustomerDetails",
      customer_email?: string | null,
      country?: string | null,
      state?: string | null,
      city?: string | null,
      total_pageviews?: number | null,
      first_visit?: string | null,
      total_purchases?: number | null,
      total_purchase_conversion_value?: number | null,
      customer_name?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerDetailsPageViewsNewQueryVariables = {
  getCustomerDetailsPageViewsInput: getCustomerDetailsPageViewsInput,
};

export type GetCustomerDetailsPageViewsNewQuery = {
  getCustomerDetailsPageViewsNew?:  {
    __typename: "GetCustomerDetailsPageviewsResponse",
    data?:  Array< {
      __typename: "CustomerDetailsPageView",
      ad?: string | null,
      ad_set?: string | null,
      campaign?: string | null,
      conversion_value?: number | null,
      created?: string | null,
      referer?: string | null,
      sirge_source_name?: string | null,
      url?: string | null,
    } | null > | null,
    total_records?: number | null,
    numberPages?: number | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetKeyMetricsAnalyticsStatisticsQueryVariables = {
  getKeyMetricsAnalyticsStatisticsInput: GetKeyMetricsAnalyticsStatisticsInput,
};

export type GetKeyMetricsAnalyticsStatisticsQuery = {
  getKeyMetricsAnalyticsStatistics?:  {
    __typename: "GetKeyMetricsAnalyticsStatisticsResponse",
    data?:  {
      __typename: "KeyMetricsAnalyticsStatistics",
      total_order?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
      total_sales?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
      average_order_value?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
      blended_roas?:  {
        __typename: "BlendedRoasStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
        sources?:  {
          __typename: "RoasBySources",
          facebook?: number | null,
          tiktok?: number | null,
          google?: number | null,
        } | null,
      } | null,
      visits?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
      conversion_rate?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
      total_conversion_value?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
      total_refunds?:  {
        __typename: "KeyMetricsStatistics",
        amount?: number | null,
        percentage?: number | null,
        trend?:  Array< {
          __typename: "KeyMetricsStatisticsTrend",
          date?: string | null,
          value?: number | null,
        } | null > | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerChannelsNewQueryVariables = {
  getCustomerChannelsInput: GetCustomerChannelsInput,
};

export type GetCustomerChannelsNewQuery = {
  getCustomerChannelsNew?:  {
    __typename: "GetCustomerChannelsResponse",
    data?:  {
      __typename: "GetCustomerChannelObject",
      channels?:  Array< {
        __typename: "CustomerChannel",
        source?: string | null,
        source_id?: string | null,
        referer?: string | null,
        unique_visitor?: number | null,
        unique_sessions?: number | null,
        purchases_count?: number | null,
      } | null > | null,
      totalInfo?:  {
        __typename: "TotalInfo",
        total_clicks?: number | null,
        total_purchases?: number | null,
        totalrecords?: number | null,
        total_visitors?: number | null,
        total_pageviews?: number | null,
      } | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetCustomerChannelsDetailsNewQueryVariables = {
  getCustomerChannelsDetailsInput: GetCustomerChannelsDetailsInput,
};

export type GetCustomerChannelsDetailsNewQuery = {
  getCustomerChannelsDetailsNew?:  {
    __typename: "GetCustomerChannelsDetailsResponse",
    data?:  {
      __typename: "GetCustomerChannelsDetailsResponseData",
      channels?:  Array< {
        __typename: "CustomerChannel",
        source?: string | null,
        source_id?: string | null,
        referer?: string | null,
        unique_visitor?: number | null,
        unique_sessions?: number | null,
        purchases_count?: number | null,
      } | null > | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetSourcesOrdersNewQueryVariables = {
  getSourcesOrdersInput: GetSourcesOrdersInput,
};

export type GetSourcesOrdersNewQuery = {
  getSourcesOrdersNew?:  {
    __typename: "GetSourcesOrdersResponse",
    data?:  Array< {
      __typename: "SourceOrder",
      business_id?: string | null,
      conversion_value?: number | null,
      created?: string | null,
      order_id?: string | null,
      source?: string | null,
      customer_id?: string | null,
      customer_name?: string | null,
    } | null > | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
    numberPages?: number | null,
  } | null,
};

export type GetSuggestedSegmentsStatsQueryVariables = {
  getSuggestedSegmentsStatsInput: GetSuggestedSegmentsStatsInput,
};

export type GetSuggestedSegmentsStatsQuery = {
  getSuggestedSegmentsStats?:  {
    __typename: "GetSuggestedSegmentsStatsResponse",
    data?:  Array< {
      __typename: "GetSuggestedSegmentsStatsResult",
      no_of_customers?: number | null,
      query_type?: SuggestedSegmentTypes | null,
    } | null > | null,
    total_records?: number | null,
    message?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    nextToken?: string | null,
  } | null,
};

export type GetCustomerOrdersByCustomerIdQueryVariables = {
  getCustomerOrdersInput: GetCustomerOrdersInput,
};

export type GetCustomerOrdersByCustomerIdQuery = {
  getCustomerOrdersByCustomerId?:  {
    __typename: "GetCustomerOrdersResponse",
    data?:  Array< {
      __typename: "CustomerOrders",
      id: string,
      customer_id?: string | null,
      customer_name?: string | null,
      platform_order_id: string,
      city?: string | null,
      state?: string | null,
      zip?: string | null,
      country?: string | null,
      currency?: string | null,
      conversion_value?: number | null,
      insight_date?: string | null,
      visitor_id?: string | null,
      deleted_at?: string | null,
      updated_at?: string | null,
      created_at?: string | null,
    } | null > | null,
    total_records?: number | null,
    message?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllStaticAudienceSegmentsQueryVariables = {
  getAllStaticAudienceSegmentQueryInput: GetAllStaticAudienceSegmentQueryInput,
};

export type GetAllStaticAudienceSegmentsQuery = {
  getAllStaticAudienceSegments?:  {
    __typename: "GetAllStaticAudienceSegmentResponse",
    data?:  Array< {
      __typename: "StaticSegments",
      id: string,
      business_id: string,
      group_name: string,
    } | null > | null,
    total_records?: number | null,
    message?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    nextToken?: string | null,
  } | null,
};

export type GetAllAudienceSegmentQueriesQueryVariables = {
  getAllAudienceSegmentQueryInput: GetAllAudienceSegmentQueryInput,
};

export type GetAllAudienceSegmentQueriesQuery = {
  getAllAudienceSegmentQueries?:  {
    __typename: "GetAllAudienceSegmentSourcesResponse",
    data?:  Array< {
      __typename: "Segments",
      id: string,
      business_id: string,
      no_of_customers?: number | null,
      added_revenue?: number | null,
      audience?: string | null,
      type: string,
      status: string,
      query_details?: string | null,
      created_at?: string | null,
      updated_at?: string | null,
      group_name: string,
      deleted_at?: string | null,
      dynamic_query_params: string,
    } | null > | null,
    total_records?: number | null,
    message?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    nextToken?: string | null,
  } | null,
};

export type GetBusinessAIReportQueryVariables = {
  getBusinessAIReportInput: GetBusinessAIReportInput,
};

export type GetBusinessAIReportQuery = {
  getBusinessAIReport?:  {
    __typename: "GetBusinessAIReportResponse",
    data?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
  } | null,
};

export type GetUserTaskProgressQueryVariables = {
  userTaskProgressInput: UserTaskProgressInput,
};

export type GetUserTaskProgressQuery = {
  getUserTaskProgress?:  {
    __typename: "UserTaskProgressResponse",
    data?:  Array< {
      __typename: "UserTaskProgress",
      task_count?: number | null,
      status?: string | null,
      objective?: string | null,
    } | null > | null,
  } | null,
};

export type GetSubscriptionNewQueryVariables = {
  getSubscriptionNewInput?: GetSubscriptionNewInput | null,
};

export type GetSubscriptionNewQuery = {
  getSubscriptionNew?:  {
    __typename: "GetSubscriptionNewResponse",
    data?:  {
      __typename: "SubscriptionsTableSchema",
      id?: string | null,
      business_id?: string | null,
      subscription_plan_code?: string | null,
      status?: string | null,
      processor?: string | null,
      store_subscription_body?: string | null,
      store_subscription_id?: string | null,
      promo_code_id?: string | null,
      promo_code_applied_at?: string | null,
      trial_start?: string | null,
      trial_end?: string | null,
      trial_left?: number | null,
      trial_extended?: boolean | null,
      current_billing_period_start?: string | null,
      current_billing_period_end?: string | null,
      subscription_end_date?: string | null,
      business_limit?: number | null,
      staff_limit?: number | null,
      created_at?: string | null,
      updated_at?: string | null,
      deleted_at?: string | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type GetUserTaskCounterQueryVariables = {
  getUserTaskCounterInput: GetUserTaskCounterInput,
};

export type GetUserTaskCounterQuery = {
  getUserTaskCounter?:  {
    __typename: "GetUserTaskCounterResponse",
    data?:  {
      __typename: "UserTaskCounter",
      completed_tasks?: number | null,
      total_tasks?: number | null,
    } | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};

export type OnDataRefreshedForBusinessSubscriptionVariables = {
  data: string,
};

export type OnDataRefreshedForBusinessSubscription = {
  onDataRefreshedForBusiness?:  {
    __typename: "RefreshDataForResponse",
    data?: string | null,
    error?:  {
      __typename: "ErrorResponse",
      code?: string | null,
      message?: string | null,
    } | null,
    message?: string | null,
    nextToken?: string | null,
  } | null,
};
