import {StateCreator} from 'zustand';
import {TrackableCopy} from "API";
interface State {
    linkTrackingDialogOpen: boolean;
    trackableCopies: TrackableCopy[]
    editedTrackableCopy: TrackableCopy | null;
    deletionTrackableCopy: TrackableCopy | null;
    showYourLinkIsReady: boolean;
    showDeleteLinkDialog: boolean;
    fetchCount: number;
}
interface Actions {
    setLinkTrackingDialogOpen: (linkDialogOpen: boolean) => void;
    setTrackableCopies: (trackableCopies: TrackableCopy[]) => void;
    setEditedTrackableCopy: (trackableCopy: TrackableCopy | null) => void;
    setDeletionTrackableCopy: (trackableCopy: TrackableCopy | null) => void;
    setShowYourLinkIsReady: (isYourLinkReady: boolean) => void;
    setShowDeleteLinkDialog: (showDeleteLinkDialog: boolean) => void;
    increaseFetchCount: () => void;
}
export interface LinkTrackingSlice extends State, Actions {
}


export const createLinkTrackingSlice: StateCreator<
    LinkTrackingSlice,
    [],
    []
> = (set, get) => ({
    linkTrackingDialogOpen: false,
    fetchCount:0,
    increaseFetchCount: ()=> set((state) => ({...state, fetchCount: state.fetchCount + 1})),
    setLinkTrackingDialogOpen: (linkDialogOpen: boolean) =>
        set((state) => ({...state, linkTrackingDialogOpen: linkDialogOpen})),
    trackableCopies: [],
    setTrackableCopies: (trackableCopies: TrackableCopy[]) =>
        set((state) => ({...state, trackableCopies: trackableCopies})),
    editedTrackableCopy: null,
    setEditedTrackableCopy: (trackableCopy: TrackableCopy | null) =>
        set((state) => ({...state, editedTrackableCopy: trackableCopy})),
    setDeletionTrackableCopy: (trackableCopy: TrackableCopy | null) =>
        set((state) => ({...state, deletionTrackableCopy: trackableCopy})),
    deletionTrackableCopy: null,
    showYourLinkIsReady: false,
    setShowYourLinkIsReady: (isYourLinkReady: boolean) =>
        set((state) => ({...state, showYourLinkIsReady: isYourLinkReady})),
    showDeleteLinkDialog: false,
    setShowDeleteLinkDialog: (showDeleteLinkDialog: boolean) =>
        set((state) => ({...state, showDeleteLinkDialog: showDeleteLinkDialog})),
});
